/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Link } from "react-router-dom";
import { PageLayout, Blank, EvButton, Font, Img } from "../../../styles/commonStyle";
import { PointInfoLayout, PointInfoDiv, ColDivider, QToolTipDiv } from "./style";
import { blueBtnGradient, cardTitleColor, orangeBtnGradient, primaryColor, } from "../../../styles/theme";
import { Wrap } from "../../register/style";
import iconJoin from "../../../assets/images/icon-join.png";
import iconVvip from "../../../assets/images/icon-member-vvip2.svg";
import { useRecoilState } from "recoil";
import { loginState } from "../../../recoil/atoms";
import Tooltip from "../../../components/tooltip";
import { useEffect, useState } from "react";
import { addCommas, getDominantColor } from "../../../util";
function PointInformation({ userPoint, expiresPoint, userGradeInfo, totalMileage }: { userPoint: any, expiresPoint: any, userGradeInfo: any, totalMileage: number | undefined }) {
    const [showPointInfo, setShowPointInfo] = useState(false);
    const [showPointInfo2, setShowPointInfo2] = useState(false);
    const [textColor, setColor] = useState<any>(null);

    var onMouseUpToolTip = () => setShowPointInfo(true)
    var onMouseLeaveToolTip = () => setShowPointInfo(false)
    var onMouseUpToolTip2 = () => setShowPointInfo2(true)
    var onMouseLeaveToolTip2 = () => setShowPointInfo2(false)

    const getImageColor = async (img: string) => {
        setColor(await getDominantColor(img))
    }

    useEffect(() => {
        getImageColor(`${process.env.REACT_APP_MEMBERSHIP_API_HOST}/api/v2/grade/img/TD/${userGradeInfo.memberGrade.imgNm}`)
    }, [])
    return (
        <>
            <PointInfoLayout>
                <PointInfoDiv >
                    <Img src={`${process.env.REACT_APP_MEMBERSHIP_API_HOST}/api/v2/grade/img/TD/${userGradeInfo.memberGrade.imgNm}`} width="200px" height="200px"
                    />
                    <div className="flex">
                        <Font fontSize="1.2rem" >고객님은 현재</Font>
                        <Font fontSize="1.8rem" color={textColor == null ? "#C4A24C" : textColor} fontWeight="500" style={{ marginLeft: "8px" }}>{userGradeInfo.memberGrade.nm}</Font>
                        <Font fontSize="1.2rem" style={{ marginLeft: "8px" }}>등급입니다.</Font>
                    </div>
                    <Blank height="20px" />
                    <Link to="/membership/info">
                        <EvButton color="#FFF" bgColor={primaryColor} width="200px">
                            멤버십 혜택 안내
                        </EvButton>
                    </Link>
                </PointInfoDiv>
                <PointInfoDiv>
                    <Font fontSize="1.2rem">보유 포인트</Font>
                    <Font fontSize="1.2rem" color="#00000000">.</Font>
                    <Blank height="3rem" />
                    <Font fontSize="1.4rem" fontWeight="600">{userPoint ? addCommas(userPoint.toString()) : '0'}P</Font>
                    <QToolTipDiv onMouseEnter={onMouseUpToolTip} onMouseLeave={onMouseLeaveToolTip}>
                        ?
                        <Tooltip show={showPointInfo} width="310px" body={"포함된 포인트 유형\n1) 등급 리워드 : 결제 금액 기준 등급별 지급되는 포인트\n2) 유료충전 : evWhere 앱 내 구매하는 포인트\n3) 적립 : 2번 구매 시 지급되는 포인트"} top="-95px"></Tooltip>
                    </QToolTipDiv>
                </PointInfoDiv>
                <ColDivider />
                <PointInfoDiv>
                    <Font fontSize="1.2rem">등급 포인트</Font>
                    <Font fontSize="1.2rem" color="#00000000">.</Font>
                    <Blank height="3rem" />
                    <Font fontSize="1.4rem" fontWeight="600">{totalMileage ? addCommas(totalMileage) : '0'}P</Font>
                    <QToolTipDiv onMouseEnter={onMouseUpToolTip2} onMouseLeave={onMouseLeaveToolTip2}>
                        ?
                        <Tooltip show={showPointInfo2} width="310px" body={"등급 리워드 : 결제 금액 기준 등급별 지급되는 포인트"} top="-45px"></Tooltip>
                    </QToolTipDiv>
                </PointInfoDiv>
                <ColDivider />
                <PointInfoDiv >
                    <Font fontSize="1.2rem">소멸 예정 포인트</Font>
                    <Font fontSize="1.2rem">(30일 이내)</Font>
                    <Blank height="3rem" />
                    <Font fontSize="1.4rem" fontWeight="600">{expiresPoint ? addCommas(expiresPoint.toString()) : '0'}P</Font>
                </PointInfoDiv>
            </PointInfoLayout>
            <Blank height="12px" />
        </>
    );
}
export default PointInformation;
