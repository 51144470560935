/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilState } from "recoil";
import { Blank, Div, EvButton, Font } from "../../styles/commonStyle";
import { disabledFontColor, primaryColor, redColor } from "../../styles/theme";
import { ChargingListContent, ChargingListHead, ScrollDiv, StyledTotal } from "./style";
import { chargingSelector, userChargingSelector } from "../../recoil/variableAtom";
import { RESULT, ResponseModel } from "../../model/response";
import { ResponseList } from "../../model/responseList";
import { useEffect, useLayoutEffect, useState } from "react";
import EvModal from "../../components/modal";
import { UtctoLocaleDateTimeFormmatter, addCommas, formatDate, getUserIdFromToken } from "../../util";
import Pagination from "../../components/pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useFetchList from "../../hooks/useFetchList";
import { useLocation, useNavigate } from "react-router-dom";
import { Charging } from "../../model/charging";
import Loading from "../../components/loading/loading";
import { modalState, pageState } from "../../recoil/atoms";

function ChargingHistoryPage() {
  const [currentPage, setPage] = useRecoilState(pageState);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [isLoading, setLoading] = useState(true);
  const [chargingList, setChargingList] = useState<any>();
  const [startDate, setStartDate] = useState<Date | null | undefined>();
  const [endDate, setEndDate] = useState<Date | null | undefined>();
  const [startDateString, setStartDateString] = useState<string | null | undefined>();
  const [endDateString, setEndDateString] = useState<string | null | undefined>();
  const [getSearch, setSearch] = useState<any>();
  const [isExec, setExec] = useState(false);
  const [totalStartDate, setTotalStartDate] = useState<any>();
  const [totalEndDate, setTotalEndDate] = useState<any>();
  const location = useLocation();
  const naviagate = useNavigate();
  const params = new URLSearchParams(location.search);
  const cPage = params.get("currentPage") ?? "1";
  let userId = getUserIdFromToken();

  const query2: any = {
    currentPage: currentPage,
    perPage: 10,
  };
  if (startDateString) {
    if (startDate) {
      query2.beginDts = startDate?.toISOString()
    }
  }
  if (endDateString) {
    if (endDate) {
      query2.beginDte = endDate?.toISOString()
    }
  }

  const userChargingResponse: any = useFetchList({
    recoilState: userChargingSelector,
    query: query2,
    currentPage: currentPage,
    search: getSearch
  })

  const execSearch = () => {
    setPage(1);
    setExec(true);
    setTotalStartDate(startDate);
    setTotalEndDate(endDate);
  };
  const execEnterSearch = (e: any) => {
    if (e.key === "Enter") {
      execSearch();
    }
  };
  useEffect(() => {
    if (userChargingResponse?.result === RESULT.FAIL) {
      setLoading(false);
      setModalOpen(true);
    }
    if (userChargingResponse?.result === RESULT.OK) {
      setLoading(false);
      setChargingList(userChargingResponse);
    }
  }, [userChargingResponse]);

  useEffect(() => {
    setSearch(startDateString + "-" + endDateString);
    if (startDateString && endDateString) {
      naviagate(`/charging?currentPage=${currentPage}&startDts=${startDateString}&startDte=${endDateString}`);
    } else if (startDateString && !endDateString) {
      naviagate(`/charging?currentPage=${currentPage}&startDts=${startDateString}`);
    } else if (!startDateString && endDateString) {
      naviagate(`/charging?currentPage=${currentPage}&startDte=${endDateString}`);
    }
    else {
      naviagate(`/charging?currentPage=${currentPage}`);
    }
    setExec(false);
  }, [isExec]);

  useLayoutEffect(() => {
    setPage(Number(cPage));
    if (params.get("startDts") && params.get("startDte")) {
      setStartDateString(params.get("startDts")!);
      setEndDateString(params.get("startDte")!);
      setSearch(startDateString + "-" + endDateString);
    }
  }, []);

  const startDateHandler = (date: Date | null) => {
    if (endDate! < date!) {
      return;
    }
    setStartDate(date);
    setStartDateString(UtctoLocaleDateTimeFormmatter(date?.toDateString()!));
  };

  const endDateHandler = (date: Date | null) => {
    setEndDate(date);
    setEndDateString(UtctoLocaleDateTimeFormmatter(date?.toDateString()!));
  };
  return (
    <>
      <Div className="w-full flex justify-end items-center">
        <DatePicker
          selected={startDate}
          onChange={(date) => startDateHandler(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className="DatePicker"
          placeholderText="검색 시작일"
        />
        &nbsp;&nbsp;~&nbsp;&nbsp;
        <DatePicker
          selected={endDate}
          onChange={(date) => endDateHandler(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className="DatePicker"
          placeholderText="검색 종료일"
          onKeyDown={(e) => execEnterSearch(e)}
        />
        <EvButton color="#fff" bgColor={primaryColor} className="ml-[10px]" padding="0.5rem 1.5rem" onClick={(e) => execSearch()}>
          조회
        </EvButton>
      </Div>

      {chargingList !== undefined && chargingList.data.chrgrElctcList.length > 0 && (totalStartDate || totalEndDate) ? (<GetTotalPoint totalStartDate={totalStartDate} totalEndDate={totalEndDate} chargingList={chargingList} />) : undefined}

      <Blank height="12px" />
      <ScrollDiv className="overflow-x-scroll w-full">
        <ChargingListHead>
          <Font className="w-[13%] text-center flex justify-center" fontSize="0.9rem">
            충전소명
          </Font>
          <Font className="w-[10%] text-center flex justify-center" fontSize="0.9rem">
            충전기번호
          </Font>
          <Font className="w-[30%] text-center flex justify-center" fontSize="0.9rem">
            충전시간 (시작시간 ~ 종료시간)
          </Font>
          <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
            충전량(kWh)
          </Font>
          <div className="w-[24%] flex flex-col">
            <div className="w-[100%] flex justify-center border-b border-slate-300 pb-2.5">
              <Font className="w-[100%] text-center flex justify-center" fontSize="0.9rem">
                결제금액
              </Font>
            </div>
            <div className="w-[100%] flex flex-row pt-2.5">
              <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                신용카드(원)
              </Font>
              <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                포인트(P)
              </Font>
              <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                합계
              </Font>
            </div>
          </div>
          <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
            결제상태
          </Font>
          <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
            포인트(P)
          </Font>
        </ChargingListHead>

        {!(chargingList?.data.chrgrElctcList.length > 0) && userChargingResponse?.result === RESULT.OK ? (
          <Font className="mt-5 flex justify-center">충전 이력이 존재하지 않습니다.</Font>
        ) : (

          <>
            {chargingList && chargingList.data && chargingList.data.chrgrElctcList && chargingList.data.chrgrElctcList?.map((value: any, idx: number) => <GetListLine value={value} key={"chargingList" + idx + 1} />)}
          </>
        )}
      </ScrollDiv>

      {isLoading ? (
        <Loading isLoading={true} />
      ) : (
        <Div className="flex mt-5 mb-5">
          <Pagination totalPage={chargingList?.data.totPage!} currentPage={"1"} type="charging" />
        </Div>
      )}
      <EvModal msg={userChargingResponse?.msg} />

    </>
  );
}

function GetTotalPoint({ totalStartDate, totalEndDate, chargingList }: { totalStartDate: any, totalEndDate: any, chargingList: any }) {
  return (
    <>
      <Blank height="12px" />
      <StyledTotal>
        <Font className="w-[10%] text-center flex justify-center font-medium bg-gray-112" fontSize="0.9rem">
          기간별 합계
        </Font>
        <Font className="w-[5%] text-center flex justify-center font-medium" fontSize="0.9rem">
          기간
        </Font>
        <Font className="w-[20%] text-center flex justify-center font-medium" fontSize="0.9rem">
          {formatDate(totalStartDate)} ~ {formatDate(totalEndDate)}
        </Font>
        <Font className="w-[10%] text-center flex justify-center font-medium" fontSize="0.9rem">
          충전량
        </Font>
        <Font className="w-[10%] text-center flex justify-center font-medium" fontSize="0.9rem">
          {chargingList?.data.totalElctcQy ? chargingList?.data.totalElctcQy : "0"}
        </Font>
        <Font className="w-[15%] text-center flex justify-center font-medium" fontSize="0.9rem">
          충전금액(원)
        </Font>
        <Font className="w-[10%] text-center flex justify-center font-medium" fontSize="0.9rem">
          {chargingList?.data.totalElctcPc ? chargingList?.data.totalElctcPc : "0"}
        </Font>
        <Font className="w-[10%] text-center flex justify-center font-medium" fontSize="0.9rem">
          포인트(P)
        </Font>
        <Font className="w-[10%] text-center flex justify-center font-medium" fontSize="0.9rem">
          {chargingList?.data.totalMileage ? chargingList?.data.totalMileage : "0"}
        </Font>
      </StyledTotal>
    </>
  )
}

function GetListLine({ value }: { value: any }) {
  let paySttus: string = value.paySttus;
  switch (paySttus) {
    case "READY":
      paySttus = "결제대기";
      break;
    case "SUCCESS":
      paySttus = "결제성공";
      break;
    case "EXCEPT":
      paySttus = "결제예외";
      break;
    case "FAIL":
      paySttus = "결제실패";
      break;
    case "ALL_CANCEL":
      paySttus = "전체취소";
      break;
    case "ACCOUNT":
      paySttus = "계좌입금";
      break;
    case "ADMIN_CANCEL":
      paySttus = "관리자취소";
      break;
    case "TAX":
      paySttus = "세금계산서";
      break;
    case "CANCEL_FAIL":
      paySttus = "취소실패";
      break;
    case "CANCEL_READY":
      paySttus = "취소대기";
      break;
    default:
      paySttus = "알수없음";
      break;
  }
  // 마일리지 적립 실패 케이스 : FAIL, SEND_FAIL, NOT_PAID, SUCCESS
  return (
    <ChargingListContent>
      <Font className="w-[13%] text-center flex justify-center" fontSize="0.9rem">
        {value.stationNm}
      </Font>
      <Font className="w-[10%] text-center flex justify-center" fontSize="0.9rem">
        {value.chrgrIdntfr}
      </Font>
      <Font className="w-[30%] text-center flex justify-center" fontSize="0.9rem">
        {value.beginDt && value.endDt
          ? `${UtctoLocaleDateTimeFormmatter(value.beginDt.toString())} ~ ${UtctoLocaleDateTimeFormmatter(value.endDt.toString())}`
          : "-"}
      </Font>
      {
        paySttus === "결제대기" || paySttus === "결제성공" || paySttus === "결제예외" || paySttus === "알수없음" ? <>
          <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
            {value.elctcQy ?? "-"}
          </Font>
          <div className="w-[24%] flex">
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {value.paidCardPrice ?? 0}
            </Font>
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {value.paidPointPrice ?? 0}
            </Font>
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {addCommas(value.paidPrice) ?? "-"}
            </Font>
          </div>
          <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
            {paySttus}
          </Font>
          {
            value.mileage < 0 ? <Font className="w-[8%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
              -{value.mileage}
            </Font> : value.mileage == 0 ? <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
              0
            </Font> : <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
              +{value.mileage}
            </Font>
          }
        </> : paySttus === "취소대기" ? <>
          <Font className="w-[8%] text-center flex justify-center" color={disabledFontColor} fontSize="0.9rem">
            {value.elctcQy ?? "-"}
          </Font>
          <div className="w-[24%] flex">
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {value.paidCardPrice ?? 0}
            </Font>
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {value.paidPointPrice ?? 0}
            </Font>
            <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
              {addCommas(value.paidPrice) ?? "-"}
            </Font>
          </div>
          <Font className="w-[8%] text-center flex justify-center" color={disabledFontColor} fontSize="0.9rem">
            {paySttus}
          </Font>
          {
            value.mileageSttus === "SUCCESS" ? <Font className="w-[8%] text-center flex justify-center" color={disabledFontColor} fontSize="0.9rem">
              +{value.mileage}
            </Font> : value.mileage < 0 ? <Font className="w-[8%] text-center flex justify-center" color={disabledFontColor} fontSize="0.9rem">
              -{value.mileage}
            </Font> : <Font className="w-[8%] text-center flex justify-center" color={disabledFontColor} fontSize="0.9rem">
              {value.mileage}
            </Font>
          }
        </> : <>
          {
            value.elctcQy == 0 ? <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
              {`${value.elctcQy}`}
            </Font> : <Font className="w-[8%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
              {`-${value.elctcQy}`}
            </Font>
          }
          <div className="w-[24%] flex">
            {
              value.paidCardPrice == 0 ? <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                {`${addCommas(value.paidCardPrice)}`}
              </Font> : <Font className="w-[33%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
                {`-${addCommas(value.paidCardPrice)}`}
              </Font>
            }
            {
              (value.paidPointPrice == 0 || value.paidPointPrice == null) ? <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                0
              </Font> : <Font className="w-[33%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
                -{value.paidPointPrice}
              </Font>
            }
            {
              value.paidPrice == 0 ? <Font className="w-[33%] text-center flex justify-center" fontSize="0.9rem">
                {`${addCommas(value.paidPrice)}`}
              </Font> : <Font className="w-[33%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
                {`-${addCommas(value.paidPrice)}`}
              </Font>
            }
          </div>
          <Font className="w-[8%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
            {paySttus}
          </Font>
          {
            value.mileageSttus === "SUCCESS" ? <Font className="w-[8%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
              +{value.mileage}
            </Font> : value.mileage < 0 ? <Font className="w-[8%] text-center flex justify-center" color={redColor} fontSize="0.9rem">
              -{value.mileage}
            </Font> : <Font className="w-[8%] text-center flex justify-center" fontSize="0.9rem">
              {0}
            </Font>
          }

        </>
      }

    </ChargingListContent>
  );
}

export default ChargingHistoryPage