import { Link } from "react-router-dom";
import styled from "styled-components";



const InformationContent = styled.div`
white-space: pre-wrap;
  
`;

const InformationTitle = styled.div`
font-size : 20px;
font-weight: 600;
`;

const InformationLink = styled(Link)`
    color: #3366cc;
`;

const AlignSelf = styled.div`
    align-self: flex-start;
`

export { InformationContent, InformationTitle, AlignSelf, InformationLink };
