import { BorderBox, ContentBox, Layout } from "./style";
import bgImage from "../../assets/images/img-bg-full-apply.jpg";
import { A, Blank, EvButton, Font, Img, Row, Span } from "../../styles/commonStyle";
import { orangeBtnGradient, primaryColor } from "../../styles/theme";
import btnAppstore from "../../assets/images/btn-appstore.png";
import btnPlaystore from "../../assets/images/btn-playstore.png";
import { Link } from "react-router-dom";

function QRpayResultPage() {
  return (
    <Layout image={bgImage}>
      <ContentBox paddingLeft="1.5rem" paddingRight="1.5rem">
        <Font fontSize="1.75rem" fontWeight="600">
          비회원 QR 결제
        </Font>
        <Font className="mt-[1.3rem]" fontWeight="500">
          QR 인증이 완료되었습니다.
        </Font>
        <Font className="mt-[0.3rem]" fontWeight="500">
          <Span color={primaryColor}>충전기의 안내</Span>에 따라
        </Font>
        <Font className="mt-[0.3rem]" fontWeight="500">
          충전을 시작해주세요.
        </Font>
        <Blank height="3rem" />
        <BorderBox>
          <Font color={primaryColor} fontWeight="500">
            <Span className="font-bold">evPlug 회원</Span>으로 가입하세요
          </Font>
          <Font fontWeight="500" className="mt-[1rem]">
            <Span color={primaryColor}>
              훨씬 <Span className="underline underline-offset-2">저렴한 요금</Span>
            </Span>
            으로
          </Font>
          <Font fontWeight="500">이용 하실 수 있습니다</Font>
          <Link to="/register">
            <EvButton color="#fff" bgColor={primaryColor} className="my-[1rem]">
              회원가입
            </EvButton>
          </Link>
          <Font fontWeight="500">
            *충전상태정보는 <Span color={primaryColor}>evWhere</Span>에서
          </Font>
          <Font fontWeight="500">확인할 수 있습니다.</Font>
          <Row className="justify-evenly w-full mt-[1rem]">
            <A href="https://itunes.apple.com/kr/app/evwhere/id1231333404" target="_blank">
              <Img src={btnAppstore} width="20vh" />
            </A>
            <A href="https://play.google.com/store/apps/details?id=com.ecoevc.evwhere.evwhere" target="_blank">
              <Img src={btnPlaystore} width="20vh" />
            </A>
          </Row>
        </BorderBox>
      </ContentBox>
    </Layout>
  );
}
export default QRpayResultPage;
