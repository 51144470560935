import { useEffect, useState } from "react";
import { StyledTopButton } from "./style";
import IconScrollTop from '../../assets/images/btn-scroll-top.png';

function TopButton() {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 1) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        const scrollStep = -window.scrollY / 15;
        const scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <StyledTopButton onClick={scrollToTop} isVisible={isVisible}>
            <img src={IconScrollTop} alt="스크롤탑" />
        </StyledTopButton>
    );
}

export default TopButton;