/* eslint-disable no-useless-escape */
import * as yup from "yup";

const requiredMessage = "필수입력입니다.";
const emailTypeMessage = "이메일 형식이 올바르지 않습니다.";

export const reqChargerSchema = yup
  .object({
    nm: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    cttpc: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9-]{10,13}$/, "10~13글자의 핸드폰 번호를 입력해주세요."),
    email: yup
      .string()
      .required(requiredMessage)
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    ty: yup.string().nullable().oneOf(["OFFICIAL_USE", "NON_OFFICIAL_USE", "UNKNOWN"], requiredMessage).required(requiredMessage),
    placeTy: yup
      .string()
      .nullable()
      .oneOf(["SHARE", "GOVERNMENT", "PUBLIC_SERVICE", "OFFICE", "COMMERCE", "OTHER"], requiredMessage)
      .required(requiredMessage),
    sideTy: yup.string().nullable().oneOf(["OUTSIDE", "INSIDE", "UNKNOWN"], requiredMessage).required(requiredMessage),
    rdnmadr: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    adresDetail: yup.string().max(255, "255자리 이하로 입력해주세요."),
    carCnt: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]*$/, "숫자만 입력해주세요."),
  })
  .required();

export const emailSchema = yup
  .object({
    email: yup
      .string()
      .required(requiredMessage)
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
  })
  .required();

// 비밀번호 찾기 Validation
export const findPasswordValidation = yup
  .object({
    phone: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]{3}-[0-9]{4}-[0-9]{4}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
  })
  .required();

// 인증번호 확인 Validation
export const certifyAuthCodeValidation = yup
  .object({
    authCode: yup
      .string()
      .required(requiredMessage)
      .matches(/^.{6}$/, "6자리로 입력해주세요."),
  })
  .required();

export const memberRegisterValidation = yup
  .object({
    lgnId: yup
      .string()
      .required(requiredMessage)
      .matches(/^[a-z0-9]{3,20}$/, "3~20글자 영문, 숫자를 입력해주세요."),
    nm: yup
      .string()
      .required(requiredMessage)
      .matches(/^[a-zA-Zㄱ-힣0-9]*$/, "특수문자는 입력이 불가능합니다.")
      .max(100, "100자리 이하로 입력해주세요."),
    // 숫자만
    // ex 021234567 => 9글자
    // ex 01012345678 => 11글자
    phone: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
    email: yup
      .string()
      .required(requiredMessage)
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    password: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?=.*\W)[a-zA-Z0-9\W]{8,20}$/, "특수문자 포함 영문 8~20글자를 입력해주세요."),
    passwordConfirmation: yup
      .string()
      .required(requiredMessage)
      .oneOf([yup.ref("password")], "비밀번호가 일치하지 않습니다."),
    rdnmadr: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    adres_detail: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    carModelCompany: yup.string().required("필수 선택 입니다.").notOneOf(["선택없음"], "필수선택입니다."),
    // carModelId: yup.string().required("필수 선택 입니다.").notOneOf(["선택없음"], "필수선택입니다."),
    carModelId: yup.mixed().test("carModelOption", "필수선택입니다", (value: any) => {
      if (value === undefined) {
        return false;
      }
      if (value === "None") {
        return false;
      }
      if (value.value === "None") {
        return false;
      }
      return true;
    }),
    carNo: yup
      .string()
      .required(requiredMessage)
      .matches(/^([가-힣]{2})?[0-9]{2,3}[가-힣]{1}\s?\d{4}$/, "올바르지 않은 차량번호 입니다."),
    hiddenPaycardNo: yup.string().required(requiredMessage),
  })
  .required();

// 법인 회원 가입 Validation
export const companyRegisterValidation = yup
  .object({
    lgnId: yup
      .string()
      .required(requiredMessage)
      .matches(/^[a-z0-9]{3,20}$/, "3~20글자 영문, 숫자를 입력해주세요."),
    nm: yup
      .string()
      .required(requiredMessage)
      .matches(/^[a-zA-Zㄱ-힣0-9]*$/, "특수문자는 입력이 불가능합니다.")
      .max(100, "100자리 이하로 입력해주세요."),
    // 숫자만
    // ex 021234567 => 9글자
    // ex 01012345678 => 11글자
    phone: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
    email: yup
      .string()
      .required(requiredMessage)
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    password: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?=.*\W)[a-zA-Z0-9\W]{8,20}$/, "특수문자 포함 영문 8~20글자를 입력해주세요."),
    passwordConfirmation: yup
      .string()
      .required(requiredMessage)
      .oneOf([yup.ref("password")], "비밀번호가 일치하지 않습니다."),
    rdnmadr: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    adresDetail: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    carModelCompany: yup.string().required("필수 선택 입니다.").notOneOf(["선택없음"], "필수선택입니다."),
    // carModelId: yup.string().required("필수 선택 입니다.").notOneOf(["선택없음"], "필수선택입니다."),
    carModelId: yup.mixed().test("carModelOption", "필수선택입니다", (value: any) => {
      if (value === undefined) {
        return false;
      }
      if (value === "None") {
        return false;
      }
      if (value.value === "None") {
        return false;
      }
      return true;
    }),
    carNo: yup
      .string()
      .required(requiredMessage)
      .matches(/^([가-힣]{2})?[0-9]{2,3}[가-힣]{1}\s?\d{4}$/, "올바르지 않은 차량번호 입니다."),
    hiddenPaycardNo: yup.string().required(requiredMessage),
    managerNm: yup.string().required(requiredMessage).max(100, "100자리 이하로 입력해주세요."),
    managerEmail: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    // 숫자만
    // ex 021234567 => 9글자
    // ex 01012345678 => 11글자
    managerCttpc: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
    coFax: yup
      .string()
      .matches(/^[0-9]*$/, "숫자만 입력해주세요.")
      .max(13, "13자리 이하로 입력해주세요."),
    file: yup.mixed().test("fileSize", "500KB 이하의 파일을 등록해주세요.", (value: any) => {
      if (value[0]) {
        return value[0].size <= 512000;
      } else {
        return true;
      }
    }),
  })
  .required();

export const paycardValidation = yup
  .object({
    paycardNo: yup.string().required(requiredMessage).max(100, "100자리 이하로 입력해주세요."),
    paycardExp: yup
      .string()
      .required(requiredMessage)
      .test("month", "유효하지 않은 값입니다.", (value) => {
        let month = value.split("-")[0];
        if (1 <= parseInt(month) && parseInt(month) < 13) {
          return true;
        } else {
          return false;
        }
      }),
    paycardBirth: yup.string().required(requiredMessage).max(10, "10자리 이하로 입력해주세요."),
    paycardPw: yup.string().required(requiredMessage).max(2, "2자리 이하로 입력해주세요."),
  })
  .required();

// 로그인 Validation
export const loginValidation = yup
  .object({
    lgnId: yup.string().required(requiredMessage),
    lgnPassword: yup.string().required(requiredMessage),
  })
  .required();

export const updateMemberUserValidation = yup
  .object({
    phone: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
    email: yup
      .string()
      .required(requiredMessage)
      // eslint-disable-next-line no-useless-escape
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    rdnmadr: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    // carModelId: yup.number().required(requiredMessage),
    carModelCompany: yup.string().required("필수 선택 입니다.").notOneOf(["선택없음"], "필수선택입니다."),
    carModelId: yup.mixed().test("carModelOption", "필수선택입니다", (value: any) => {
      if (value === undefined) {
        return false;
      }
      if (value.value === "None") {
        return false;
      }
      if (value.label === "선택없음") {
        return false;
      }
      return true;
    }),
    carNo: yup
      .string()
      .matches(/^([가-힣]{2})?[0-9]{2,3}[가-힣]{1}\s?\d{4}$/, "올바르지 않은 차량번호 입니다.")
      .required(requiredMessage),
  })
  .required();

// 법인회원정보 수정 Validation
export const updateCompanyUserValidation = yup
  .object({
    // 숫자만
    // ex 021234567 => 9글자
    // ex 01012345678 => 11글자
    phone: yup
      .string()
      // .matches(/^[0-9]*$/, "숫자만 입력해주세요.")
      // .max(13, "13자리 이하로 입력해주세요.")
      .required(requiredMessage)
      .matches(/^[0-9]{9,11}$/, "9~11글자의 핸드폰 번호(숫자만)를 입력해주세요."),
    email: yup
      .string()
      .required(requiredMessage)
      .matches(/^([\w\.\_\-]*[a-zA-Z0-9])+([-_]?[a-zA-Z0-9])+@[a-zA-Z0-9]+([-_]?[a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    rdnmadr: yup.string().required(requiredMessage).max(255, "255자리 이하로 입력해주세요."),
    carModelCompany: yup.string().required("필수 선택 입니다.").notOneOf(["선택없음"], "필수선택입니다."),
    carModelId: yup.mixed().test("carModelOption", "필수선택입니다", (value: any) => {
      if (value === undefined) {
        return false;
      }
      if (value.value === "None") {
        return false;
      }
      if (value.label === "선택없음") {
        return false;
      }
      return true;
    }),
    carNo: yup
      .string()
      .matches(/^([가-힣]{2})?[0-9]{2,3}[가-힣]{1}\s?\d{4}$/, "올바르지 않은 차량번호 입니다.")
      .required(requiredMessage),
    managerNm: yup.string().required(requiredMessage).max(100, "100자리 이하로 입력해주세요."),
    managerEmail: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i, emailTypeMessage)
      .max(255, "255자리 이하로 입력해주세요."),
    managerCttpc: yup
      .string()
      .matches(/^[0-9]{9,11}$/, "9~11글자의 연락처(숫자만)를 입력해주세요.")
      .required(requiredMessage),
    coFax: yup
      .string()
      .matches(/^[0-9]*$/, "숫자만 입력해주세요.")
      .max(13, "13자리 이하로 입력해주세요."),
    file: yup.mixed().test("fileSize", "500KB 이하의 파일을 등록해주세요.", (value: any) => {
      if (value[0]) {
        return value[0].size <= 512000;
      } else {
        return true;
      }
    }),
  })
  .required();

export const updatePasswordValidation = yup
  .object({
    password: yup
      .string()
      .required(requiredMessage)
      .matches(/^(?=.*\W)[a-zA-Z0-9\W]{8,20}$/, "특수문자 포함 영문 8~20글자를 입력해주세요."),
    passwordConfirmation: yup
      .string()
      .required(requiredMessage)
      .oneOf([yup.ref("password")], "비밀번호가 일치하지 않습니다."),
  })
  .required();

export const updatePaycardValidation = yup
  .object({
    paycardNo: yup.string().required(requiredMessage).max(100, "100자리 이하로 입력해주세요."),
    paycardExp: yup
      .string()
      .required(requiredMessage)
      .test("month", "유효하지 않은 값입니다.", (value) => {
        let month = value.split("-")[0];
        if (1 <= parseInt(month) && parseInt(month) < 13) {
          return true;
        } else {
          return false;
        }
      }),
    paycardBirth: yup.string().required(requiredMessage).max(10, "10자리 이하로 입력해주세요."),
    paycardPw: yup.string().required(requiredMessage).max(2, "2자리 이하로 입력해주세요."),
  })
  .required();

export const QRChargingValidation = yup
  .object({
    phone: yup
      .string()
      .required(requiredMessage)
      .matches(/^[0-9-]{10,13}$/, "10~13글자의 핸드폰 번호를 입력해주세요."),
    kwhPay: yup.string().nullable().oneOf(["100", "10000", "20000", "30000"], requiredMessage),
  })
  .required();
