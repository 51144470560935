import styled from "styled-components";
import { inlineWidth, mobileChangeWidth, payInfoOrangeColor, payInfoSubOrangeColor, payInfoSubTextColor, payInfotableBorder, payInfotableNavyColor, payInfotableOrangeColor } from "../../styles/theme";
import { Link } from "react-router-dom";

interface PayInfoTabButtonProps {
  bool?: boolean;
}

interface PayInfoTableProps {
  width?: string;
}
interface ToggleTitleProps {
  bool?: boolean;
}

export const PayInfoTabUl = styled.ul`
  display: flex;
  align-self: flex-start;
  gap: 10px;
  border-bottom: 2px solid ${payInfoOrangeColor};
`

export const PayInfoTabButton = styled.button<PayInfoTabButtonProps>`
  width: 220px;
  height: 54px;
  background-color: ${(props) => (props.bool ? `${payInfoOrangeColor}` : `${payInfoSubOrangeColor}`)};
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
  border-radius: 10px 10px 0 0;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    width: 140px;
    height: 36px;
    font-size: 0.875rem;
  }
`

export const PayInfoOrangeTable = styled.div<PayInfoTableProps>`
  width: ${(props) => props.width ?? '100%'};
  font-weight: 500;
  font-size: 1rem;
  display: flex; /* Flexbox 사용 */
  flex-direction: column; /* 세로로 정렬 */
  .title{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom:10px;
    .content{
      font-size: 1.25rem;
      margin-left: 8px;
    }
    .unit{
      align-self: flex-end;
    }
  }
  .unit{ 
    margin-left: auto;
    text-align: right;
    font-size: 14px;
    color: ${payInfoSubTextColor};
  }

  .table {
    width: 100%;
    display: flex;
    flex-direction: column; /* 세로로 정렬 */
    flex-grow: 1; /* 남은 공간을 채우도록 설정 */
  }

  .table .table_head {
    width: 100%;
    background-color: ${payInfotableOrangeColor};
  }

  .table .table_body{
    width: 100%; 
    flex-grow: 1; /* 남은 공간을 채우도록 설정 */
  }

  .table .table_body ul{
    border-bottom: 1px solid ${payInfotableBorder};
  }

  .table ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table li{
    width: 100%;
    text-align: center;
    padding: 14px 0;
  }

  .description{
    margin-top: 20px;

    li{
      color: ${payInfoSubTextColor};
      font-size:1rem;
      font-weight: 400;
    }

    li::before{
      content: '*';
      margin-right:4px;
    }
  }
  @media only screen and (max-width: ${mobileChangeWidth}) {
    .unit{
      margin: 4px 0;
      font-size: 12px;
    }
    .table li{
      font-size: 12px;
    }
    .description{
      li{
        font-size: 12px;
      }
    }
  }
`;

export const ToggleTitle = styled.div<ToggleTitleProps>`
  align-self: flex-start;
  button{
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.5rem;
    gap: 8px;
    &::before{
      content: '*';
    }
    img{
      margin-left:4px;
      transform: ${(props) => props.bool ? 'rotate(0deg)' : 'rotate(180deg)'};
    }
  }
  @media only screen and (max-width: ${mobileChangeWidth}) {
    button{
      font-size: 1.25rem;
      gap: 8px;
      img{
        width: 16px;
      }
    }
  }
`
export const KepcoContainer = styled.div`
align-self: flex-start;
width: 70%;
@media only screen and (max-width: ${inlineWidth}) {
    width: 100%;
  }`
export const PayInfoNavyTable = styled.div<PayInfoTableProps>`
  width: ${(props) => props.width ?? '100%'};
  font-weight: 500;
  font-size: 1rem;
  .table {
    width: 100%;
  }
  .table .table_head {
    width: 100%;
    background-color: ${payInfotableNavyColor};
  }
  .table .table_head .diagonal {
    position: relative;
    height: 80px;
    background-image: linear-gradient(to left bottom, transparent calc(50% - 1px), #ffffff, transparent calc(50% + 1px));
    background-size: 120% 120%;
    background-position: center;
    .rightTop{
      position: absolute;
      right: 10%;
      top: 20px;
    }
    .leftBottom{
      position: absolute;
      left: 10%;
      bottom: 20px;
    }
  }
  .table .table_body{
    width: 100%; 
  }
  .table .table_body ul{
    border-bottom: 1px solid ${payInfotableBorder};
  }
  .table ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table .table_head li{
    padding: 0;
    color: #ffffff;
  }
  .table li{
    width: 100%;
    text-align: center;
    padding: 14px 0;
  }
  .description{
    margin-top: 20px;

    li{
      color: ${payInfoSubTextColor};
      font-size:1rem;
      font-weight: 400;
    }
    li::before{
      content: '※';
      margin-right:4px;
    }
  }
  @media only screen and (max-width: ${mobileChangeWidth}) {
    .table li{
      font-size: 12px;
    }
    .description{
      li{
        font-size: 12px;
      }
    }
  }
`
export const FastChargingContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  align-items: stretch;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    flex-direction: column;
    font-size: 12px;
    min-width: 100px;
    width: 100%;
  }
`;

export const RoamingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .description{
    color: ${payInfoSubTextColor};
  }
  @media only screen and (max-width: ${inlineWidth}) {
    gap:20px;
    flex-direction: column;
  }
`

export const RoamingLink = styled(Link)`
  padding: 16px 120px;
  background-color: #525888;
  border-radius: 50px;
  font-weight: 600;
  font-size: 1.25rem;
  color: #ffffff;
`