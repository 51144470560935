import styled from "styled-components";

const Table = styled.div`
  width: 100%;
  border-bottom: 1px solid #555;
`;

interface ItemProps {
  width?: string;
  textAlign?: string;
  justifyContent?: string;
  padding?: string;
}
const Item = styled.div<ItemProps>`
  width: ${(props) => props.width ?? "100%"};
  border-top: 0.5px solid #555;
  padding: ${(props) => props.padding ?? "1rem"};
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? "center"};
  text-align: ${(props) => props.textAlign ?? "center"};
`;

export { Table, Item };
