/* eslint-disable react-hooks/exhaustive-deps */
import { Font, Img, Row } from "../../styles/commonStyle";
import { ContentBox, ImgButton, Layout } from "./style";
import bgImage from "../../assets/images/img-bg-full-apply.jpg";
import logoTop from "../../assets/images/logo-top.png";
import serviceReady from "../../assets/images/service_ready.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { platformSelector } from "../../recoil/variableAtom";
import { RESULT, ResponseModel } from "../../model/response";
import useFetch from "../../hooks/useFetch";

function QRplatformPage() {
  const [getQuery, setQuery] = useState<any>();
  const { roamingId } = useParams();
  let navigate = useNavigate();
  const response: ResponseModel<any> = useFetch({
    recoilSelector: platformSelector,
    query: getQuery,
  });
  useEffect(() => {
    setQuery({
      roamingIdntfr: roamingId,
    });
  }, []);

  useEffect(() => {
    if (response?.result === RESULT.FAIL) {
      alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
      navigate(`/`);
    }
  }, [response]);

  function handleClick(roamingId: string) {
    window.location.href = "/QRplatformPay/" + roamingId;
  }
  return (
    <Layout image={bgImage}>
      <ContentBox paddingLeft="1.5rem" paddingRight="1.5rem">
        <Font fontSize="1.75rem" fontWeight="600">
          비회원 QR플랫폼 결제
        </Font>
        <Font fontWeight="600" className="w-full mt-[2rem]">
          * QR결제방식 안내
        </Font>
        <Font fontWeight="500" className="w-full">
          1. 이용하고자 하는 서비스를 선택하여, QR결제를 진행합니다.
        </Font>
        <Font fontWeight="500" className="w-full">
          2. 보다 자세한 QR결제 진행 방법은 각 서비스에 접속하여 확인해주시기 바랍니다.
        </Font>
        <Row className="mt-[2rem]">
          <ImgButton className="drop-shadow-md" onClick={() => handleClick(roamingId!)}>
            <Img src={logoTop} alt="evPlug_logo_top"></Img>
          </ImgButton>
          <ImgButton className="drop-shadow-md">
            <Img src={serviceReady} alt="service_ready1"></Img>
          </ImgButton>
          <ImgButton className="drop-shadow-md">
            <Img src={serviceReady} alt="service_ready2"></Img>
          </ImgButton>
        </Row>
      </ContentBox>
    </Layout>
  );
}
export default QRplatformPage;
