import { cardTitleColor, fontColor, primaryColor } from "../../styles/theme";
import { Font } from "../../styles/commonStyle";
import { ProgressBar, ProgressInner, ProgressTexts, RemainValueBox } from "./style";
import { roundToTwoDecimalPlaces } from "../../util";

function Progress({ userGradeInfo, membershipInfoList }: { userGradeInfo: any, membershipInfoList: any }) {
    const getGradeInfo = (index: number) => {
        const gradeInfo = userGradeInfo.gradeInfoList[index];
        const nextGradeInfo = userGradeInfo.gradeInfoList[index - 1];
        const progress = index === 0 ? 100 : (userGradeInfo.comulateQy - gradeInfo.calculateMinValue) / (nextGradeInfo.calculateMinValue - gradeInfo.calculateMinValue) * 100;
        return {
            gradeInfo,
            nextGradeInfo,
            progress
        };
    };

    // 등급 정보 가져오기
    const gradeIndex = userGradeInfo.gradeInfoList.findIndex((info: { calculateMinValue: number; }) => userGradeInfo.comulateQy >= info.calculateMinValue);
    const { gradeInfo, nextGradeInfo, progress } = getGradeInfo(gradeIndex);

    return (
        <>
            <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px" className="w-full">
                {gradeIndex === 0 ?
                    `이미 ${userGradeInfo.gradeInfoList[0].nm} 최고 등급 입니다. 대단히 감사합니다.`
                    :
                    membershipInfoList.calculateTy == 'PRICE' ? `${roundToTwoDecimalPlaces(nextGradeInfo.calculateMinValue - userGradeInfo.comulateQy)}원 추가 충전 결제 시 ${nextGradeInfo.nm} 달성!`
                        : `${roundToTwoDecimalPlaces(nextGradeInfo.calculateMinValue - userGradeInfo.comulateQy)}kWh 추가 충전 결제 시 ${nextGradeInfo.nm} 달성!`}
            </Font>
            <ProgressTexts>
                {
                    gradeIndex === 0 ? <>
                        <span></span>
                        <Font fontSize="1rem" fontWeight="500" color={primaryColor}>다음 등급 최고 등급 달성!</Font>
                    </> : <>
                        <Font fontSize="1rem" fontWeight="500" color={fontColor}>현재 등급 {userGradeInfo.memberGrade.nm}</Font>
                        <Font fontSize="1rem" fontWeight="500" color={primaryColor}>다음 등급 {nextGradeInfo.nm}</Font>
                    </>
                }


            </ProgressTexts>
            <ProgressBar heartbeatBoolean={gradeIndex === 0}>
                <ProgressInner width={progress} />
            </ProgressBar>
            <RemainValueBox>
                <div className="triangle"></div>
                <span>+{Number(userGradeInfo.comulateQy)}{membershipInfoList.calculateTy == 'PRICE' ? '원' : 'kWh'}</span>
            </RemainValueBox>
        </>
    )
}


export default Progress;
