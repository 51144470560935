/* eslint-disable react-hooks/exhaustive-deps */
import { FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Blank, Div, Font, Input, Label, LineBox, LineInputBox, LineInputLabel, Radio, RadioBox, Span, WrapMobile } from "../../styles/commonStyle";
import { listBorderColor, noticeRedColor } from "../../styles/theme";
import Dropdown, { Group, Option } from "react-dropdown";
import { POSTCODE_URL } from "../../constants";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { useEffect, useState } from "react";

function InputLine({
  label,
  name,
  placeholder,
  necessary,
  type,
  selectList,
  valueList,
  register,
  errors,
  onClick,
  noBorder,
  options,
  setValue,
  getValues,
  labelWidth,
  onChange,
  readOnly,
  readData,
  labelColor,
  defaultValue,
  isBottomlabel,
  bottomLabel,
  tabIndex,
  disabled,
  value
}: {
  label?: string;
  name: string;
  placeholder?: string;
  necessary?: boolean;
  type: string;
  selectList?: Array<string>;
  valueList?: Array<string>;
  register?: UseFormRegister<FieldValues>;
  errors?: any;
  onClick?: () => void;
  noBorder?: boolean;
  options?: (Group | Option | string)[];
  setValue?: UseFormSetValue<FieldValues>;
  getValues?: UseFormGetValues<FieldValues>;
  labelWidth?: string;
  onChange?: any;
  readOnly?: boolean;
  readData?: string | JSX.Element;
  labelColor?: string;
  defaultValue?: string;
  isBottomlabel?: boolean;
  bottomLabel?: string;
  tabIndex?: number;
  disabled?: boolean;
  value?: any
}) {
  const openPostCode = useDaumPostcodePopup(POSTCODE_URL);

  const postCodeHandleClick = () => {
    openPostCode({ onComplete: postCodeHandleComplete });
  };
  const postCodeHandleComplete = (data: any) => {
    setValue!(
      "rdnmadr",
      data.roadAddress
      // { shouldValidate: true }
    );
    setValue!("adres", data.jibunAddress === "" ? data.autoJibunAddress : data.jibunAddress);
    setValue!("zipCode", data.zonecode);
  };


  let labelContent = label ? (
    <LineInputLabel width={labelWidth} bgColor={labelColor} className="p-[0.5rem]">
      {label}
      {necessary ? <Span color={noticeRedColor}>*</Span> : null}
    </LineInputLabel>
  ) : null;

  return (
    <LineBox noBorder={noBorder}>
      <WrapMobile>
        {labelContent}
        <LineInputBox>
          {(() => {
            switch (type) {
              case "text":
                return (
                  <Div className="w-full">
                    <Input
                      type={type}
                      placeholder={placeholder}
                      {...register!(name)}
                      name={name}
                      tabIndex={tabIndex}
                      isError={errors ? true : false}
                      onClick={onClick}
                      onChange={onChange ? (e) => onChange(e) : (e) => setValue!(name, e.target.value)}
                      onKeyDown={readOnly ? (e) => e.preventDefault() : undefined}
                      defaultValue={defaultValue}
                      disabled={disabled}
                      value={value}

                    />
                    {isBottomlabel ? (
                      <Font fontSize="0.9rem" color={listBorderColor}>
                        {bottomLabel}
                      </Font>
                    ) : null}
                  </Div>
                );
              case "number":
                return (
                  <Div className="w-full">
                    <Input
                      type={type}
                      placeholder={placeholder}
                      {...register!(name)}
                      name={name}
                      tabIndex={tabIndex}
                      isError={errors ? true : false}
                      onClick={onClick}
                      onChange={onChange ? (e) => onChange(e) : (e) => setValue!(name, e.target.value)}
                      onKeyDown={readOnly ? (e) => e.preventDefault() : undefined}
                      defaultValue={defaultValue}
                    // key={defaultValue}
                    />
                    {isBottomlabel ? (
                      <Font fontSize="0.9rem" color={listBorderColor}>
                        {bottomLabel}
                      </Font>
                    ) : null}
                  </Div>
                );
              case "password":
                return (
                  <Div className="w-full">
                    <Input
                      type={type}
                      placeholder={placeholder}
                      {...register!(name)}
                      name={name}
                      tabIndex={tabIndex}
                      isError={errors ? true : false}
                      onClick={onClick}
                      onChange={onChange ? (e) => onChange(e) : (e) => setValue!(name, e.target.value)}
                      onKeyDown={readOnly ? (e) => e.preventDefault() : undefined}
                      autoComplete="off"
                    />
                  </Div>
                );
              case "adres":
                return (
                  <Div className="w-full">
                    <Input
                      type={type}
                      placeholder={placeholder}
                      {...register!(name)}
                      name={name}
                      tabIndex={tabIndex}
                      isError={errors ? true : false}
                      onClick={postCodeHandleClick}
                      onKeyDown={readOnly ? (e) => e.preventDefault() : undefined}
                      defaultValue={defaultValue}
                    // key={defaultValue}
                    />
                  </Div>
                );
              case "radio":
                return (
                  <RadioBox>
                    {selectList?.map((value, index) => (
                      <Div className="flex items-center max-[640px]:pb-[0.5rem]" key={label + value}>
                        <Label className="whitespace-nowrap" changeFontSize="12px">
                          <Radio id={label + value} type={type} value={valueList![index]} tabIndex={tabIndex} {...register!(name)} name={name} />
                          <Blank width="4px" />
                          {value}
                          <Blank width="16px" />
                        </Label>
                      </Div>
                    ))}
                  </RadioBox>
                );
              case "dropdown":
                return (
                  <Div className="w-full">
                    <Dropdown
                      options={options!}
                      onChange={(e) => onChange(e)}
                      value={getValues!(name) ?? "선택없음"}
                      placeholder="Select an option"
                    />
                  </Div>
                );
              case "file":
                return (
                  <Div className="w-full filebox">
                    <Input
                      className="upload-name"
                      type={type}
                      placeholder={placeholder}
                      {...register!(name)}
                      name={name}
                      isError={errors ? true : false}
                      accept=".zip"
                      onKeyDown={readOnly ? (e) => e.preventDefault() : undefined}
                      fontSize="calc(0.7rem + 0.3vw)"
                    />
                  </Div>
                );
              case "read":
                return (
                  <Font className="w-full" fontWeight="500">
                    {readData}
                  </Font>
                );
              default:
                return null;
            }
          })()}
        </LineInputBox>
      </WrapMobile>
      <WrapMobile>
        <LineInputLabel width={labelWidth} bgColor={labelColor}></LineInputLabel>
        <Font color={noticeRedColor} className="w-[80%] ps-[0.5rem]" fontSize="calc(0.7rem + 0.3vw)">
          {errors}
        </Font>
      </WrapMobile>
    </LineBox>
  );
}
export default InputLine;
