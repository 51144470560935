
import Modal from "react-modal";
import ReactModal from "react-modal";
import { useRecoilState } from "recoil";
import { customStyles } from "../../styles/theme";
import React, { useEffect, useRef, useState } from "react";
import { isPopupOpenState } from "../../recoil/atoms";
import { Notice } from "../../model/notice";
import { ModalBox, PopupBtns } from "./style";
import { Font, CheckBox, Blank, Div } from "../../styles/commonStyle";
import { setCookie } from "../../util";
import Loading from "../loading/loading";
const parse = require("html-react-parser");

Modal.setAppElement("#root");

function EvPopup({ notice }: { notice?: Notice; }) {
  const [isOpen, setIsOpen] = useRecoilState(isPopupOpenState);
  const [isCheck, setIsCheck] = useState(false);

  function closeModal() {
    if (isCheck && notice != undefined) {
      const expiration = new Date(new Date().setHours(24, 0, 0, 0));
      console.log(expiration);
      setCookie(
        `${notice?.id}`, `${notice?.id}`, {
        expires: expiration
      }
      )
    }
    setIsOpen(false);
  }
  useEffect(() => { }, [isOpen])

  ReactModal.defaultStyles.overlay!.backgroundColor = "rgba( 0, 0, 0, 0)";
  return (
    <Modal isOpen={isOpen} style={customStyles} contentLabel="EvPlug popup" shouldCloseOnOverlayClick={true}>
      <ModalBox width="488px">
        <div
          style={{
            'width': "100%",
            'display': 'flex'
          }}
        >
          <Div className="w-full" id="detailContent" >
            {parse(notice?.cn)}
          </Div>
        </div>
        <PopupBtns>
          <div style={{ width: '75%', borderRight: "1px solid white", display: 'flex', alignItems: 'center' }}>
            <Font fontSize="12px" fontWeight="500" className="p-[16px]" color="white" >
              오늘 하루 열지 않음
            </Font>
            <CheckBox type="checkbox" className="me-[8px]" color="white" checked={isCheck} onChange={() => { setIsCheck(!isCheck) }}  ></CheckBox>
          </div>
          <div style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}
            onClick={(e) => { closeModal() }}
          >
            <Font fontSize="12px" fontWeight="500" className="p-[16px]" color="white" >
              닫기
              <Blank width="4px"></Blank>
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 14 14" fill="none">
                <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="white" />
              </svg>
            </Font>
          </div>
        </PopupBtns>
      </ModalBox>
    </Modal >
  );
}


export default React.memo(EvPopup);
