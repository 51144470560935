import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Div } from "../../../styles/commonStyle";
import { SearchInput, SearchRightBox } from "../style";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { searchColor } from "../../../styles/theme";
import React, { useState } from "react";

function Search({ execSearch }: { execSearch: (e: any) => void }) {
  const [search, setSearch] = useState("");
  const inputSearch = (e: any) => {
    setSearch(e.target.value);
  };
  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      execSearch(e);
    }
  };
  return (
    <Div className="flex justify-end w-full">
      <SearchInput placeholder="검색어" onChange={(e) => inputSearch(e)} onKeyDown={onKeyPress}></SearchInput>
      <SearchRightBox value={search} onClick={(e) => execSearch(e)}>
        <FontAwesomeIcon icon={faMagnifyingGlass} color={searchColor} className="searchGlass" />
      </SearchRightBox>
    </Div>
  );
}
export default React.memo(Search);
