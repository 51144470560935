import styled from "styled-components";
import { cardTitleColor, primaryColor, searchBorderColor } from "../../styles/theme";

interface PageBoxProps {
  bottomLeftRadius?: string;
  topLeftRadius?: string;
  bottomRightRadius?: string;
  topRightRadius?: string;
  bgColorChange?: boolean;
}

const PageButton = styled.button<PageBoxProps>`
  border: 1px solid ${searchBorderColor};
  color: ${(props) => (props.bgColorChange === false ? primaryColor : "#FFF")};
  font-size: 16px;
  padding: 6px 12px;
  background-color: ${(props) => (props.bgColorChange === true ? primaryColor : "#FFF")};
  margin-left: -1px;
  font-weight: 600;
  border-bottom-left-radius: ${(props) => props.bottomLeftRadius};
  border-top-left-radius: ${(props) => props.topLeftRadius};
  border-bottom-right-radius: ${(props) => props.bottomRightRadius};
  border-top-right-radius: ${(props) => props.topRightRadius};
  /* :hover {
    background-color: #f7f7f7;
    color: ${cardTitleColor};
  } */
`;

const LeftArrow = styled.div`
  width: 10px;
  height: 10px;
  border: solid ${primaryColor};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 7px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
`;

const RightArrow = styled.div`
  width: 10px;
  height: 10px;
  border: solid ${primaryColor};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 7px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

const ArrowBtn = styled.button`
  display: flex;
  align-items: center;
`;
export { PageButton, LeftArrow, RightArrow, ArrowBtn };
