import { Link } from "react-router-dom";
import icoComp from "../../assets/images/icon-comp.png";
import { Blank, Div, EvButton, Font, Img, PageLayout, Span } from "../../styles/commonStyle";
import { listBorderColor, primaryColor } from "../../styles/theme";
import { orangeBtnGradient, spanBlueColor } from "../../styles/theme";

function ChargerReqComplete() {
  return (
    <PageLayout>
      <Img src={icoComp} className="my-[48px]"></Img>
      <Font fontSize="1.75rem" fontWeight="500">
        충전기 설치신청이 완료되었습니다.
      </Font>
      <Font fontSize="20px" fontWeight="500" className="my-[16px]">
        <Span color={spanBlueColor}>담당자</Span>가&nbsp;<Span color={spanBlueColor}>3일 안</Span>에&nbsp;<Span color={spanBlueColor}>연락</Span>
        드리도록 하겠습니다.
      </Font>
      <Blank height="24px" />
      <Div className="w-full justify-center flex">
        <Link to="/">
          <EvButton fontSize="20px" bgColor={listBorderColor} color="#FFF">
            홈으로
          </EvButton>
        </Link>
        <Blank width="10px" />
        <Link to="/subsidy">
          <EvButton fontSize="20px" bgColor={primaryColor} color="#FFF">
            보조금 정보
          </EvButton>
        </Link>
      </Div>
    </PageLayout>
  );
}

export default ChargerReqComplete;
