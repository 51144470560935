import styled from "styled-components";
import { labelBgColor, listBorderColor, noticeFontColor, searchBorderColor } from "../../../styles/theme";

const PointInfoLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const PointInfoDiv = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ColDivider = styled.div`
height: 150px;
width : 1px;
background-color:black;
opacity: 0.1;
`;


const QToolTipDiv = styled.div`
position: absolute;
right: -30px;
top: 2px;
font-size: 1rem;
border: 1px solid;
border-radius: 100%;
width: 22px;
height: 22px;
display: flex;
align-items: center;
justify-content: center;
`;


export { PointInfoLayout, PointInfoDiv, ColDivider, QToolTipDiv };
