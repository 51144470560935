/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilState } from "recoil";
import { EvButton, Font, Form } from "../../styles/commonStyle";
import { orangeBtnGradient, primaryColor } from "../../styles/theme";
import { ModalBorderContent, ModalBox, ModalContent } from "./style";
import { FieldValues, UseFormSetValue, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { paycardValidation } from "../../validation";
import InputLine from "../input/inputLine";
import { checkKeyDown, makeHyphenInput } from "../../util";
import { modalState } from "../../recoil/atoms";

function FindCreditCardModal({ setValue }: { setValue: UseFormSetValue<FieldValues> }) {
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const {
    register: creditCardRegister,
    handleSubmit: creditCardHandleSubmit,
    formState: { errors: creditCardErrors },
    setValue: creditCardSetValue,
  } = useForm({
    resolver: yupResolver(paycardValidation),
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  async function OnSubmit(data: FieldValues) {
    let cardNo = data.paycardNo.split("- ");
    cardNo = cardNo[0].split("-");
    for (let i = 0; i < cardNo.length - 1; i++) {
      cardNo[i] = cardNo[i].replace(/[0-9]/gi, "*");
    }
    setValue("hiddenPaycardNo", cardNo.join("-"), { shouldValidate: true });
    setValue("paycardNo", data.paycardNo.replace(/ /gi, "").replace(/-/gi, ""));
    setValue("paycardExp", data.paycardExp.replace(/ /gi, "").replace(/-/gi, ""));
    setValue("paycardBirth", data.paycardBirth.replace(/ /gi, "").replace(/-/gi, ""));
    setValue("paycardPw", data.paycardPw.replace(/ /gi, ""));

    setIsOpen(false);
  }
  return (
    <ModalBox width="450px">
      <Font fontSize="17px" fontWeight="500" className="p-[16px]">
        신용카드 등록
      </Font>
      <Form
        className="w-full"
        key={2}
        // onKeyDown={(e) => checkKeyDown(e)}
        onSubmit={(e) => {
          e.preventDefault();
          creditCardHandleSubmit(OnSubmit)(e);
          e.stopPropagation(); //외부 form의 submit버튼이 실행되는 것을 막아줌.
        }}
        onKeyDown={checkKeyDown}
      >
        <ModalBorderContent className="flex-col">
          <InputLine
            label="카드번호"
            name="paycardNo"
            type="text"
            necessary={true}
            register={creditCardRegister}
            errors={creditCardErrors.paycardNo ? creditCardErrors.paycardNo.message : undefined}
            noBorder={true}
            labelWidth="40%"
            isBottomlabel={true}
            tabIndex={31}
            bottomLabel="예) 1234-1234-1234-1234"
            onChange={(e: any) => {
              makeHyphenInput(e, "paycardNo");
              creditCardSetValue("paycardNo", e.target.value);
            }}
          />
          <InputLine
            label="유효기간"
            name="paycardExp"
            type="text"
            necessary={true}
            register={creditCardRegister}
            errors={creditCardErrors.paycardExp ? creditCardErrors.paycardExp.message : undefined}
            noBorder={true}
            labelWidth="40%"
            isBottomlabel={true}
            tabIndex={32}
            bottomLabel="월-년 순서로 입력, 예) 08-24"
            onChange={(e: any) => {
              makeHyphenInput(e, "paycardExp");
              creditCardSetValue("paycardExp", e.target.value);
            }}
          />
          <InputLine
            label="생년월일/사업자번호"
            name="paycardBirth"
            type="text"
            necessary={true}
            register={creditCardRegister}
            errors={creditCardErrors.paycardBirth ? creditCardErrors.paycardBirth.message : undefined}
            noBorder={true}
            labelWidth="40%"
            setValue={creditCardSetValue}
            isBottomlabel={true}
            tabIndex={33}
            bottomLabel="예) 850823(개인), 1234578900(사업자)"
          />
          <InputLine
            label="비밀번호 앞 2자리"
            name="paycardPw"
            type="password"
            necessary={true}
            register={creditCardRegister}
            errors={creditCardErrors.paycardPw ? creditCardErrors.paycardPw.message : undefined}
            noBorder={true}
            labelWidth="40%"
            setValue={creditCardSetValue}
            tabIndex={34}
          />
        </ModalBorderContent>
        <ModalContent>
          <EvButton fontSize="1.2rem" color="#FFF" bgColor={primaryColor} padding="8px 28px" type="submit">
            등록
          </EvButton>
        </ModalContent>
      </Form>
    </ModalBox>
  );
}
export default FindCreditCardModal;
