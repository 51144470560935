/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Font } from "../../styles/commonStyle";
import { fontColor, primaryColor } from "../../styles/theme";
import { LevelBenefitsListStyled, LevelLogo, LevelPercent, LevelStandard } from "./style";
import IconMemberFamily from '../../assets/images/grade-Family.png';
import IconMemberVip from '../../assets/images/grade-VIP.png';
import IconMemberVvip from '../../assets/images/grade-VVIP.png';
import IconMemberVvipplus from '../../assets/images/grade-VVIPplus.png';
import { useEffect, useState } from "react";
import { getDominantColor } from "../../util";

function LevelBenefitsList({ membershipInfoList }: { membershipInfoList: any }) {
    // const [reverseGradeInfoList, setreverseGradeInfoList] = useState<any>();
    const reversedGradeInfoList = membershipInfoList?.gradeInfoList ? [...membershipInfoList.gradeInfoList].reverse() : [];
    return (
        <LevelBenefitsListStyled className="w-full flex">
            <li className="w-1/5">
                <LevelLogo className="none"></LevelLogo>
                <LevelPercent className="text-center">
                    <Font fontSize="1.25rem" fontWeight="500" color="#ffffff" changeFontSize="16px" className="w-full flex justify-center">
                        {"."}
                    </Font>
                </LevelPercent>
                <LevelPercent className="text-center">
                    <Font fontSize="1.25rem" fontWeight="500" color="#2B2B2B" changeFontSize="16px" className="w-full flex justify-center">
                        리워드 비율
                    </Font>
                </LevelPercent>
                <LevelStandard className="text-center">
                    <Font fontSize="1rem" fontWeight="500" color="#2B2B2B" changeFontSize="16px" className="w-full flex justify-center">
                        등급 기준 (이상 ~ 미만)
                    </Font>
                    <div className="mt-1">
                        <Font fontSize="0.75rem" fontWeight="400" color="#2B2B2B" changeFontSize="12px" className="w-full flex justify-center">
                            {membershipInfoList ?
                                membershipInfoList.calculateTy == 'PRICE' ? `최근 ${membershipInfoList.countingPd}개월간의 누적 충전 금액` : `최근 ${membershipInfoList.countingPd}개월간의 누적 충전량` : "최근 3개월간의 누적 충전량"}
                        </Font>
                    </div>
                </LevelStandard>
            </li>
            {
                membershipInfoList ? (
                    reversedGradeInfoList.map((item: any, idx: number) => {
                        return <LevelBenefitsItem item={item} idx={idx} membershipInfoList={membershipInfoList} ></LevelBenefitsItem>
                    })
                ) : (<>
                    <li className="w-1/5">
                        <LevelLogo color={primaryColor} className="text-center"><img src={IconMemberFamily} alt="family" /></LevelLogo>
                        <LevelPercent className="text-center">
                            <Font fontSize="1.25rem" fontWeight="500" color={primaryColor} changeFontSize="16px" className="w-full flex justify-center">
                                0.1% 적립
                            </Font>
                        </LevelPercent>
                        <LevelStandard className="text-center">
                            <Font fontSize="1rem" fontWeight="500" color="#2B2B2B" changeFontSize="16px" className="w-full flex justify-center">
                                evPlug 회원이면 누구나
                            </Font>
                        </LevelStandard>
                    </li>
                    <li className="w-1/5">
                        <LevelLogo color={primaryColor} className="text-center"><img src={IconMemberVip} alt="vip" /></LevelLogo>
                        <LevelPercent className="text-center">
                            <Font fontSize="1.25rem" fontWeight="500" color={primaryColor} changeFontSize="16px" className="w-full flex justify-center">
                                1% 적립
                            </Font>
                        </LevelPercent>
                        <LevelStandard className="text-center">
                            <Font fontSize="1rem" fontWeight="500" color="#2B2B2B" changeFontSize="16px" className="w-full flex justify-center">
                                301kWh ~ 900kWh 충전
                            </Font>
                        </LevelStandard>
                    </li>
                    <li className="w-1/5">
                        <LevelLogo color={primaryColor} className="text-center"><img src={IconMemberVvip} alt="vvip" /></LevelLogo>
                        <LevelPercent className="text-center">
                            <Font fontSize="1.25rem" fontWeight="500" color={primaryColor} changeFontSize="16px" className="w-full flex justify-center">
                                3% 적립
                            </Font>
                        </LevelPercent>
                        <LevelStandard className="text-center">
                            <Font fontSize="1rem" fontWeight="500" color="#2B2B2B" changeFontSize="16px" className="w-full flex justify-center">
                                901kWh ~ 1800kWh 충전
                            </Font>
                        </LevelStandard>
                    </li>
                    <li className="w-1/5">
                        <LevelLogo color={primaryColor} className="text-center"><img src={IconMemberVvipplus} alt="vvip plus" /></LevelLogo>
                        <LevelPercent className="text-center">
                            <Font fontSize="1.25rem" fontWeight="500" color={primaryColor} changeFontSize="16px" className="w-full flex justify-center">
                                5% 적립
                            </Font>
                        </LevelPercent>
                        <LevelStandard className="text-center">
                            <Font fontSize="1rem" fontWeight="500" color="#2B2B2B" changeFontSize="16px" className="w-full flex justify-center">
                                1800kWh 이상 충전
                            </Font>
                        </LevelStandard>
                    </li>
                </>)
            }
        </LevelBenefitsListStyled>
    );
}
export default LevelBenefitsList;



function LevelBenefitsItem({ item, idx, membershipInfoList }: { item: any, idx: any, membershipInfoList: any }) {
    const [color, setColor] = useState<any>();

    const getImageColor = async (img: string) => {
        setColor(await getDominantColor(img))
    }

    useEffect(() => {
        getImageColor(`${process.env.REACT_APP_MEMBERSHIP_API_HOST}/api/v2/grade/img/TD/${item.imgNm}`)
    }, [])

    return (
        <li className="w-1/5" key={idx}>
            <LevelLogo color={primaryColor} className="text-center"><img src={
                `${process.env.REACT_APP_MEMBERSHIP_API_HOST}/api/v2/grade/img/TD/${item.imgNm}`
            } alt="family" /></LevelLogo>
            <LevelPercent className="text-center">
                <Font fontSize="1.25rem" fontWeight="500" color={color} changeFontSize="16px" className="w-full flex justify-center">
                    {item.nm}
                </Font>
            </LevelPercent>
            <LevelPercent className="text-center">
                <Font fontSize="1.25rem" fontWeight="500" color={primaryColor} changeFontSize="16px" className="w-full flex justify-center">
                    {item.mileageValue}% 적립
                </Font>
            </LevelPercent>
            <LevelStandard className="text-center">
                {
                    membershipInfoList.calculateTy == "PRICE" ? <Font fontSize="1rem" fontWeight="500" color="#2B2B2B" changeFontSize="16px" className="w-full flex justify-center">
                        {
                            idx === 0
                                ? 'evPlug 회원이면 누구나'
                                : idx === membershipInfoList.gradeInfoList.length - 1
                                    ? `${item.calculateMinValue}원 이상 충전`
                                    : `${item.calculateMinValue}원 ~ ${item.calculateMaxValue}원 충전`
                        }
                    </Font> : <Font fontSize="1rem" fontWeight="500" color="#2B2B2B" changeFontSize="16px" className="w-full flex justify-center">
                        {
                            idx === 0
                                ? 'evPlug 회원이면 누구나'
                                : idx === membershipInfoList.gradeInfoList.length - 1
                                    ? `${item.calculateMinValue}kWh 이상 충전`
                                    : `${item.calculateMinValue}kWh ~ ${item.calculateMaxValue}kWh 충전`
                        }
                    </Font>
                }

            </LevelStandard>
        </li>


    );
}
