/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Blank, CheckBox, Div, Font, Form, Img, Input, Radio, Span } from "../../styles/commonStyle";
import {
  AgreeAllBox,
  Arrow,
  ChargingResultBox,
  ContentBox,
  Layout,
  PageMoveBtn,
  StipulationBox,
  StipulationContentBox,
  StipulationHeaderBox,
} from "./style";
import bgImage from "../../assets/images/img-bg-full-apply.jpg";
import { FieldError, FieldErrorsImpl, FieldValues, Merge, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { QRChargingValidation } from "../../validation";
import { lightBlueColor, modalGreyBtnColor, noticeRedColor, primaryColor } from "../../styles/theme";
import { useEffect, useState } from "react";
import { Charger, ChargerSttus } from "../../model/charger";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  findChargerQrSelector,
  platformSelector,
  smartroRoamingSelector,
  smartroSelector,
  stipulation1Selector,
  stipulationQR1Selector,
  stipulationQR2Selector,
} from "../../recoil/variableAtom";
import { useRecoilState } from "recoil";
import expandLess from "../../assets/images/expand_less.svg";
import expandMore from "../../assets/images/expand_more.svg";
import { Stipulation } from "../../model/stipulation";
import { RESULT, ResponseModel } from "../../model/response";
import arrowNextActive from "../../assets/images/arrow-next-active.svg";
import arrowPrev from "../../assets/images/arrow-prev.svg";
import { isMobile } from "react-device-detect";
import { GetSmartroDate, makeMoid, makePayEncryptData } from "../../util";
import useFetch from "../../hooks/useFetch";
import usePost from "../../hooks/usePost";
import Loading from "../../components/loading/loading";
import { smartroRoamingState, smartroState, variableState } from "../../recoil/atoms";

function QRpaymentPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(QRChargingValidation),
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const defaultKwhPay = "20000";
  const [kwhPay, setKwhPay] = useState(defaultKwhPay);
  const { qrcode } = useParams();
  let navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [getCharger, setCharger] = useState<Charger>();
  const [getChargerQuery, setChargerQuery] = useRecoilState(variableState);
  const [isOpenStipulation, setOpenStipulation] = useState([false, false, false]);
  const [stipulationAgree, setStipulationAgree] = useState([false, false, false]);
  const [stipulationDataList, setStipulationDataList] = useState<Array<string>>([]);
  const stipulationHeaderList = [
    "(필수) 이브이플러그(evPlug) 서비스 이용약관에 동의합니다.",
    "(필수) 전자금융거래 이용약관에 동의합니다.",
    "(필수) 개인정보 수집 및 이용에 동의합니다.",
  ];
  const [BuyerTel, setPhone] = useState("");
  const [VatAmt, setVatAmt] = useState(Math.floor(Number(defaultKwhPay) - Number(defaultKwhPay) / 1.1));
  const [TaxAmt, setTaxAmt] = useState(Number(defaultKwhPay) - Math.floor(Number(defaultKwhPay) - Number(defaultKwhPay) / 1.1));
  const moid = makeMoid();
  const mid = process.env.REACT_APP_SMARTRO_QR_MID;
  const edidate = GetSmartroDate(true);
  const currentLastPath = "/charging_info";
  const [searchParams, setSearchParams] = useSearchParams();
  const smartro_pay_result = searchParams.get("result");
  const location = useLocation();
  const state = location.state;
  const [getQuery, setQuery] = useState<any>();
  const [getQueryRoaming, setQueryRoaming] = useState<any>();
  const [getSmartroQuery, setSmartroQuery] = useState<any>();
  const [getResponse, setResponse] = useState<ResponseModel<Charger>>();
  const [getSmartroRoamingQuery, setSmartroRoamingQuery] = useState<any>();
  let check = /^[0-9]+$/;
  const resSt1: ResponseModel<Stipulation> = useFetch({
    recoilSelector: stipulation1Selector,
  });
  const resSt2: ResponseModel<Stipulation> = useFetch({
    recoilSelector: stipulationQR1Selector,
  });
  const resSt3: ResponseModel<Stipulation> = useFetch({
    recoilSelector: stipulationQR2Selector,
  });
  const getChargerData: ResponseModel<Charger> = useFetch({
    recoilSelector: findChargerQrSelector,
    query: getQuery,
  });
  const getChargerDataFromRoaming: ResponseModel<Charger> = useFetch({
    recoilSelector: platformSelector,
    query: getQueryRoaming,
  });
  const getSmartroRoaming: ResponseModel<any> = usePost({
    recoilSelector: smartroRoamingSelector,
    data: getSmartroRoamingQuery,
    recoilState: smartroRoamingState,
  });
  const getSmartro: ResponseModel<any> = usePost({
    recoilSelector: smartroSelector,
    data: getSmartroQuery,
    recoilState: smartroState,
  });

  useEffect(() => {
    if (resSt1?.result === RESULT.FAIL || resSt2?.result === RESULT.FAIL || resSt3?.result === RESULT.FAIL) {
      alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
      navigate("/QRpay");
    } else if (resSt1?.result === RESULT.OK && resSt2?.result === RESULT.OK && resSt3?.result === RESULT.OK) {
      setStipulationDataList([resSt1.data?.cn!, resSt2.data?.cn!, resSt3.data?.cn!]);
    }
  }, [resSt1, resSt2, resSt3]);

  function settingStipulationCheck(index: number) {
    if (index === 0) {
      setStipulationAgree([!stipulationAgree[0], stipulationAgree[1], stipulationAgree[2]]);
    } else if (index === 1) {
      setStipulationAgree([stipulationAgree[0], !stipulationAgree[1], stipulationAgree[2]]);
    } else if (index === 2) {
      setStipulationAgree([stipulationAgree[0], stipulationAgree[1], !stipulationAgree[2]]);
    } else {
      if (stipulationAgree[0] && stipulationAgree[1] && stipulationAgree[2]) {
        setStipulationAgree([false, false, false]);
      } else {
        setStipulationAgree([true, true, true]);
      }
    }
  }
  const settingOpenStipulationCheck = (index: number) => {
    if (index === 0) {
      setOpenStipulation([!isOpenStipulation[0], isOpenStipulation[1], isOpenStipulation[2]]);
    } else if (index === 1) {
      setOpenStipulation([isOpenStipulation[0], !isOpenStipulation[1], isOpenStipulation[2]]);
    } else if (index === 2) {
      setOpenStipulation([isOpenStipulation[0], isOpenStipulation[1], !isOpenStipulation[2]]);
    }
  };

  const onSubmit = (data: FieldValues) => {
    setPhone(data.phone);
    onQrpaySubmit(data.phone);
  };

  const onQrpaySubmit = (phone: string) => {
    if (!stipulationAgree[0] || !stipulationAgree[1] || !stipulationAgree[2]) {
      alert("결제 진행을 위해 약관에 동의가 필요합니다.");
    } else {
      const smartroId = "smartro-script";
      if (document.getElementById(smartroId) == null) {
        const script = document.createElement("script");
        script.src = isMobile
          ? `${process.env.REACT_APP_SMARTRO_MOBILE}${GetSmartroDate(false)}`
          : `${process.env.REACT_APP_SMARTRO_WEB}${GetSmartroDate(false)}`;
        script.id = smartroId;
        script.onload = () => {
          goPay(phone);
        };
        document.head.appendChild(script);
      } else {
        goPay(phone);
      }
    }
  };

  function goPay(phone: string) {
    const mode = "REAL" //STG || REAL;
    //@ts-ignore
    const smartropay = window.smartropay;
    smartropay.init({
      mode: mode,
    });
    let data;
    if (isMobile) {
      data = {
        FormId: "tranMgr", // 폼ID
      };
    } else {
      const path = window.location.pathname.toString().replace(currentLastPath, "").split("/");
      const qrCode = path[path.length - 1];

      data = {
        FormId: "tranMgr", // 폼ID
        Callback: function (res: any) {
          const phoneNumNonDash = phone.replaceAll("-", "");
          //@ts-ignore
          var approvalForm: any = document.approvalForm;
          approvalForm.Tid.value = res.Tid;
          approvalForm.TrAuthKey.value = res.TrAuthKey;
          if (!document.getElementById("qrcode")) {
            let phoneInput = document.createElement("input");
            let qrCodeInput = document.createElement("input");
            phoneInput.setAttribute("type", "hidden");
            qrCodeInput.setAttribute("type", "hidden");
            phoneInput.setAttribute("id", "phone");
            qrCodeInput.setAttribute("id", "qrcode");
            phoneInput.setAttribute("name", "phone");
            qrCodeInput.setAttribute("name", "qrcode");
            approvalForm.appendChild(phoneInput);
            approvalForm.appendChild(qrCodeInput);
            approvalForm.phone.value = phoneNumNonDash;
            approvalForm.qrcode.value = qrCode;
          }
          approvalForm.action = `${process.env.REACT_APP_SMARTRO_RETURN_URL}${qrCode}/${phoneNumNonDash}`;
          approvalForm.submit();
        },
      };
    }
    smartropay.payment(data);
  }

  const onChangeKwhPayHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventKwhPay = event.target.value;
    setKwhPay(eventKwhPay);
    let tmp = Number(eventKwhPay) / 1.1;
    let VatAmt = Math.floor(Number(eventKwhPay) - tmp); // 부가세 절사
    let TaxAmt = Number(eventKwhPay) - VatAmt;
    setVatAmt(VatAmt);
    setTaxAmt(TaxAmt);
  };
  function setSmartroData(data: any, qrCode: string) {
    let check = /^[0-9]+$/;
    setLoading(true);
    if (check.test(qrcode!)) {
      setSmartroQuery(data);
    } else {
      setSmartroRoamingQuery(data);
    }
  }
  //shw
  useEffect(() => {
    const path = window.location.pathname.toString().replace(currentLastPath, "").split("/");
    const qrCode = path[path.length - 1];
    if (check.test(qrCode)) {
      if (getSmartro?.result === RESULT.OK) {
        setLoading(false);
        navigate(`/QRpay/${qrcode}/payment_result`, { replace: true, state: { Payment: true } });
      }
      if (getSmartro?.result === RESULT.FAIL) {
        alert(getSmartro.msg ?? "비회원 충전이 실패하였습니다.");
        setLoading(false);
        navigate(`/QRpay/${qrcode}`, { replace: true });
        return;
      }
    } else {
      if (getSmartroRoaming?.result === RESULT.OK) {
        setLoading(false);
        navigate(`/QRpay/${qrcode}/payment_result`, { replace: true, state: { Payment: true } });
      }
      if (getSmartroRoaming?.result === RESULT.FAIL) {
        alert(getSmartroRoaming.msg ?? "비회원 충전이 실패하였습니다.");
        setLoading(false);
        navigate(`/QRplatformPay/${qrCode}`);
        return;
      }
    }

    if (state && state.Tid && state.TrAuthKey && state.phone) {
      const data = {
        Tid: state.Tid,
        TrAuthKey: state.TrAuthKey,
        phone: state.phone,
        qrcode: qrCode,
      };
      setSmartroData(data, qrCode);
    }
    if (smartro_pay_result) {
      let json_smartro_result = JSON.parse(smartro_pay_result);
      json_smartro_result["TrAuthKey"] = encodeURIComponent(JSON.parse(searchParams.get("result")!)["TrAuthKey"])
        .toString()
        .replaceAll("%3D", "=")
        .replaceAll("%2B", "+")
        .replaceAll("%2F", "/")
        .replaceAll("%20", "+");

      navigate(`/QRpay/${qrCode}/charging_info`, {
        replace: true,
        state: {
          Tid: `${json_smartro_result["Tid"]}`,
          TrAuthKey: `${json_smartro_result["TrAuthKey"]}`,
          phone: `${json_smartro_result["phone"]}`,
        },
      });
    }
  }, [getSmartro, getSmartroRoaming, smartro_pay_result]);

  useEffect(() => {
    if (check.test(qrcode!)) {
      setQuery({
        qrcode: qrcode,
      });
    } else {
      setQueryRoaming({ roamingIdntfr: qrcode });
    }
  }, []);

  useEffect(() => {
    let check = /^[0-9]+$/;
    const path = window.location.pathname.toString().replace(currentLastPath, "").split("/");
    const qrCode = path[path.length - 1];
    if (check.test(qrCode)) {
      setResponse(getChargerData);
    } else {
      setResponse(getChargerDataFromRoaming);
    }
  }, [getChargerData, getChargerDataFromRoaming]);

  useEffect(() => {
    // if (!getChargerQuery) return;
    if (getResponse?.result === RESULT.OK) {
      const chargerData = getResponse.data;
      setChargerQuery(undefined);
      if (
        (chargerData!.sttus === ChargerSttus.AA || chargerData!.sttus === ChargerSttus.AB) &&
        (chargerData!.mode === "00" || chargerData!.mode === "01" || chargerData!.mode === "02")
      ) {
        setCharger(chargerData);
      } else {
        alert("사용이 불가능한 충전기입니다.");
        if (check.test(qrcode!)) {
          navigate("/QRpay");
        } else {
          navigate(`/QRplatform/${qrcode}`);
        }
      }
    }
    if (getResponse?.result === RESULT.FAIL) {
      alert(getResponse.msg);
      if (check.test(qrcode!)) {
        navigate(`/QRpay?qrcode=${qrcode}`);
      } else {
        navigate(`/QRplatformPay/${qrcode}`);
      }
    }
  }, [getResponse]);

  let phoneError: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined = errors.phone ? errors.phone.message : undefined;
  let kwhPayError: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined = errors.kwhPay ? errors.kwhPay.message : undefined;
  return (
    <Div>
      {/* 스마트로 hidden form */}
      <form id="tranMgr" name="tranMgr" method="post">
        <input type="hidden" name="PayMethod" value="CARD" placeholder="" />
        <input type="hidden" name="GoodsCnt" maxLength={2} value="1" placeholder="" />
        <input type="hidden" name="GoodsName" maxLength={40} value="evPlug 비회원 결제" placeholder="" />

        <input type="hidden" name="Amt" maxLength={12} value={kwhPay} placeholder="" />
        <input type="hidden" name="Moid" maxLength={40} value={moid} placeholder="특수문자 포함 불가" />
        <input type="hidden" name="Mid" maxLength={10} value={mid} placeholder="" />
        <input type="hidden" name="BuyerName" maxLength={30} value="" placeholder="" />
        <input type="hidden" name="BuyerTel" maxLength={30} value={BuyerTel} placeholder="" />
        <input type="hidden" name="BuyerEmail" maxLength={30} value="" placeholder="" />
        <input
          type="hidden"
          name="StopUrl"
          size={100}
          className="input"
          value={`${process.env.REACT_APP_SMARTRO_STOP_URL}${qrcode}`}
          placeholder="Mobile 연동 시 필수"
        />

        <input
          type="hidden"
          name="ReturnUrl"
          size={100}
          className="input"
          value={`${process.env.REACT_APP_SMARTRO_RETURN_URL}${qrcode}/${BuyerTel.replaceAll("-", "")}`}
        />
        <input type="hidden" name="MallIp" maxLength={20} value="10.0.0.1" placeholder="" />

        <input type="hidden" name="EncryptData" value={makePayEncryptData(edidate, kwhPay)} placeholder="위/변조방지 HASH 데이터" />
        <input type="hidden" name="GoodsCl" value="0" placeholder="가맹점 설정에 따라 0 또는 1, 핸드폰결제 시 필수" />
        <input type="hidden" name="EdiDate" maxLength={14} value={edidate} placeholder="" />
        <input type="hidden" name="TaxAmt" maxLength={12} value={TaxAmt} placeholder="부가세 직접계산 가맹점 필수,숫자만 가능, 문장부호 제외" />
        <input type="hidden" name="TaxFreeAmt" maxLength={12} value="0" placeholder="부가세 직접계산 가맹점 필수,숫자만 가능, 문장부호 제외" />
        <input type="hidden" name="VatAmt" maxLength={12} value={VatAmt} placeholder="부가세 직접계산 가맹점 필수,숫자만 가능, 문장부호 제외" />
      </form>
      {!isMobile && (
        <form id="approvalForm" name="approvalForm" method="post">
          <input type="hidden" id="Tid" name="Tid" />
          <input type="hidden" id="TrAuthKey" name="TrAuthKey" />
        </form>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Layout image={bgImage}>
          <ContentBox paddingLeft="1.5rem" paddingRight="1.5rem">
            <Font fontSize="1.75rem" fontWeight="600">
              비회원 QR 결제
            </Font>
            <Font className="w-full mt-[2rem]" fontWeight="500">
              휴대폰 번호를 입력해주세요<Span color={noticeRedColor}>*</Span>
            </Font>
            <Input type="text" placeholder="ex>010-1234-5678" {...register!("phone")} name="phone" isError={phoneError ? true : false} />
            <Font color={noticeRedColor} className="w-full" fontSize="calc(0.7rem + 0.2vw)">
              {phoneError?.toString()}
            </Font>
            <Font fontSize="calc(0.6rem + 0.2vw)" color={modalGreyBtnColor} className="w-full my-[5px]" fontWeight="500">
              휴대전화 번호는 본인 확인을 위해 이용됩니다. 정확한 번호를 입력하지 않을 시, 서비스 이용에 불편을 겪을 수 있습니다.
            </Font>
            <Font className="w-full mt-[2rem]" fontWeight="500">
              충전 요금을 선택해 주세요<Span color={noticeRedColor}>*</Span>
            </Font>
            <Div className="flex flex-col w-full">
              {["10,000", "20,000", "30,000"].map((value, index) => (
                <label>
                  <Div className="flex items-center max-[640px]:pb-[0.5rem] mb-[0.5rem]" key={"kwhPay" + value}>
                    <Radio
                      type="radio"
                      value={[10000, 20000, 30000][index]}
                      {...register!("kwhPay")}
                      name="kwhPay"
                      defaultChecked={value === "20,000" ? true : false}
                      onChange={(e) => onChangeKwhPayHandler(e)}
                    />
                    <Blank width="4px" />
                    <Font fontSize="16px" fontWeight="500" className="whitespace-nowrap" changeFontSize="12px">
                      {value}
                    </Font>
                    <Blank width="16px" />
                  </Div>
                </label>
              ))}
              <Font color={noticeRedColor} className="w-full" fontSize="calc(0.7rem + 0.2vw)">
                {kwhPayError?.toString()}
              </Font>
            </Div>
            <Font fontSize="calc(0.6rem + 0.2vw)" color={modalGreyBtnColor} className="w-full my-[5px]" fontWeight="500">
              실제 충전량 만큼만 과금되며, 차액은 환불됩니다.
            </Font>
            <ChargingResultBox>
              <Font fontWeight="500" color={lightBlueColor} fontSize="0.9rem">
                예상 충전량
              </Font>
              <Font fontWeight="500">{(Number.parseFloat(kwhPay) / parseFloat(`${getCharger?.price}`)).toFixed(2)} kWh</Font>
            </ChargingResultBox>
            <Font fontSize="calc(0.6rem + 0.2vw)" color={modalGreyBtnColor} className="w-full my-[5px]" fontWeight="500">
              예상 충전량은 시간대별 적용요금에 따라 변경되며, 실제와 차이가 있을 수 있습니다.
            </Font>
            <label className="w-full">
              <AgreeAllBox className="mt-[0.3rem]">
                <CheckBox
                  type="checkbox"
                  className="me-[8px]"
                  onChange={() => settingStipulationCheck(3)}
                  checked={stipulationAgree[0] && stipulationAgree[1] && stipulationAgree[2]}
                />
                모두 동의합니다
              </AgreeAllBox>
            </label>

            {stipulationHeaderList.map((value, index) => (
              <StipulationBox className="mt-[1rem]" key={"charging_stpList" + index}>
                <StipulationHeaderBox>
                  <Div className="flex">
                    <label className="flex">
                      <CheckBox
                        type="checkbox"
                        className="me-[8px]"
                        onChange={() => settingStipulationCheck(index)}
                        checked={stipulationAgree[index]}
                      />
                      <Font fontSize="1rem" fontWeight="500">
                        {stipulationHeaderList[index]}
                      </Font>
                    </label>
                  </Div>
                  <Img width="30px" src={isOpenStipulation[index] ? expandLess : expandMore} onClick={() => settingOpenStipulationCheck(index)}></Img>
                </StipulationHeaderBox>
                <StipulationContentBox
                  height={isOpenStipulation[index] ? "150px" : "0px"}
                  className="scrollBar"
                  dangerouslySetInnerHTML={{ __html: stipulationDataList[index] }}
                ></StipulationContentBox>
              </StipulationBox>
            ))}
            <Div className="flex justify-between w-[100%] mt-[1.5rem]">
              <PageMoveBtn onClick={() => navigate(-1)} type="button">
                <Arrow arrowImg={arrowPrev}></Arrow>이전
              </PageMoveBtn>
              <PageMoveBtn color={primaryColor} type="submit">
                다음<Arrow arrowImg={arrowNextActive}></Arrow>
              </PageMoveBtn>
            </Div>
          </ContentBox>
        </Layout>
      </Form>
      <Loading isLoading={isLoading} />
    </Div>
  );
}
export default QRpaymentPage;
