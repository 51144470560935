/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { RecoilState, useRecoilState, useRecoilStateLoadable } from "recoil";
import { useLayoutEffect, useState } from "react";
import { loadingState } from "../recoil/atoms";
import _ from "lodash";

function useFetch<T>({
  recoilSelector,
  query,
  setQuery,
}: {
  recoilSelector: RecoilState<T>;
  query?: any;
  setQuery?: React.Dispatch<any>;
}): T | undefined {
  const [getData, setData] = useRecoilStateLoadable<T>(recoilSelector);
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const [_query, _setQuery] = useState<any>();

  useLayoutEffect(() => {
    if (!_.isEqual(_query, query)) {
      // 이전 상태와 변경된 상태가 다를 경우에만 setData 호출
      setLoading(true);
      setData(query);
      _setQuery(query);
    }
  }, [query]);

  useLayoutEffect(() => {
    if (getData.state !== "loading") {
      setLoading(false);
    }
  }, [getData.state]);

  if (getData.state !== "loading") {
    return getData.contents;
  }
}

export default useFetch;
