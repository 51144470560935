import { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";

function onRequest(config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> {
  let accessToken = window.sessionStorage.getItem("accessToken");
  if (config.headers.getAuthorization()) {
    return config;
  }
  if (accessToken) {
    const toto = { token: encodeURIComponent(accessToken!) };

    if (toto) {
      config.headers.Authorization = toto.token;
      config.headers["Content-Type"] = "application/json";
    }
  }
  return config;
}

async function onResponse(response: AxiosResponse): Promise<AxiosResponse> {
  return response;
}

export default function Interceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest);
  axiosInstance.interceptors.response.use(onResponse);
  return axiosInstance;
}
