import styled from "styled-components";
import { fontColor, inlineWidth, loginChangeWidth } from "../../styles/theme";

interface LayoutProps {
  paddingTop?: string;
  bgImg?: string;
}
const LoginLayout = styled.div<LayoutProps>`
  width: 100%;
  height: 82vh;
  background-image: url(${(props) => props.bgImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: ${inlineWidth}) {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  @media only screen and (max-width: ${loginChangeWidth}) {
    background-image: none;
    justify-content: flex-start;
  }
`;

const LoginBox = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  padding: 2rem;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  @media only screen and (max-width: ${loginChangeWidth}) {
    border-width: 0px;
    width: 100%;
    padding: calc(1rem + 1vw);
  }
`;

const FontLink = styled.div`
  font-size: calc(0.7rem + 0.2vw);
  color: #555555;
  cursor: pointer;
  :hover {
    color: ${fontColor};
  }
`;

export { LoginLayout, LoginBox, FontLink };
