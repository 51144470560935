/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForm } from "react-hook-form";
import InputLine from "../../components/input/inputLine";
import { Blank, Div, EvButton, Font, Form, PageLayout } from "../../styles/commonStyle";
import { cardTitleColor, labelBgColor, modalGreyBtnColor, orangeBtnGradient, primaryColor } from "../../styles/theme";
import { yupResolver } from "@hookform/resolvers/yup";
import { updatePasswordValidation } from "../../validation";
import { InfoBox } from "./style";
import { Link, useNavigate } from "react-router-dom";
import { RESULT, ResponseModel } from "../../model/response";
import { useRecoilState } from "recoil";
import { checkKeyDown, getUserIdFromToken, logoutHandler } from "../../util";
import { useEffect, useState } from "react";
import usePost from "../../hooks/usePost";
import EvModal from "../../components/modal";
import { changePasswordPostSelector } from "../../recoil/variableAtom";
import Loading from "../../components/loading/loading";
import { errorState, loadingState, loginState, modalState, modalTypeState, variableState } from "../../recoil/atoms";

function ChangePasswordPage() {
  const [islogin, setlogin] = useRecoilState(loginState);
  const navigate = useNavigate();
  const [body, setBody] = useState<any>();
  const [errMsg, setErrMsg] = useRecoilState(errorState);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const [modalType, setModalType] = useRecoilState(modalTypeState);
  const chagePwdForm = useForm({
    resolver: yupResolver(updatePasswordValidation),
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const response: ResponseModel<any> = usePost({
    recoilSelector: changePasswordPostSelector,
    data: body,
    recoilState: variableState,
  });

  const onSubmit = async (data: any) => {
    if (!window.confirm("비밀번호를 변경하시겠습니까?")) {
      return;
    }
    let userId = getUserIdFromToken();
    let body = {
      id: userId,
      password: data.password,
    };
    setBody(body);
  };

  useEffect(() => {
    if (response?.result === RESULT.OK) {
      alert("정상적으로 완료되었습니다.");
      logoutHandler(setlogin, navigate);
    }
    if (response?.result === RESULT.FAIL) {
      setModalType("inputError");
      setErrMsg(response.msg!);
      setModalOpen(true);
    }
  }, [response]);
  return (
    <Form onSubmit={chagePwdForm.handleSubmit(onSubmit)} className="w-full" onKeyDown={(e) => checkKeyDown(e)}>
      <PageLayout>
        <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px">
          비밀번호 변경
        </Font>
        <Blank height="2rem" />
        <InfoBox>
          <InputLine
            label="비밀번호"
            name="password"
            type="password"
            necessary={true}
            placeholder="특수문자 포함 영문 8~20글자를 입력해주세요"
            register={chagePwdForm.register}
            errors={chagePwdForm.formState.errors.password ? chagePwdForm.formState.errors.password.message : undefined}
            labelColor={labelBgColor}
            setValue={chagePwdForm.setValue}
          />
          <InputLine
            label="비밀번호 확인"
            name="passwordConfirmation"
            type="password"
            necessary={true}
            placeholder="비밀번호를 재입력해주세요"
            register={chagePwdForm.register}
            errors={chagePwdForm.formState.errors.passwordConfirmation ? chagePwdForm.formState.errors.passwordConfirmation.message : undefined}
            labelColor={labelBgColor}
            setValue={chagePwdForm.setValue}
          />
        </InfoBox>
        <Font className="w-full pt-[0.5rem]" fontSize="0.9rem">
          비밀번호 변경시 로그아웃되어 변경된 비밀번호로 다시 로그인하셔야 합니다.
        </Font>
        <Div className="flex w-[100%] justify-center py-[3rem]">
          <EvButton color="#FFF" bgColor={primaryColor} width="7rem" type="submit">
            수정
          </EvButton>
          <Blank width="1rem"></Blank>
          <Link to="/userinfo">
            <EvButton bgColor={modalGreyBtnColor} color="#fff" width="7rem" type="button">
              취소
            </EvButton>
          </Link>
        </Div>
      </PageLayout>
      <EvModal type={modalType} />
      <Loading isLoading={isLoading} />
    </Form>
  );
}
export default ChangePasswordPage;
