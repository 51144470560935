import styled from "styled-components";


interface TooltipProps {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
    width?: string;
    opacity?:number;
  }
const ToolTipLayout = styled.div<TooltipProps>`
position: absolute;
top: ${(props) => props.top};
left: ${(props) => props.left};
right: ${(props) => props.right};
bottom: ${(props) => props.bottom};
width: ${(props) => props.width};
opacity: ${(props) => props.opacity};
z-index: 100;
transition: all 0.5s;
display: flex;
flex-direction: column;

`;

const ToolTipBody = styled.div`
    padding: 8px 12px;
    background-color: black;
    opacity: 0.7;
    display: inline-block;
    color:white;
    font-size: 12px;
    white-space: pre-wrap;
    border-radius: 6px;
`;


interface TooltipArrowProps {
  direction: "up" | 'down'
}
const TooltipArrow = styled.div<TooltipArrowProps>`
margin-left : 4px;

width: 0;
height: 0;
border-bottom: ${(props) => props.direction == "down" ? '6px solid black':"6px solid transparent"};
border-top: ${(props) => props.direction == "up" ? '6px solid black':"6px solid transparent"};
border-left: 6px solid transparent;
border-right: 6px solid transparent;
opacity: 0.7;
`;


export  {  ToolTipLayout,ToolTipBody,TooltipArrow };
