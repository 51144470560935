import styled from "styled-components";
import { footerBackground, footerFontColor } from "../../styles/theme";

const FooterDiv = styled.div`
  width: 100%;
  height: 10vh;
  background: ${footerBackground};
  color: ${footerFontColor};
  font-size: 12px;
  text-align: start;
  align-items: center;
  display: flex;
  font-weight: 600;
  padding: 0 20px 0 20px;
`;
const Content = styled.div`
  width: 100%;
  @media only screen and (max-width: 576px) {
    display: none;
  }
`;
const MobileContent = styled.div`
  width: 100%;
  display: none;
  @media only screen and (max-width: 576px) {
    display: block;
    font-size: 0.6rem;
  }
  @media (min-width: 0px) and (max-width: 365px) {
    display: block;
    zoom: 0.9; /* Old IE only */
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
`;


const TardisLogoBg = styled.img`
`

export { FooterDiv, Content, MobileContent, TardisLogoBg };
