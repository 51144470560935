import { Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "./view/Home";
import NoticeListPage from "./view/notice";
import NoticeDetailPage from "./view/notice/detail";
import InstallReqPage from "./view/installRequest";
import ChargerReqComplete from "./view/installRequest/complete";
import SubsidyPage from "./view/subsidy";
import LoginPage from "./view/login";
import RegisterIndexPage from "./view/register";
import RegisterMemberPage from "./view/register/member";
import RegisterCompanyPage from "./view/register/company";
import RegisterCompletePage from "./view/register/complete";
import UserInfoPage from "./view/userInfo";
import ChangePasswordPage from "./view/userInfo/changePassword";
import ChangePaycardPage from "./view/userInfo/changePaycard";
import WithdrawalPage from "./view/userInfo/withdrawal";
import ChargingHistoryPage from "./view/userInfo/charging";
import QRpayPage from "./view/QR";
import QRDetailPage from "./view/QR/detailQR";
import QRpaymentPage from "./view/QR/payment";
import QRpayResultPage from "./view/QR/payResult";
import QRplatformPage from "./view/QR/platform";
import QRplatformPayPage from "./view/QR/platformPay";
import NotFoundPage from "./view/notFound";
import StipulationPage from "./view/stipulation";
import Middleware from "./middleware/middleware";
import MembershipInfoPage from "./view/membership";
import PointHistoryPage from "./view/userInfo/pointList";
import ChargingPage from "./view/userInfo/chargingPage";
import { loginState } from "./recoil/atoms";
import { useRecoilState } from "recoil";
import PayInfoPage from "./view/payInfo";

function App() {
  const [loginBoolean] = useRecoilState(loginState);
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/policy" element={<StipulationPage />} />
      <Route path="/privacy" element={<StipulationPage />} />
      <Route path="/notice" element={<NoticeListPage />} />
      <Route path="/notice/:id" element={<NoticeDetailPage />} />
      <Route path="/payInfo" element={<PayInfoPage />} />
      <Route path="/installRequest" element={<InstallReqPage />} />
      <Route path="/installRequest/complete" element={<ChargerReqComplete />} />
      <Route path="/subsidy" element={<SubsidyPage />} />
      <Route path="/membership/info" element={<MembershipInfoPage />} />
      <Route
        path="/login"
        element={
          <Middleware>
            <LoginPage />
          </Middleware>
        }
      />

      <Route path="/userInfo" element={<UserInfoPage />} />

      {/* 게이트 페이지 제거 및 법인 회원가입 제거 */}
      {/* <Route path="/register" element={loginBoolean ? <Navigate to="/" /> : <RegisterIndexPage />} /> */}
      <Route path="/register" element={loginBoolean ? <Navigate to="/" /> : <RegisterMemberPage />} />
      {/* <Route path="/register/company" element={loginBoolean ? <Navigate to="/" /> : <RegisterCompanyPage />} /> */}
      <Route path="/register/complete" element={loginBoolean ? <Navigate to="/" /> : <RegisterCompletePage />} />
      <Route path="/password" element={!loginBoolean ? <Navigate to="/" /> : <ChangePasswordPage />} />
      <Route path="/paycard" element={!loginBoolean ? <Navigate to="/" /> : <ChangePaycardPage />} />
      <Route path="/close" element={!loginBoolean ? <Navigate to="/" /> : <WithdrawalPage />} />
      <Route path="/charging/*" element={!loginBoolean ? <Navigate to="/" /> : <ChargingPage />} />
      <Route
        path="/QRpay"
        element={
          <Middleware>
            <QRpayPage />
          </Middleware>
        }
      />
      <Route
        path="/QRpay/:qrcode"
        element={
          <Middleware>
            <QRDetailPage />
          </Middleware>
        }
      />
      <Route
        path="/QRpay/:qrcode/charging_info"
        element={
          <Middleware>
            <QRpaymentPage />
          </Middleware>
        }
      />
      <Route
        path="/QRpay/:qrcode/payment_result"
        element={
          <Middleware>
            <QRpayResultPage />
          </Middleware>
        }
      />
      <Route
        path="/QRplatform/:roamingId"
        element={
          <Middleware>
            <QRplatformPage />
          </Middleware>
        }
      />
      <Route
        path="/QRplatformPay/:roamingId"
        element={
          <Middleware>
            <QRplatformPayPage />
          </Middleware>
        }
      />
      <Route path={"*"} element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
