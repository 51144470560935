import { Blank, Div, Font } from "../../styles/commonStyle";
import { ToolTipBody,ToolTipLayout, TooltipArrow } from "./style";





interface TooltipProps{
  body:any; 
  show:boolean;
  direction?:"up" | "down";
  top?:string | undefined;
  right?:string | undefined;
  bottom?:string | undefined;
  left?:string | undefined
  width?:string | undefined
}

function Tooltip({
  body,
  show,
  direction = "up",
  top = '0',
  right = '0',
  bottom = '0',
  left = '0',
  width
}: TooltipProps) {

  let opa = 0;
  if(show){
    opa = 1;
  }

  return (
    <ToolTipLayout top={top} right={right} left={left} bottom={bottom} width ={width} opacity={opa}>
      {direction == "down" ?
      <TooltipArrow direction={direction} /> : null}
      <ToolTipBody>
        {body}
      </ToolTipBody>
      {direction == "up" ?
      <TooltipArrow direction={direction} /> : null}
    </ToolTipLayout>
  );
}

export default Tooltip;
