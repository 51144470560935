import MoonLoader from "react-spinners/MoonLoader";
import { Div } from "../../styles/commonStyle";

function Loading({ isLoading, bgColor, cssStyle }: { isLoading: boolean; bgColor?: string; cssStyle?: string }) {
  const inlineStyle = bgColor
    ? `w-full h-full fixed top-0 flex justify-center items-center ${bgColor}`
    : "w-full h-full fixed top-0 flex justify-center items-center bg-[#FFF]/60";
  return isLoading ? (
    <Div className={cssStyle ?? inlineStyle}>
      <MoonLoader
        color="#ff6900"
        size={70}
        cssOverride={{
          display: cssStyle ? undefined : "block",
          margin: "0 auto",
          position: cssStyle ? undefined : "fixed",
        }}
      />
    </Div>
  ) : null;
}
export default Loading;
