import styled from "styled-components";
import {
  boxBottomBorderColor,
  fontColor,
  greyColor,
  inlineWidth,
  inputBorderColor,
  mobileChangeWidth,
  navMenuFontColor,
  noticeRedColor,
  primaryColor,
  registerChangeWidth,
} from "./theme";

const FontBold21 = styled.div`
  font-size: 21px;
  color: ${fontColor};
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
  cursor: pointer;
`;

interface SizeProps {
  width?: string;
  height?: string;
  changeWidth?: string;
  changeHeight?: string;
}
const Blank = styled.div<SizeProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  @media only screen and (max-width: ${inlineWidth}) {
    width: ${(props) => props.changeWidth};
    height: ${(props) => props.changeHeight};
  }
`;

interface EvBorderButtonProps {
  color?: string;
  borderColor?: string;
  fontSize?: string;
  hoverPrevent?: boolean;
  width?: string;
}
const EvBorderButton = styled.button<EvBorderButtonProps>`
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  padding: 6px 12px;
  font-weight: 500;
  font-size: ${(props) => props.fontSize ?? "14px"}; //14px;
  border-radius: 1.5rem;
    width: ${(props) => props.width};
  :hover {
    background-color: ${(props) => (props.hoverPrevent ? "#FFF" : props.color)};
    color: ${(props) => (props.hoverPrevent ? props.color : "#FFF")};
  }
`;
interface ImgProps {
  width?: string;
  height?: string;
  changeWidth?: string;
  changeHeight?: string;
  minWidth?: string;
}
const Img = styled.img<ImgProps>`
  min-width: ${(props) => props.minWidth};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  @media only screen and (max-width: ${inlineWidth}) {
    width: ${(props) => props.changeWidth};
    height: ${(props) => props.changeHeight};
  }
`;

const Close = styled.div`
  width: 25px;
  height: 25px;
  ::before {
    position: absolute;
    left: 30px;
    content: " ";
    height: 25px;
    width: 1px;
    background-color: #000;
    transform: rotate(45deg);
  }
  ::after {
    position: absolute;
    left: 30px;
    content: " ";
    height: 25px;
    width: 1px;
    background-color: #000;
    transform: rotate(-45deg);
  }
`;
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: auto;
  margin-top: 8vh;
  min-height: 82vh;
`;
const Wrap = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (max-width: ${inlineWidth}) {
    flex-direction: column;
  }
`;
const WrapMobile = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    flex-direction: column;
  }
`;
const WrapRegister = styled.div`
  display: flex;
  width: 100%;
  margin-top: calc(1rem + 1vw);
  @media only screen and (max-width: ${registerChangeWidth}) {
    flex-direction: column;
  }
`;

interface EvButtonProps {
  color?: string;
  bgColor?: string;
  fontSize?: string;
  changeFontSize?: string;
  padding?: string;
  noRadius?: boolean;
  width?: string;
  fontweight?: string;
  radius?: string;
  lineHeight?: string;
}
const EvButton = styled.button<EvButtonProps>`
  width: ${(props) => props.width};
  color: ${(props) => props.color};
  background: ${(props) => props.bgColor};
  padding: ${(props) => props.padding ?? "0.5rem 1rem"};
  line-height: ${(props) => props.lineHeight ?? "1.5"};
  font-weight: ${(props) => props.fontweight ?? "500"};
  font-size: ${(props) => props.fontSize ?? "1.25rem"};
  border-radius: ${(props) => (props.noRadius === true ? "0rem" : props.radius ?? "1.5rem")}; //1.5rem;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  :hover {
    color: #000;
  }
  @media only screen and (max-width: ${inlineWidth}) {
    font-size: ${(props) => props.changeFontSize ?? "16px"};
  }
`;

interface FontProps {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  changeFontSize?: string;
  lineHeight?: string;
}
const Font = styled.div<FontProps>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight};
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    font-size: ${(props) => props.changeFontSize};
  }
`;

interface SpanProps {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  changeFontSize?: string;
}

const Span = styled.span<SpanProps>`
  color: ${(props) => props.color};
`;

const A = styled.a``;

interface DivProps {
  width?: string;
  height?: string;
  isdisable?: string;
}
const Div = styled.div<DivProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  @media only screen and (max-width: ${mobileChangeWidth}) {
    display: ${(props) => (props.isdisable ? "none" : null)};
  }
`;

interface PageLayoutProps {
  paddingTop?: string;
  bgImg?: string;
  height?: string;
}
const PageLayout = styled.div<PageLayoutProps>`
  width: ${inlineWidth};
  height: ${(props) => props.height};
  background-image: url(${(props) => props.bgImg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: ${(props) => props.paddingTop ?? "4rem"};
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media only screen and (max-width: ${inlineWidth}) {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

interface InputProps {
  isError?: boolean;
  fontSize?: string;
  padding?: string;
  lineHeight?: string;
}
const Input = styled.input<InputProps>`
  border: 1px solid ${(props) => (props.isError ? noticeRedColor : inputBorderColor)};
  width: 100%;
  color: ${navMenuFontColor};
  /* font-size: ${(props) => props.fontSize ?? "1.2rem"}; */
  font-size: ${(props) => props.fontSize ?? "calc(0.7rem + 0.3vw)"};
  font-weight: 400;
  line-height: ${(props) => props.lineHeight ?? "1"}; //1;
  padding: ${(props) => props.padding ?? "0.375rem 0.75rem"}; //0.375rem 0.75rem;
  :focus {
    outline: none;
    border: 1px solid ${primaryColor};
    z-index: 1;
  }
  ::placeholder {
    font-size: calc(0.7rem + 0.3vw);
  }
`;

const Radio = styled.input`
  width: 1.2rem;
  height: 1.2rem;
`;

const CheckBox = styled.input`
  appearance: none;
  border: 1.5px solid gainsboro;
  border-radius: 0.35rem;
  width: 1.5rem;
  height: 1.5rem;
  &:checked {
    border-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: ${primaryColor};
  }
`;

const Form = styled.form``;

interface LineBoxProps {
  noBorder?: boolean;
}
const LineBox = styled.div<LineBoxProps>`
  width: 100%;
  border-bottom: ${(props) => (props.noBorder ? null : `1px solid ${boxBottomBorderColor}`)};
  /* padding: 0.5rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface LineInputLabelProps {
  width?: string;
  bgColor?: string;
}
const LineInputLabel = styled.div<LineInputLabelProps>`
  width: ${(props) => props.width ?? "20%"};
  font-weight: 500;
  vertical-align: middle;
  font-size: calc(0.6rem + 0.3vw); //15px;
  /* padding: 5px; */
  display: flex;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  @media only screen and (max-width: ${mobileChangeWidth}) {
    font-size: 12px;
    min-width: 100px;
    width: 100%;
  }
`;

const LineInputBox = styled.div`
  width: 80%;
  vertical-align: middle;
  display: flex;
  padding: 0.5rem;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    font-size: 12px;
    width: 100%;
  }
`;

const RadioBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    flex-direction: column;
  }
`;
const Hr = styled.hr`
  color: ${greyColor};
  border-color: ${greyColor};
  background-color: ${greyColor};
  opacity: 0.5;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
`;

interface ColumnProps {
  width?: string;
  changeWidth?: string;
  mediaWidth?: string;
}
const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  @media only screen and (max-width: ${(props) => props.mediaWidth ?? mobileChangeWidth}) {
    width: ${(props) => props.changeWidth};
  }
`;
const Row = styled.div`
  display: flex;
`;

interface LabelProps {
  changeFontSize?: string;
}
const Label = styled.label<LabelProps>`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    font-size: ${(props) => props.changeFontSize};
  }
`;

export {
  Label,
  Row,
  Column,
  Hr,
  LineInputLabel,
  LineInputBox,
  RadioBox,
  LineBox,
  Form,
  CheckBox,
  Radio,
  Input,
  FontBold21,
  Blank,
  EvBorderButton,
  Img,
  Close,
  Layout,
  Wrap,
  EvButton,
  Div,
  Font,
  Span,
  A,
  PageLayout,
  WrapMobile,
  WrapRegister,
};
