import styled from "styled-components";
import { cardTitleColor, fontColor, inlineWidth, minimumWidth } from "../../../styles/theme";

interface ImgProps {
  image?: string;
}

interface SectionProps {
  image?: string;
  minHeight?: string;
  bgColor?: string;
  padding?: string;
  changePadding?: string;
}
const MembershipBenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Section = styled.div<SectionProps>`
  background-image: url(${(props) => props.image});
  background: ${(props) => props.bgColor};
  min-height: ${(props) => props.minHeight ?? "500px"};
  height: auto;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: ${(props) => props.padding};
  @media only screen and (max-width: ${inlineWidth}) {
    min-height: ${(props) => props.minHeight ?? "400px"};
    padding: ${(props) => props.changePadding};
  }
`;

const TextBox = styled.div`
  width: ${inlineWidth};
  font-size: 42px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 70px;
  @media only screen and (max-width: ${inlineWidth}) {
    width: 100%;
    font-size: 28px;
    padding-bottom: 20px;
  }
`;
const LeftBox = styled.div<ImgProps>`
  width: 40%;
  border-image-width: 0 1px 0 0;
  border-style: solid;
  border-image-source: url(${(props) => props.image});
  border-image-slice: 1;
  text-align: right;
  padding-right: 30px;
  display: flex;
  justify-content: right;
  @media only screen and (max-width: ${inlineWidth}) {
    width: 174px;
    margin: 0px auto;
    margin-bottom: 20px;
    padding: 0px;
    border-image-width: 0 0 0 0;
  }
`;
const RightBox = styled.div<ImgProps>`
  padding-left: 44px;
  width: 60%;
  display: flex;
  justify-content: left;
  color: #fff;
  flex-direction: column;
  font-size: 22px;
  font-weight: 600;
  line-height: 40px;
  @media only screen and (max-width: ${inlineWidth}) {
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding-left: 0px;
    line-height: 22px;
  }
`;
const InlineLayout = styled.div`
  width: ${inlineWidth};
  margin: 0px auto;
  @media only screen and (max-width: ${inlineWidth}) {
    width: 100%;
  }
`;
const CardBox = styled.div`
  width: 50%;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: ${inlineWidth}) {
    width: 100%;
    padding: 32px 0px;
  }
`;

const CardTitle = styled.div`
  color: ${cardTitleColor};
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
  @media only screen and (max-width: ${inlineWidth}) {
    font-size: 20px;
    line-height: 24px;
    margin-top: 12px;
  }
`;

const CardContent = styled.div`
  color: ${fontColor};
  font-size: 20px;
  margin-top: 18px;
  font-weight: 500;
  text-align: center;
  @media only screen and (max-width: ${inlineWidth}) {
    font-size: 13px;
    line-height: 20px;
    margin-top: 9px;
  }
`;

const TransparencyBox = styled.div`
  width: 445px;
  height: 578px;
  background: hsla(0, 0%, 100%, 0.8);
  margin: 48px 16px;
  padding: 37px;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: ${inlineWidth}) {
    height: 45%;
  }
  @media only screen and (max-width: ${minimumWidth}) {
    width: 100%;
    padding: 40px;
  }
`;

const TransparencyContent = styled.div`
  color: ${fontColor};
  font-size: 20px;
  font-weight: 500;
  margin-top: 18px;
  margin-bottom: 10px !important;
  text-align: center;
  @media only screen and (max-width: ${inlineWidth}) {
    font-size: 13px;
    line-height: 20px;
    margin-top: 9px;
  }
`;

export {
  Section,
  TextBox,
  LeftBox,
  InlineLayout,
  RightBox,
  CardBox,
  CardTitle,
  CardContent,
  TransparencyBox,
  TransparencyContent,
  MembershipBenefitsContainer,
};
