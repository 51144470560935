/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilState } from "recoil";
import { stipulation1Selector, stipulation2Selector, stipulation3Selector } from "../../../recoil/variableAtom";
import { RESULT, ResponseModel } from "../../../model/response";
import { Stipulation } from "../../../model/stipulation";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AgreeAllBox, StipulationBox, StipulationFinalBox, StipulationHeader, StipulationTail, StipulationWrap } from "../style";
import { CheckBox, Font } from "../../../styles/commonStyle";
import useFetch from "../../../hooks/useFetch";
import { loadingState } from "../../../recoil/atoms";

function StipulationList({
  stipulationAgree,
  setStipulationAgree,
}: {
  stipulationAgree: boolean[];
  setStipulationAgree: React.Dispatch<React.SetStateAction<boolean[]>>;
}) {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const resSt1: ResponseModel<Stipulation> = useFetch({
    recoilSelector: stipulation1Selector,
  });
  const resSt2: ResponseModel<Stipulation> = useFetch({
    recoilSelector: stipulation2Selector,
  });
  const resSt3: ResponseModel<Stipulation> = useFetch({
    recoilSelector: stipulation3Selector,
  });

  useEffect(() => {
    if (resSt1?.result === RESULT.FAIL || resSt2?.result === RESULT.FAIL || resSt3?.result === RESULT.FAIL) {
      alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
      navigate("/");
    }
  }, [resSt1, resSt2, resSt3]);

  function settingStipulationCheck(index: number) {
    if (index === 0) {
      setStipulationAgree([!stipulationAgree[0], stipulationAgree[1], stipulationAgree[2]]);
    } else if (index === 1) {
      setStipulationAgree([stipulationAgree[0], !stipulationAgree[1], stipulationAgree[2]]);
    } else if (index === 2) {
      setStipulationAgree([stipulationAgree[0], stipulationAgree[1], !stipulationAgree[2]]);
    } else {
      if (stipulationAgree[0] && stipulationAgree[1] && stipulationAgree[2]) {
        setStipulationAgree([false, false, false]);
      } else {
        setStipulationAgree([true, true, true]);
      }
    }
  }
  return (
    <StipulationWrap>
      <StipulationBox>
        <StipulationHeader>
          <Font>
            evPlug
            <br />
            이용약관
          </Font>
          <label>
            <Font fontSize="16px" fontWeight="400" className="flex items-center">
              <CheckBox type="checkbox" className="me-[8px]" checked={stipulationAgree[0]} onChange={() => settingStipulationCheck(0)} />
              동의합니다
            </Font>
          </label>
        </StipulationHeader>
        {resSt1?.data?.cn ? (
          <StipulationTail className="scrollBar" dangerouslySetInnerHTML={{ __html: resSt1!.data!.cn }}></StipulationTail>
        ) : (
          <StipulationTail className="scrollBar">loading....</StipulationTail>
        )}
      </StipulationBox>
      <StipulationBox>
        <StipulationHeader>
          <Font>
            위치기반
            <br />
            이용약관 서비스
          </Font>
          <label>
            <Font fontSize="16px" fontWeight="400" className="flex items-center">
              <CheckBox type="checkbox" className="me-[8px]" checked={stipulationAgree[1]} onChange={() => settingStipulationCheck(1)} />
              동의합니다
            </Font>
          </label>
        </StipulationHeader>
        {resSt2?.data?.cn ? (
          <StipulationTail className="scrollBar" dangerouslySetInnerHTML={{ __html: resSt2!.data!.cn }}></StipulationTail>
        ) : (
          <StipulationTail className="scrollBar">loading....</StipulationTail>
        )}
      </StipulationBox>
      <StipulationBox>
        <StipulationHeader>
          <Font>
            evPlug
            <br />
            개인정보 수집 및 이용 동의
          </Font>
          <label>
            <Font fontSize="16px" fontWeight="400" className="flex items-center">
              <CheckBox type="checkbox" className="me-[8px]" checked={stipulationAgree[2]} onChange={() => settingStipulationCheck(2)} />
              동의합니다
            </Font>
          </label>
        </StipulationHeader>
        {resSt3?.data?.cn ? (
          <StipulationTail className="scrollBar" dangerouslySetInnerHTML={{ __html: resSt3!.data!.cn }}></StipulationTail>
        ) : (
          <StipulationTail className="scrollBar">loading....</StipulationTail>
        )}
      </StipulationBox>
      <StipulationFinalBox flexDirection="column" justify="space-between" className="pt-[20px] pb-[40px]">
        <label>
          <AgreeAllBox>
            <CheckBox
              type="checkbox"
              className="me-[8px]"
              onChange={() => settingStipulationCheck(3)}
              checked={stipulationAgree[0] && stipulationAgree[1] && stipulationAgree[2]}
            />
            모두 동의합니다
          </AgreeAllBox>
        </label>
      </StipulationFinalBox>
    </StipulationWrap>
  );
}

export default StipulationList;
