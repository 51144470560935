import { Blank, Div, EvButton, Font, Img, PageLayout } from "../../styles/commonStyle";
import { cardTitleColor, modalGreyBtnColor, spanBlueColor } from "../../styles/theme";
import { Tab } from "./style";
import iconJoin from "../../assets/images/icon-join.png";
import { Link } from "react-router-dom";

function RegisterCompletePage() {
  return (
    <PageLayout>
      <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px">
        멤버십 가입
      </Font>
      <Blank height="20px"></Blank>
      <Div className="flex p-[10px]">
        <Tab marginLeft="1px" className="rounded-l-[30px]">
          약관동의
        </Tab>
        <Tab marginLeft="-1px" marginRight="-1px">
          회원정보입력
        </Tab>
        <Tab marginRight="-1px" borderColor={spanBlueColor} zindex={"2"} fontColor={spanBlueColor} className="rounded-r-[30px]">
          가입완료
        </Tab>
      </Div>
      <Div className="flex flex-col justify-center items-center">
        <Blank height="calc(1rem + 2vw)"></Blank>
        <Img src={iconJoin} alt="joinIcon" width="calc(6rem + 2vw)" />
        <Blank height="calc(0.5rem + 0.5vw)"></Blank>
        <Font fontSize="calc(1rem + 1vw)" fontWeight="500" className="text-center">
          evPlug의 멤버가 되신 것을
          <br />
          환영합니다:)
        </Font>
        <Link to="/">
          <EvButton color="#FFF" bgColor={modalGreyBtnColor} className="mt-[2rem]">
            홈으로
          </EvButton>
        </Link>
      </Div>
    </PageLayout>
  );
}

export default RegisterCompletePage;
