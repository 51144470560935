/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilState } from "recoil";
import LandingPage from "../view/Home";
import { loginState } from "../recoil/atoms";

function Middleware({ children }: { children: React.ReactElement }) {
  const [islogin, setlogin] = useRecoilState(loginState);
  if (islogin) {
    return <LandingPage />;
  } else {
    return children;
  }
}

export default Middleware;
