import { Blank, Column, Div, Font, Img, PageLayout, Row } from "../../styles/commonStyle";
import { cardTitleColor } from "../../styles/theme";
import imgType1 from "../../assets/images/img-type2.png";
import { Table, Item } from "./style";

function SubsidyPage() {
  return (
    <PageLayout>
      <Font fontSize="1.75rem" color={cardTitleColor} fontWeight="500" className="mb-[28px]">
        충전기 설치 정부 지원
      </Font>
      <Font fontSize="16px" color={cardTitleColor} fontWeight="500" className="mb-[16px]">
        충전기 설치 신청 고객에게 충전기 설치 비용을 국가보조금으로 지원합니다.
      </Font>
      <Blank height="12px" />
      <Img src={imgType1} alt="imgtype1" className="m-[24px]" />
      <Font fontSize="26px" color={cardTitleColor} fontWeight="500" className="mb-[8px]">
        완속충전기 설치 보조금
      </Font>
      <Div className="px-[10rem] w-full max-[1200px]:px-[0rem]">
        <Font color="#555" fontWeight="400" fontSize="14px">
          (단위:만원)
        </Font>
        <Table>
          <Row>
            <Item className="bg-[#f6f6f6] border-r-[1px] border-[#555]">구분</Item>
            <Item className="bg-[#f6f6f6]">1기</Item>
            <Item className="bg-[#f6f6f6]">2~5기</Item>
            <Item className="bg-[#f6f6f6]">6기이상</Item>
          </Row>
          <Row>
            <Item className="bg-[#f6f6f6] border-r-[1px] border-[#555]">
              완속충전기
              <br />
              (C타입)
            </Item>
            <Item>
              <Font color="#3596ff" fontWeight="600" fontSize="18px">
                200
              </Font>
            </Item>
            <Item className="border-r-[1px] border-l-[1px] border-[#555]">
              <Font color="#3596ff" fontWeight="600" fontSize="18px">
                180
              </Font>
            </Item>
            <Item>
              <Font color="#3596ff" fontWeight="600" fontSize="18px">
                150
              </Font>
            </Item>
          </Row>
          <Row>
            <Item className="bg-[#f6f6f6] border-r-[1px] border-[#555]" width="25%">
              과금형 콘센트
            </Item>
            <Item width="75%">50</Item>
          </Row>
          <Row>
            <Item className="bg-[#f6f6f6] border-r-[1px] border-[#555]" width="25%">
              키오스크 충전기
            </Item>
            <Item width="75%">100</Item>
          </Row>
        </Table>
      </Div>
      <Blank height="2rem" />
      <Font fontSize="18px" fontWeight="400" color="#333">
        동일 장소에 설치하는 충전기 수량에 따라 지원단가 차등 지원함
      </Font>
      <Blank height="3rem" />
      <ul className="list-['※'] p-[10px]">
        <li>충전기 설치 보조금은 지원 가능한 최대 금액을 말하며, 보조금 한도를 초과하여 발생한 충전기 설치 비용은 설치희망자가 부담함.</li>
        <li>키오스크 형식의 완속충전기(C타입)는 기본 2기(보조금 200만원)을 지원하며, 1기 추가 시 추가보조금 100만원을 지원함.</li>
        <li>
          과금형콘센트의 경우 기존 전기배선을 활용한 단순교체가 가능함. 단, 누전 및 화재 등의 안전사고가 발생하지 않도록 충분한 안전조치를 취해야하며
          과금형 콘센트 운영 사업수행기관은 충전정보의 교환, 전력 분배 또는 차단에 대한 조치를 하여야 함.
        </li>
        <li>동일 장소에 설치된 과금형콘센트는 모두 동시에 충전기 가능하여야 하며, 하나의 차단기에 최대 2기의 과금형콘센트만 설치 가능.</li>
      </ul>
      <Blank height="3rem" />
      <Font fontSize="1.75rem" color={cardTitleColor} fontWeight="500" className="mb-[28px]">
        ‘21년도 완속 공용 충전시설 설치수량 산정기준(완속충전기 기준)
      </Font>
      <Div className="px-[4rem] w-full max-[1200px]:px-[0rem]">
        <Table>
          <Row>
            <Item className="bg-[#f6f6f6] border-r-[1px] border-[#555]">구분</Item>
            <Item className="bg-[#f6f6f6]">설치 가능 수량</Item>
          </Row>
          <Row>
            <Column className="w-[50%]">
              <Item className="border-r-[1px] border-[#555]" textAlign="start" justifyContent="start">
                1) 해당 장소에 설치된 충전기가 없는 경우
              </Item>
              <Item className="border-r-[1px] border-[#555]" textAlign="start" justifyContent="start" padding="0rem">
                <Div className="bg-[#f6f6f6] flex items-center px-[1.5rem]">상반기</Div>
                <Font className="p-[1rem]">
                  상시 이용 전기차가 있는 경우에만 지원 가능 주차면수 x 2%
                  <br />
                  (해당 장소의 주차단위구획 수의 2% 이내)
                </Font>
              </Item>
              <Item className="border-r-[1px] border-[#555]" textAlign="start" justifyContent="start" padding="0rem">
                <Div className="bg-[#f6f6f6] flex items-center px-[1.5rem]">상반기</Div>
                <Font className="p-[1rem]">
                  전기차 보유 여부 관계 없이 지원 가능
                  <br />
                  (해당 장소의 주차단위구획 수의 2% 이내)
                </Font>
              </Item>
            </Column>
            <Item width="50%">
              <Font color="#3596ff" fontWeight="600" fontSize="18px">
                주차면수 x 2%
              </Font>
            </Item>
          </Row>
          <Row>
            <Column className="w-[50%]">
              <Item className="border-r-[1px] border-[#555]" textAlign="start" justifyContent="start">
                2) 해당 장소에 이미 설치된 충전기가 있는 경우
              </Item>
              <Item className="border-r-[1px] border-[#555]" textAlign="start" justifyContent="start">
                해당 장소의 주차단위구획 수의 2% 이내 - 기 설치된 충전기 수
              </Item>
            </Column>
            <Item width="50%">
              <Font color="#3596ff" fontWeight="600" fontSize="18px">
                (주차면수 x 2%)
                <br />
                이미 설치된 충전시설 수
              </Font>
            </Item>
          </Row>
        </Table>
      </Div>
      <Blank height="3rem" />
      <ul className="list-decimal font-medium w-full p-[10px]">
        <li>1)에서 소수점 이하는 올림하여 처리한다.</li>
        <li>키오스크 충전기는 계산된 설치가능 수량 2를 곱한 수만큼, 과금형콘센트는 4를 곱한 수만큼 지원 가능하다.</li>
        <li>
          기 설치된 급속충전기, 완속충전기는 1기로 산정(환경부에서 지원받은 급속충전기는 10기로 산정)하고, 키오스크 충전기는 2기당 완속충전기 1기,
          과금형콘센트는 4기당 완속충전기 1기 산정한다.
        </li>
        <li>1)과 2)를 통해 산정된 설치가능 수량은 완속충전기는 최대 5기, 키오스크 충전기는 최대 10기, 과금형콘센트는 최대 20기를 초과할 수 없다.</li>
        <li>
          {" "}
          4.에도 불구하고, 설치 희망자(장소)의 상시이용 전기차(주민등록지 등 증빙이 가능한 경우에 한함) 대수를 2로 나눈 값이 설치가능 수량을 초과하는
          경우 초과분만큼 추가로 지원할 수 있으며 , 키오스크 충전기는 초과분에 2를 곱한수만큰, 과금형콘센트는 4를 곱한수만큼 추가로 지원할 수 있다.
          <br />
          (소수점 이하는 올림하여 지원)
        </li>
      </ul>
      <Blank height="3rem" />
    </PageLayout>
  );
}
export default SubsidyPage;
