export interface UserInfoTabProps {
    nm: String,
    link: String
}

export const user_info_tab: UserInfoTabProps[] = [
    {
        nm: "충전 이력",
        link: "/charging?currentPage=1"
    },
    {
        nm: "포인트 이력",
        link: "/charging/pointlist?currentPage=1"
    }
]