/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FieldValues, useForm } from "react-hook-form";
import { Blank, CheckBox, EvButton, Font, Form, LineBox, LineInputBox, LineInputLabel, PageLayout, Span, WrapMobile } from "../../styles/commonStyle";
import { cardTitleColor, noticeRedColor, orangeBtnGradient, primaryColor } from "../../styles/theme";
import InputLine from "../../components/input/inputLine";
import { reqChargerSchema } from "../../validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { POSTCODE_URL } from "../../constants";
import { RESULT, ResponseModel } from "../../model/response";
import { useNavigate } from "react-router-dom";
import EvModal from "../../components/modal";
import { useRecoilState } from "recoil";
import usePost from "../../hooks/usePost";
import { checkKeyDown } from "../../util";
import Loading from "../../components/loading/loading";
import { errorState, loadingState, modalState, variableState } from "../../recoil/atoms";
import { installRequestSelector } from "../../recoil/variableAtom";

function InstallReqPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(reqChargerSchema),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const openPostCode = useDaumPostcodePopup(POSTCODE_URL);
  const [agree, setAgree] = useState(false);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [getBody, setBody] = useState<any>();
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const [errMsg, setErrMsg] = useRecoilState(errorState);
  const response: ResponseModel<any> = usePost({
    recoilSelector: installRequestSelector,
    data: getBody,
    recoilState: variableState,
  });

  const onClickAgree = (e: any) => {
    setAgree(!agree);
  };

  async function onSubmit(data: FieldValues, event: any) {
    if (!agree) {
      alert("개인정보 취급방침 동의가 필수입니다.");
    } else {
      let body = {
        nm: data.nm,
        cttpc: data.cttpc.replace(/ /gi, "").replace(/-/gi, ""),
        email: data.email,
        rdnmadr: data.rdnmadr,
        adres: data.adres,
        adresDetail: data.adresDetail,
        ty: data.ty,
        placeTy: data.placeTy,
        sideTy: data.sideTy,
        carCnt: data.carCnt,
        parkingCnt: data.parkingCnt,
        slowChrgrCnt: data.slowChrgrCnt,
        fastChrgrCnt: data.fastChrgrCnt,
        cnfirmYn: "N",
      };
      setBody(body);
    }
  }

  useEffect(() => {
    if (response?.result === RESULT.OK) {
      navigate("/installRequest/complete", { replace: true });
    }
    if (response?.result === RESULT.FAIL) {
      setErrMsg(response.msg!);
      setModalOpen(true);
    }
  }, [response]);

  const postCodeHandleComplete = (data: any) => {
    setValue("rdnmadr", data.roadAddress, { shouldValidate: true });
    setValue("adres", data.jibunAddress, { shouldValidate: true });
  };

  const postCodeHandleClick = () => {
    openPostCode({ onComplete: postCodeHandleComplete });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
      <PageLayout>
        <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px">
          충전기 설치 신청
        </Font>
        <LineBox>
          <Font fontSize="1.25rem" fontWeight="500" lineHeight="1.2" changeFontSize="1rem" className="w-full pt-[2rem]">
            설치신청서 작성
          </Font>
          <Blank height="0.5rem"></Blank>
        </LineBox>
        <input hidden={true} />
        <InputLine
          label="신청자"
          name="nm"
          type="text"
          tabIndex={1}
          necessary={true}
          placeholder="이름이나 사업자명을 입력해주세요."
          register={register}
          setValue={setValue}
          errors={errors.nm ? errors.nm.message : undefined}
        />
        <InputLine
          label="연락처"
          name="cttpc"
          type="text"
          tabIndex={2}
          necessary={true}
          placeholder="연락처를 입력해주세요."
          register={register}
          setValue={setValue}
          errors={errors.cttpc ? errors.cttpc.message : undefined}
        />
        <InputLine
          label="이메일"
          name="email"
          type="text"
          tabIndex={3}
          necessary={true}
          placeholder="이메일을 입력해주세요."
          register={register}
          setValue={setValue}
          errors={errors.email ? errors.email.message : undefined}
        />
        <InputLine
          label="개방형태"
          name="ty"
          type="radio"
          tabIndex={4}
          necessary={true}
          selectList={["공용", "비공용", "알수없음"]}
          valueList={["OFFICIAL_USE", "NON_OFFICIAL_USE", "UNKNOWN"]}
          register={register}
          errors={errors.ty ? errors.ty.message : undefined}
        />
        <InputLine
          label="설치장소"
          name="placeTy"
          type="radio"
          tabIndex={5}
          necessary={true}
          selectList={["공동주택", "관공서", "공영 및 관광지 주차장", "업무시설 주차장", "상업시설 주차장", "기타시설"]}
          valueList={["SHARE", "GOVERNMENT", "PUBLIC_SERVICE", "OFFICE", "COMMERCE", "OTHER"]}
          register={register}
          errors={errors.placeTy ? errors.placeTy.message : undefined}
        />
        <InputLine
          label="옥외/옥내"
          name="sideTy"
          type="radio"
          tabIndex={6}
          necessary={true}
          selectList={["옥외", "옥내", "알수없음"]}
          valueList={["OUTSIDE", "INSIDE", "UNKNOWN"]}
          register={register}
          errors={errors.sideTy ? errors.sideTy.message : undefined}
        />
        <InputLine
          label="설치장소 주소"
          name="rdnmadr"
          type="text"
          tabIndex={7}
          necessary={true}
          placeholder="클릭하여 입력해주세요."
          register={register}
          setValue={setValue}
          errors={errors.rdnmadr ? errors.rdnmadr.message : undefined}
          onClick={postCodeHandleClick}
        />
        <InputLine
          label=" "
          type="text"
          name="adresDetail"
          tabIndex={8}
          necessary={false}
          placeholder="상세주소"
          register={register}
          setValue={setValue}
          errors={errors.adresDetail ? errors.adresDetail.message : undefined}
        />
        <InputLine
          label="보유 전기차 수"
          name="carCnt"
          type="number"
          tabIndex={9}
          necessary={true}
          placeholder="숫자를 입력해주세요."
          register={register}
          setValue={setValue}
          errors={errors.carCnt ? errors.carCnt.message : undefined}
        />
        <InputLine
          label="설치 장소 주차면 수"
          name="parkingCnt"
          type="text"
          tabIndex={10}
          necessary={false}
          placeholder="숫자를 입력해주세요."
          register={register}
          setValue={setValue}
          errors={errors.parkingCnt ? errors.parkingCnt.message : undefined}
        />
        <InputLine
          label="완속(7kW) 신청 수량"
          name="slowChrgrCnt"
          type="text"
          tabIndex={11}
          necessary={false}
          placeholder="숫자를 입력해주세요."
          register={register}
          setValue={setValue}
          errors={errors.slowChrgrCnt ? errors.slowChrgrCnt.message : undefined}
        />
        <InputLine
          label="급속(50kW 이상) 신청 수량"
          name="fastChrgrCnt"
          type="text"
          tabIndex={12}
          necessary={false}
          placeholder="숫자를 입력해주세요."
          register={register}
          setValue={setValue}
          errors={errors.fastChrgrCnt ? errors.fastChrgrCnt.message : undefined}
        />
        <LineBox>
          <WrapMobile>
            <LineInputLabel className="p-[0.5rem]">
              개인정보 취급방침
              <Span color={noticeRedColor}>*</Span>
            </LineInputLabel>
            <LineInputBox className="flex flex-col font-medium">
              * 개인정보 취급방침
              <ul>
                개인정보 수집 및 이용에 대한 안내
                <li>수집 주체 : 타디스테크놀로지</li>
                <li>수집 항목 : 신청자, 연락처, 이메일, 설치주소, 개인정보 취급방침</li>
                <li>수집 목적 : 공용충전기 신청 및 결과 회신</li>
                <li>
                  이용 기간 : 원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 단, 관계법령의 규정에 의하여
                  보존할 필요가 있는 경우 아래와 같이 관계법령에서 정한 일정 기간동안 개인정보를 보관할 수 있습니다.
                </li>
              </ul>
              <p className="my-[16px]">- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)</p>
              <p className="mb-[16px]">- 위의 개인정보 소집 및 이용에 동의합니다.(동의 후 문의가 가능합니다.)</p>
              <label className="flex">
                <CheckBox type="checkbox" className="me-[8px]" onClick={onClickAgree} />
                동의합니다.
              </label>
            </LineInputBox>
          </WrapMobile>
        </LineBox>
        <Blank height="12px" />
        <EvButton bgColor={primaryColor} color="#FFF" type="submit">
          설치 신청
        </EvButton>
        <Blank height="36px" />
        <EvModal msg={response?.msg} />
      </PageLayout>
      <EvModal />
      <Loading isLoading={isLoading} />
    </Form>
  );
}
export default InstallReqPage;
