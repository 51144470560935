import styled from "styled-components";
import {
  greyColor,
  greyColor2,
  inputBorderColor,
  loginChangeWidth,
  mobileChangeWidth,
  navMenuFontColor,
  primaryColor,
  registerChangeWidth,
} from "../../styles/theme";

interface LayoutProps {
  image?: string;
}
const Layout = styled.div<LayoutProps>`
  background-image: url(${(props) => props.image});
  background-position-y: -70px;
  width: 100%;
  height: 82vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${loginChangeWidth}) {
    background-image: none;
  }
`;
const RegisterBox = styled.div`
  width: 49rem; //40%;
  height: 70%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  @media only screen and (max-width: ${loginChangeWidth}) {
    width: 100%;
  }
`;

const SelectMemberButton = styled.button`
  background-color: #fff;
  width: 48%;
  border: 1px solid #ddd;
  color: #333;
  font-size: calc(1.6rem + 0.4vw); //30px;
  padding: 35px;
  word-break: keep-all;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    border-color: ${primaryColor};
    color: ${primaryColor};
  }
  @media only screen and (max-width: ${mobileChangeWidth}) {
    flex-direction: column;
    padding: 10px;
  }
`;
const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    padding: 10px;
  }
`;

interface UlProps {
  listPosition?: string;
  fontSize?: string;
}
const Ul = styled.ul<UlProps>`
  color: #555555;
  font-size: 15px;
  font-weight: 500;
  list-style-position: ${(props) => props.listPosition ?? "inside"};
  font-size: ${(props) => props.fontSize};
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    font-size: 13px;
    padding: 10px;
  }
`;
interface LiProps {
  fontColor?: string;
}
const Li = styled.li<LiProps>`
  font-size: calc(0.5rem + 0.4vw);
  color: ${(props) => props.fontColor};
`;
interface TabProps {
  marginLeft?: string;
  marginRight?: string;
  borderColor?: string;
  zindex?: string;
  fontColor?: string;
}
const Tab = styled.div<TabProps>`
  width: calc(4rem + 9vw);
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  z-index: ${(props) => props.zindex};
  border: 1px solid #ddd;
  color: ${(props) => props.fontColor ?? "#555"};
  cursor: default;
  font-size: calc(0.7rem + 0.5vw);
  font-weight: 500;
  line-height: 24px;
  padding: calc(0.5rem + 0.5vw) calc(0.1rem + 1.5vw);
  text-align: center;
  border-color: ${(props) => props.borderColor ?? "#ddd"};
`;

const StipulationWrap = styled.div`
  margin: 80px 0px 0px;
  width: 100%;
  height: 82vh;
`;

interface StiplulationBoxProps {
  flexDirection?: string;
  justify?: string;
}
const StipulationBox = styled.div<StiplulationBoxProps>`
  width: 100%;
  height: 30%;
  border-top: 1px solid ${greyColor};
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justify};
  @media only screen and (max-width: ${mobileChangeWidth}) {
    flex-direction: column;
    height: 30%;
  }
`;

const StipulationHeader = styled.div`
  padding: 12px 12px;
  width: 20%;
  height: 100%;
  font-weight: 500;
  font-size: calc(0.8rem + 0.7vw); //1.5rem;
  word-break: keep-all;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    padding: 5px 5px;
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StipulationTail = styled.div`
  width: 80%;
  padding: 12px 12px;
  overflow: auto;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    width: 100%;
  }
`;

interface PageMoveDivProps {
  color?: string;
}
const PageMoveBtn = styled.button<PageMoveDivProps>`
  font-size: 30px;
  color: ${(props) => props.color ?? greyColor2};
  display: flex;
  align-items: center;
`;

interface ArrowProps {
  arrowImg?: string;
}
const Arrow = styled.div<ArrowProps>`
  width: 30px;
  height: 50px;
  background-image: url(${(props) => props.arrowImg});
  background-size: auto;
  background-repeat: no-repeat;
`;

const StipulationFinalBox = styled.div<StiplulationBoxProps>`
  width: 100%;
  height: 10%;
  display: flex;
  border-top: 1px solid ${greyColor};
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justify};
`;

const ArrowBox = styled.div<StiplulationBoxProps>`
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justify};
`;

const AgreeAllBox = styled.div`
  font-size: 16px;
  color: ${navMenuFontColor};
  font-weight: 500;
  display: flex;
  width: 100%;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    justify-content: end;
  }
`;

interface GreyBorderBoxProps {
  width?: string;
}
const GreyBorderBox = styled.div<GreyBorderBoxProps>`
  border: 1px solid ${inputBorderColor};
  padding: calc(1vw);
  /* margin: 10px; */
  width: ${(props) => props.width ?? "48%"}; //50%;
  border-radius: 10px;
  @media only screen and (max-width: ${registerChangeWidth}) {
    width: 100%;
    margin: 0px;
    margin-bottom: 10px;
  }
`;

export {
  GreyBorderBox,
  AgreeAllBox,
  StipulationFinalBox,
  ArrowBox,
  Arrow,
  PageMoveBtn,
  Layout,
  RegisterBox,
  SelectMemberButton,
  Wrap,
  Ul,
  Li,
  Tab,
  StipulationWrap,
  StipulationBox,
  StipulationHeader,
  StipulationTail,
};
