/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import styled from "styled-components";
import { mobileChangeWidth, payInfoOrangeColor } from "../../../styles/theme";

function PayInfoSectionTitle({ title }: { title: string }) {

  return (
    <PayInfoTitle>{title}</PayInfoTitle>
  );
}
export default PayInfoSectionTitle;

const PayInfoTitle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: start;
  align-items: center;
  margin-bottom: 30px;

  &::before{
    content: "";
    display: inline-block;
    width: 4px;
    height: 26px;
    background-color: ${payInfoOrangeColor};
    margin-right: 10px;
  }

  @media only screen and (max-width: ${mobileChangeWidth}) {
    font-size: 1.25rem;
  }
`;