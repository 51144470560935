import { Link, useLocation } from "react-router-dom";
import { HistoryTabList } from "../style";
import { useEffect, useRef } from "react";
import { UserInfoTabProps } from "./userInfoBase";

function UserInfoTab({ list }: { list: UserInfoTabProps[] }) {
    const elementRef = useRef<HTMLUListElement>(null);
    const top = elementRef?.current?.offsetTop;
    const onClick = () => {

        setTimeout(() => {
            if (top) {
                // window.scrollTo(0, top - 48);
                window.scrollTo(0, 700);
            } else {
                window.scrollTo(0, 0);
            }
        }, 0)
    }
    const location = useLocation();
    const path = location.pathname;
    return (
        <HistoryTabList ref={elementRef}>
            {
                list.map((obj: any, index: number) => {
                    return (
                        <li key={index}>
                            <Link
                                onClick={onClick}
                                to={obj.link}
                                className={obj.link.split('?')[0] === path ? "historyTabButton active" : "historyTabButton"}
                            >
                                {obj.nm}
                            </Link>
                        </li>
                    )
                })
            }
        </HistoryTabList>
    );
}

export default UserInfoTab;