/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useLocation, useNavigate } from "react-router-dom";
import bgImage from "../../assets/images/img-bg-full-apply.jpg";
import { Blank, EvButton, Font, Form, Input, Row, Span } from "../../styles/commonStyle";
import { modalGreyBtnColor, noticeRedColor, orangeBtnGradient, primaryColor } from "../../styles/theme";
import { ContentBox, Layout } from "./style";
import { findChargerQrSelector } from "../../recoil/variableAtom";
import { useEffect, useState } from "react";
import { RESULT, ResponseModel } from "../../model/response";
import useFetch from "../../hooks/useFetch";
import { Charger } from "../../model/charger";
import Loading from "../../components/loading/loading";

function QRpayPage() {
  const location = useLocation();
  let navigate = useNavigate();
  const params = new URLSearchParams(location.search); //url의 파라미터값을 가져온다.
  let [isSearchChager, setIsSearchChager] = useState(true);
  const [getQuery, setQuery] = useState<any>();
  let sid = params.get("sid");
  let cid = params.get("cid");
  let qrcode = params.get("qrcode");
  let cnnctrId = params.get("cnnctr_id");
  const [isLoading, setLoading] = useState(false);
  const response: ResponseModel<Charger> = useFetch({
    recoilSelector: findChargerQrSelector,
    query: getQuery,
  });

  useEffect(() => {
    if (sid && cid && cnnctrId) {
      setQuery({
        sid: sid,
        cid: cid,
        cnnctrId: cnnctrId,
      });
    } else if (sid && cid) {
      setQuery({
        sid: sid,
        cid: cid,
      });
    } else if (qrcode) {
      setQuery({
        qrcode: qrcode,
      });
    }
  }, []);

  useEffect(() => {
    if (!getQuery) {
      return;
    }
    if (response?.result === RESULT.OK) {
      setIsSearchChager(true);
      if (response.data!.qrcode) {
        navigate(response.data!.qrcode);
      } else {
        alert("비회원 현장결제를 지원하지 않는 충전기입니다.");
      }
    }
    if (response?.result === RESULT.FAIL) {
      setIsSearchChager(false);
    }
  }, [response]);

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let qrcode = event.currentTarget.qrcode.value;
    setQuery({
      qrcode: qrcode,
    });
  };
  return (
    <Form onSubmit={(e) => onSubmitHandler(e)}>
      {!isLoading ? (
        <Layout image={bgImage}>
          <ContentBox>
            <Font fontSize="1.75rem" fontWeight="600">
              비회원 QR 결제
            </Font>
            <Blank height="3rem" changeHeight="calc(1rem + 2vw)"></Blank>
            <Row className="w-full">
              <Input placeholder="충전기에 부착된 QR번호를 입력해주세요" name="qrcode"></Input>
              <EvButton bgColor={modalGreyBtnColor} color="#fff" noRadius={true} width="7rem">
                검색
              </EvButton>
            </Row>
            {!isSearchChager && (
              <Font color={noticeRedColor} fontSize="0.9rem" className="w-full" fontWeight="500">
                유효하지 않은 큐알코드/충전기 입니다.
              </Font>
            )}
            <Font fontSize="calc(0.6rem + 0.2vw)" color={modalGreyBtnColor} className="w-full" fontWeight="500">
              큐알코드 번호는 충전기에서 확인할 수 있습니다.
            </Font>
            <Font className="py-[1rem]" fontWeight="500" fontSize="calc(0.8rem + 0.2vw)">
              <Span color={primaryColor}>evPlug 회원</Span>으로 가입하세요.
            </Font>
            <Font fontWeight="500" fontSize="calc(0.8rem + 0.2vw)">
              훨씬 <Span color={primaryColor}>저렴한 요금</Span>으로 이용 하실 수 있습니다.
            </Font>
            <Link to="/register">
              <EvButton bgColor={primaryColor} color="#fff" width="7rem" className="mt-[0.8rem]" radius="6px" type="submit">
                회원가입
              </EvButton>
            </Link>
          </ContentBox>
        </Layout>
      ) : (
        <Layout image={bgImage}>
          <ContentBox>
            <Font fontSize="1.75rem" fontWeight="600">
              비회원 QR 결제
            </Font>
            <Loading isLoading={true} cssStyle="w-full min-h-[250px] flex bg-[#FFF]/0 items-center" />
          </ContentBox>
        </Layout>
      )}
    </Form>
  );
}

export default QRpayPage;
