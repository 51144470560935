export interface Charger {
  mode?: string;
  ormpm?: string;
  plugTy?: PlugCategoryType;
  price?: string;
  qrcode?: string;
  adres?: string;
  rdnmadr?: string;
  stationIdntfr?: string;
  nm?: string;
  sttus?: ChargerSttusType;
}

export const ChargerSttus = {
  AA: "AA",
  AB: "AB",
  AC: "AC",
  AD: "AD",
  AL: "AL",
  AE: "AE",
  AF: "AF",
  AG: "AG",
  AH: "AH",
  AI: "AI",
  AJ: "AJ",
  AK: "Ak",
};
type ChargerSttusType = keyof typeof ChargerSttus;

export const PlugCategory = {
  AA: "플러그없음",
  AB: "AC완속",
  AC: "DC콤보X1",
  AD: "DC차데모",
  AE: "AC3",
  AF: "DC콤보+DC차데모",
  AG: "DC콤보+AC3",
  AH: "DC차데모+AC3",
  AI: "DC콤보+DC차데모+AC3",
  AJ: "무선 충전",
  AK: "DC급속(버스)",
  AL: "AC급속(버스)",
  AM: "급속(이륜차, e-mobility)",
  AN: "완속(이륜차, e-mobility)",
  AO: "콘센트(콘센트)",
  AP: "DC콤보X2",
};
type PlugCategoryType = keyof typeof PlugCategory;
