import Modal from "react-modal";
import ReactModal from "react-modal";
import { useRecoilState, useResetRecoilState } from "recoil";
import ErrorModal from "./error";
import FindIdModal from "./findId";
import FindPasswordModal from "./findPassword";
import { creditCardCustomStyles, customStyles, loginCustomStyles } from "../../styles/theme";
import FindPhoneModal from "./findPhone";
import FindCreditCardModal from "./findCreditCard";
import InputErrorModal from "./inputError";
import React from "react";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import { errorState, modalState } from "../../recoil/atoms";

Modal.setAppElement("#root");

function EvModal({ msg, type, setValue }: { msg?: string; type?: string; setValue?: UseFormSetValue<FieldValues> }) {
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const resetError = useResetRecoilState(errorState);
  function closeModal() {
    resetError();
    setIsOpen(false);
  }
  ReactModal.defaultStyles.overlay!.backgroundColor = "rgba( 0, 0, 0, 0.5)";

  if (type === "id") {
    return (
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={loginCustomStyles} contentLabel="EvPlug id Modal" shouldCloseOnOverlayClick={true}>
        <FindIdModal />
      </Modal>
    );
  } else if (type === "password") {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={loginCustomStyles}
        contentLabel="EvPlug password Modal"
        shouldCloseOnOverlayClick={true}
      >
        <FindPasswordModal />
      </Modal>
    );
  } else if (type === "phone") {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={loginCustomStyles}
        contentLabel="EvPlug password Modal"
        shouldCloseOnOverlayClick={true}
      >
        <FindPhoneModal setValue={setValue} />
      </Modal>
    );
  } else if (type === "creditCard") {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={creditCardCustomStyles}
        contentLabel="EvPlug creditCard Modal"
        shouldCloseOnOverlayClick={true}
      >
        <FindCreditCardModal setValue={setValue!} />
      </Modal>
    );
  } else if (type === "inputError") {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="EvPlug InputError Modal"
        shouldCloseOnOverlayClick={false}
      >
        <InputErrorModal msg={msg} closeModal={closeModal} />
      </Modal>
    );
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles} contentLabel="EvPlug Error Modal" shouldCloseOnOverlayClick={false}>
      <ErrorModal msg={msg} closeModal={closeModal} />
    </Modal>
  );
}

export default React.memo(EvModal);
