/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoTop from "../../assets/images/logo-top.png";
import hambug from "../../assets/images/ico-hambug.png";
import {
  ButtonBox,
  ContentDiv,
  FontBox,
  HeaderDiv,
  MobileContentDiv,
  NavMenu,
  NavModalDiv,
  NavModalContent,
  NavModalHeader,
  NavModalMenuBox,
  HeaderBox,
  MenuDropdown,
  Triangle,
  TriangleNoHover,
  MenuOption,
} from "./style";
import { Blank, Close, Div, EvBorderButton, FontBold21, Img, Row } from "../../styles/commonStyle";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { primaryColor, searchColor } from "../../styles/theme";
import triangle from "../../assets/images/triangle.svg";
import triangleWhite from "../../assets/images/triangleWhite.svg";
import triangleGrey from "../../assets/images/triangleGrey.svg";
import { logoutHandler, navLogoutHandler, useOutsideAlerter } from "../../util";
import { debounce } from "lodash";
import { loginState } from "../../recoil/atoms";

function Header() {
  const [navBarShow, setNavBarShow] = useState(false);
  const [islogin, setlogin] = useRecoilState(loginState);
  const [mypageState, setMypageState] = useState(false);
  const navigate = useNavigate();
  let sessionStorage = window.sessionStorage;

  const location = useLocation();


  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setMypageState);

  const onClickNavBar = () => {
    setNavBarShow(!navBarShow);
    if (!navBarShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  useEffect(() => {
    setlogin(sessionStorage.getItem("accessToken") ? true : false);
  }, []);
  return (
    <HeaderBox>
      <HeaderDiv className="shadow-md">
        <ContentDiv>
          <Link to="/">
            <Img src={logoTop} alt="evPlug_logo_top" />
          </Link>
          <FontBox>
            <FontBold21 onClick={() => (window.location.href = "/notice?currentPage=1")}>공지사항</FontBold21>
            <Blank width="40px" />
            <FontBold21 onClick={() => (window.location.href = "/payInfo")}>요금 안내</FontBold21>
            <Blank width="40px" />
            <FontBold21 onClick={() => (window.location.href = "/installRequest")}>충전기 설치신청</FontBold21>
          </FontBox>
          {!islogin ? (
            <ButtonBox>
              <Link to="/membership/info">
                <EvBorderButton color={location.pathname == "/membership/info" ? primaryColor : "#808184"} borderColor={location.pathname == "/membership/info" ? primaryColor : "#bbbdbf"} >
                  멤버십
                </EvBorderButton>
              </Link>
              <Blank width="10px" />
              <Link to="/register">
                <EvBorderButton color={location.pathname == "/register" ? primaryColor : "#808184"} borderColor={location.pathname == "/register" ? primaryColor : "#bbbdbf"} >
                  회원가입
                </EvBorderButton>
              </Link>
              <Blank width="10px" />
              <Link to="/login">
                <EvBorderButton color={location.pathname == "/login" ? primaryColor : "#808184"} borderColor={location.pathname == "/login" ? primaryColor : "#bbbdbf"} >
                  로그인
                </EvBorderButton>
              </Link>
            </ButtonBox>
          ) : (

            <ButtonBox>
              <Link to="/membership/info">
                <EvBorderButton color={location.pathname == "/membership/info" ? primaryColor : "#808184"} borderColor={location.pathname == "/membership/info" ? primaryColor : "#bbbdbf"} >
                  멤버십
                </EvBorderButton>
              </Link>
              <Blank width="10px" />
              <EvBorderButton
                color={location.pathname.includes('charging') || location.pathname == "/userInfo" ? primaryColor : "#808184"} borderColor={location.pathname.includes('charging') || location.pathname == "/userInfo" ? primaryColor : "#bbbdbf"}
                className="flex relative"
                ref={wrapperRef}
                onClick={() => setMypageState(!mypageState)}
                hoverPrevent={mypageState}
              >
                마이페이지
                {mypageState ? (
                  <TriangleNoHover bgImage={location.pathname.includes('charging') || location.pathname == "/userInfo" ? triangle : triangleGrey}></TriangleNoHover>
                ) : (
                  <Triangle bgImage={location.pathname.includes('charging') || location.pathname == "/userInfo" ? triangle : triangleGrey} changeBgImage={triangleWhite}></Triangle>
                )}
                {mypageState ? (
                  <MenuDropdown>
                    <Link to="/userInfo">
                      <MenuOption className="w-full p-[3px]">회원정보</MenuOption>
                    </Link>
                    <Link to="/charging">
                      <MenuOption className="w-full p-[3px]">내 멤버십/충전이력</MenuOption>
                    </Link>
                  </MenuDropdown>
                ) : null}
              </EvBorderButton>
              <Blank width="10px" />
              <EvBorderButton color="#808184" borderColor="#bbbdbf" onClick={() => logoutHandler(setlogin, navigate)}>
                로그아웃
              </EvBorderButton>
            </ButtonBox>
          )}
        </ContentDiv>
        <MobileContentDiv>
          <Img src={hambug} alt="hambug_menu" onClick={onClickNavBar} />
          <Link to="/">
            <Img src={logoTop} alt="evPlug_logo_top" height="20px" />
          </Link>
          <Blank width="38px" />
        </MobileContentDiv>

        <NavModal isShow={navBarShow} clickHandler={onClickNavBar} setNavBarShow={setNavBarShow} />
      </HeaderDiv>
    </HeaderBox>
  );
}
interface NavModalProps {
  isShow: boolean;
  clickHandler: any;
  setNavBarShow?: any;
}
function NavModal(props: NavModalProps) {
  const ref = useRef<any>(null);
  let sessionStorage = window.sessionStorage;
  const [islogin, setlogin] = useRecoilState(loginState);
  useOutsideAlerter(ref, props.setNavBarShow);
  let accessToken: string | null = sessionStorage.getItem("accessToken");

  const handleResize = debounce(() => {
    if (window.innerWidth > 620) {
      props.setNavBarShow(false);
    }
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (props.isShow === false) {
    return <></>;
  }
  return (
    <NavModalDiv>
      <NavModalContent>
        <Div className="w-full h-full" ref={ref}>
          <NavModalHeader>
            <Row className="w-full justify-between">
              <Close onClick={props.clickHandler} />
              {accessToken === null ? (
                <Link to="/login">
                  <EvBorderButton borderColor={searchColor} color={searchColor} fontSize="0.8rem" onClick={props.clickHandler}>
                    로그인
                  </EvBorderButton>
                </Link>
              ) : (
                <Link to="/">
                  <EvBorderButton
                    borderColor={searchColor}
                    color={searchColor}
                    fontSize="0.8rem"
                    onClick={() => navLogoutHandler(setlogin, props.clickHandler)}
                  >
                    로그아웃
                  </EvBorderButton>
                </Link>
              )}
            </Row>
          </NavModalHeader>
          <NavModalMenuBox>
            <Link to="/" onClick={props.clickHandler}>
              <NavMenu>Home</NavMenu>
            </Link>
            <Link to="/installRequest" onClick={props.clickHandler}>
              <NavMenu>충전기 설치신청</NavMenu>
            </Link>
            <Link to="/notice?currentPage=1" onClick={props.clickHandler}>
              <NavMenu>공지사항</NavMenu>
            </Link>
            <Link to="/payInfo" onClick={props.clickHandler}>
              <NavMenu>요금 안내</NavMenu>
            </Link>
            <Link to="/membership/info" onClick={props.clickHandler}>
              <NavMenu>멤버십</NavMenu>
            </Link>
            {accessToken === null ? (
              <Link to="/register" onClick={props.clickHandler}>
                <NavMenu>멤버십 가입</NavMenu>
              </Link>
            ) : (
              <Div className="w-full">
                <Link to="/userInfo" onClick={props.clickHandler}>
                  <NavMenu>회원정보</NavMenu>
                </Link>
                <Link to="/charging" onClick={props.clickHandler}>
                  <NavMenu>내 멤버십/충전이력</NavMenu>
                </Link>
              </Div>
            )}
          </NavModalMenuBox>
        </Div>
      </NavModalContent>
    </NavModalDiv>
  );
}
export default Header;
