import iconError from "../../assets/images/icon-error.png";
import { Font, Img, PageLayout } from "../../styles/commonStyle";

function NotFoundPage() {
  return (
    <PageLayout>
      <Img src={iconError} alt="icon_error" className="mt-5" />
      <Font className="my-4" fontSize="calc(1.2rem + 0.8vw)" fontWeight="500">
        페이지를 찾을 수 없습니다.
      </Font>
    </PageLayout>
  );
}
export default NotFoundPage;
