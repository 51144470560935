/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Blank, Div, EvButton, Font } from "../../styles/commonStyle";
import { ColorCircle, ContentBox, Layout } from "./style";
import bgImage from "../../assets/images/img-bg-full-apply.jpg";
import { greenColor, modalGreyBtnColor, noticeRedColor, orangeBtnGradient, orangeColor, primaryColor } from "../../styles/theme";
import { useEffect, useState } from "react";
import { Charger, ChargerSttus, PlugCategory } from "../../model/charger";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { platformSelector } from "../../recoil/variableAtom";
import { RESULT, ResponseModel } from "../../model/response";
import useFetch from "../../hooks/useFetch";
import { variableState } from "../../recoil/atoms";

function QRplatformPayPage() {
  let navigate = useNavigate();
  const [getChargerQuery, setChargerQuery] = useRecoilState(variableState);
  const { roamingId } = useParams();
  const [getCharger, setCharger] = useState<Charger>();
  const [isPaymentDisabled, setIsPaymentDisabled] = useState(true);
  const [getQuery, setQuery] = useState<any>();
  const response: ResponseModel<Charger> = useFetch({
    recoilSelector: platformSelector,
    query: getQuery,
  });

  const ing_div = (
    <Div className="w-full flex items-center">
      <ColorCircle color={orangeColor} />
      <Blank width="0.5rem"></Blank>
      <Font fontWeight="500" fontSize="1rem">
        사용중
      </Font>
    </Div>
  );
  const ready_div = (
    <Div className="w-full flex items-center">
      <ColorCircle color={greenColor} />
      <Blank width="0.5rem"></Blank>
      <Font fontWeight="500" fontSize="1rem">
        사용가능
      </Font>
    </Div>
  );
  const no_div = (
    <Div className="w-full flex items-center">
      <Font color={noticeRedColor} fontWeight="500" fontSize="1rem">
        현재 사용할 수 없는 충전기입니다.
      </Font>
    </Div>
  );

  const selectSttus = {
    AA: ready_div,
    AB: ready_div,
    AC: ing_div,
    AD: ing_div,
    AL: ing_div,
    AE: ing_div,
    AF: ing_div,
    AG: no_div,
    AH: no_div,
    AI: no_div,
    AJ: no_div,
    AK: no_div,
  };

  useEffect(() => {
    setQuery({
      roamingIdntfr: roamingId,
    });
  }, []);

  useEffect(() => {
    if (response?.result === RESULT.OK) {
      setChargerQuery(undefined);
      setCharger(response.data);
    }
    if (response?.result === RESULT.FAIL) {
      alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
      navigate("/QRpay");
    }
  }, [response]);

  useEffect(() => {
    if (getCharger?.mode === "00" || getCharger?.mode === "01" || getCharger?.mode === "02") {
      if (getCharger.sttus === ChargerSttus.AA || getCharger.sttus === ChargerSttus.AB) {
        setIsPaymentDisabled(false);
      } else {
        setIsPaymentDisabled(true);
      }
    } else {
      setIsPaymentDisabled(true);
    }
  }, [getCharger]);

  const onClickPayHandler = () => {
    if (window.confirm("충전기 정보를 충분히 확인하였습니까?")) {
      navigate(`/QRpay/${roamingId}/charging_info`);
    }
  };
  return (
    <Layout image={bgImage}>
      <ContentBox paddingLeft="1.5rem" paddingRight="1.5rem">
        <Font fontSize="1.75rem" fontWeight="600">
          비회원 QR플랫폼 결제
        </Font>
        <Blank height="1rem" changeHeight="calc(0.5rem + 0.5vw)"></Blank>
        <Font fontSize="calc(0.6rem + 0.2vw)" color={modalGreyBtnColor} fontWeight="500">
          * 충전소 정보를 확인해 주세요
        </Font>
        <EvButton
          bgColor={primaryColor}
          color="#fff"
          width="100%"
          className="my-[0.8rem]"
          disabled={isPaymentDisabled}
          onClick={onClickPayHandler}
        >
          결제하기
        </EvButton>
        {getCharger?.mode === "00" || getCharger?.mode === "01" || getCharger?.mode === "02" ? selectSttus[getCharger.sttus ?? "AK"] : no_div}

        <Blank height="10px" />
        <Div className="w-full mb-[0.2rem] flex">
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)" className="w-[30%]">
            큐알코드 번호
          </Font>
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)">
            {getCharger?.qrcode}
          </Font>
        </Div>
        <Div className="w-full mb-[0.2rem] flex">
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)" className="w-[30%]">
            충전소명
          </Font>
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)">
            {getCharger?.nm}
          </Font>
        </Div>
        <Div className="w-full mb-[0.2rem] flex">
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)" className="w-[30%]">
            충전소 주소
          </Font>
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)">
            {getCharger?.rdnmadr ? getCharger?.rdnmadr : getCharger?.adres ?? "-"}
          </Font>
        </Div>
        <Div className="w-full mb-[0.2rem] flex">
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)" className="w-[30%]">
            충전기 종류
          </Font>
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)">
            {PlugCategory[getCharger?.plugTy!]}
          </Font>
        </Div>
        <Div className="w-full mb-[0.2rem] flex">
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)" className="w-[30%]">
            이용가능 시간
          </Font>
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)">
            {getCharger?.ormpm}
          </Font>
        </Div>
        <Div className="w-full mb-[0.2rem] flex">
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)" className="w-[30%]">
            서비스 기관
          </Font>
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)">
            evPlug
          </Font>
        </Div>
        <Div className="w-full mb-[0.2rem] flex">
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)" className="w-[30%]">
            요금
          </Font>
          <Font fontWeight="500" fontSize="calc(0.7rem + 0.3vw)">
            {getCharger?.price}원/kWh
          </Font>
        </Div>
      </ContentBox>
    </Layout>
  );
}
export default QRplatformPayPage;
