export enum RESULT {
  OK = "OK",
  FAIL = "FAIL",
  LOADING = "LOADING",
}

export type ResponseModel<T> = {
  result?: RESULT;
  msg?: string;
  data?: T;
};
