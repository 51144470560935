/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { RecoilState, useRecoilState, useRecoilStateLoadable, useResetRecoilState } from "recoil";
import { useLayoutEffect, useState } from "react";
import _ from "lodash";
import { loadingState, pageState, searchState } from "../recoil/atoms";

function useFetchList({
  recoilState,
  currentPage,
  search,
  query,
}: {
  
  recoilState: RecoilState<any>;
  currentPage?: number;
  search?: any;
  query?: any;
}) {
  const [getData, setDataQuery] = useRecoilStateLoadable(recoilState);
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const reset = useResetRecoilState(recoilState);
  const resetPage = useResetRecoilState(pageState);
  const resetSearch = useResetRecoilState(searchState);
  const [isReset, setReset] = useState(false);

  useLayoutEffect(() => {
    reset();
    resetPage();
    resetSearch();
    setReset(true);
  }, []);

  useLayoutEffect(() => {
    setLoading(true);
    if (query !== null || query !== undefined) {
      setDataQuery(query);
    }
  }, [currentPage, search]);

  useLayoutEffect(() => {
    if (isReset === false) return;
    if (getData.state !== "loading") {
      setLoading(false);
    }
  }, [getData.state]);

  if (getData.state !== "loading" && isReset === true) {
    return getData.contents;
  }
}

export default useFetchList;
