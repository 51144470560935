/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useNavigate } from "react-router-dom";
import { Div, PageLayout } from "../../styles/commonStyle";
import useFetch from "../../hooks/useFetch";
import { Stipulation } from "../../model/stipulation";
import { RESULT, ResponseModel } from "../../model/response";
import { stipulationPolicySelector, stipulationPrivacySelector } from "../../recoil/variableAtom";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { MoonLoader } from "react-spinners";
import { loadingState } from "../../recoil/atoms";

function StipulationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const ty = location.pathname.replace("/", "");
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const response: ResponseModel<Stipulation> = useFetch({
    recoilSelector: ty === "policy" ? stipulationPolicySelector : stipulationPrivacySelector,
  });

  useEffect(() => {
    if (response?.result === RESULT.FAIL) {
      alert("[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오.");
      navigate("/");
    }
  }, [response]);
  return (
    <PageLayout>
      <Div className="scrollBar p-[20px]" dangerouslySetInnerHTML={{ __html: response?.data!.cn }}></Div>
      {isLoading ? (
        <Div className="w-full h-full fixed top-0 flex justify-center items-center">
          <MoonLoader
            color="#ff6900"
            size={70}
            cssOverride={{
              display: "block",
              margin: "0 auto",
              position: "fixed",
            }}
          />
        </Div>
      ) : null}
    </PageLayout>
  );
}
export default StipulationPage;
