/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRecoilState } from "recoil";
import { RESULT, ResponseModel } from "../../model/response";
import { EvButton, Font, Form } from "../../styles/commonStyle";
import { orangeBtnGradient, primaryColor, searchColor } from "../../styles/theme";
import { ModalBorderContent, ModalBox, ModalContent } from "./style";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailSchema } from "../../validation";
import InputLine from "../input/inputLine";
import { emailSelector } from "../../recoil/variableAtom";
import useFetch from "../../hooks/useFetch";
import { checkKeyDown } from "../../util";
import { errorState, modalState, modalTypeState } from "../../recoil/atoms";

function FindIdModal() {
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const [isComplete, setComplete] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [getBody, setBody] = useState<any>();
  const [modalType, setModalType] = useRecoilState(modalTypeState);
  const [errMsg, setErrMsg] = useRecoilState(errorState);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const response: ResponseModel<boolean> | undefined = useFetch<ResponseModel<boolean>>({
    recoilSelector: emailSelector,
    query: getBody,
  });

  useEffect(() => {
    if (!isSubmit) {
      return;
    }
    if (response?.result === RESULT.OK) {
      setComplete(true);
    }
    if (response?.result === RESULT.FAIL) {
      setModalType("inputError");
      setErrMsg(response.msg!);
      setModalOpen(true);
    }
  }, [response]);

  async function OnSubmit(data: FieldValues) {
    let body = {
      email: data.email,
    };
    setBody(body);
    setSubmit(true);
  }
  if (isComplete) {
    return (
      <ModalBox width="100%">
        <Font fontSize="17px" fontWeight="500" className="p-[16px]">
          아이디찾기
        </Font>
        <ModalBorderContent className="justify-center">가입하신 이메일로 아이디를 보내드렸습니다.</ModalBorderContent>
        <ModalContent>
          <EvButton
            fontSize="1.2rem"
            color="#FFF"
            bgColor={searchColor}
            padding="8px 28px"
            onClick={() => {
              setIsOpen(false);
              setComplete(false);
            }}
          >
            확인
          </EvButton>
        </ModalContent>
      </ModalBox>
    );
  }
  return (
    <ModalBox width="450px">
      <Form onSubmit={handleSubmit(OnSubmit)} onKeyDown={checkKeyDown}>
        <Font fontSize="17px" fontWeight="500" className="p-[16px]">
          아이디찾기
        </Font>
        <ModalBorderContent>
          <InputLine
            label="이메일"
            name="email"
            type="text"
            necessary={false}
            placeholder="가입시 등록하신 이메일을 입력해주세요"
            register={register}
            errors={errors.email ? errors.email.message : undefined}
            noBorder={true}
            setValue={setValue}
          />
        </ModalBorderContent>
        <ModalContent>
          <EvButton fontSize="1.2rem" color="#FFF" bgColor={primaryColor} padding="8px 28px" type="submit">
            찾기
          </EvButton>
        </ModalContent>
      </Form>
    </ModalBox>
  );
}
export default FindIdModal;
