/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilState } from "recoil";
import { Blank, Div, EvButton, Font, Form, Input, Wrap } from "../../styles/commonStyle";
import { modalGreyBtnColor, modalGreyBtnOpacityColor, noticeRedColor, orangeBtnGradient, primaryColor } from "../../styles/theme";
import { ModalBorderContent, ModalBox, ModalContent } from "./style";
import { useEffect, useState } from "react";
import { certifyAuthCodeValidation, findPasswordValidation } from "../../validation";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Timer from "../timer/timer";
import { makeHyphenInput } from "../../util";
import usePost from "../../hooks/usePost";
import { certifyPhoneSelector, checkAuthSelector } from "../../recoil/variableAtom";
import { RESULT, ResponseModel } from "../../model/response";
import { certifyPhoneState, checkAuthState, modalState } from "../../recoil/atoms";

function FindPhoneModal({ setValue }: { setValue: any }) {
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const [timer, setTimer] = useState(false);
  const [sendAuthCode, setsendAuthCode] = useState(false);
  const [phone, setPhone] = useState("");
  const [getBody, setBody] = useState<any>();
  const [getAuthBody, setAuthBody] = useState<any>();
  const [isCert, setCert] = useState<boolean>(false);
  const certResponse: ResponseModel<any> = usePost({
    recoilSelector: certifyPhoneSelector,
    data: getBody,
    recoilState: certifyPhoneState,
  });
  const checkResponse: ResponseModel<any> = usePost({
    recoilSelector: checkAuthSelector,
    data: getAuthBody,
    recoilState: checkAuthState,
  });

  const phoneForm = useForm({
    resolver: yupResolver(findPasswordValidation),
    mode: "onSubmit",
  });
  const authCodeForm = useForm({
    resolver: yupResolver(certifyAuthCodeValidation),
    mode: "onSubmit",
  });

  useEffect(() => {
    if (timer) {
      alert("인증 제한 시간이 만료되었습니다. 인증 버튼을 다시 클릭해주세요.");
      setTimer(false);
      setsendAuthCode(false);
    }
  }, [timer]);

  async function onPhoneSubmit(data: FieldValues) {
    let body = {
      ty: "AUTH",
      phone: data.phone.replace(/ /gi, "").replace(/-/gi, ""),
    };
    setBody(body);
    setPhone(data.phone);
  }

  useEffect(() => {
    if (certResponse?.result === RESULT.OK) {
      setsendAuthCode(true);
      setCert(true);
    }
    if (certResponse?.result === RESULT.FAIL) {
      alert(
        certResponse.msg ?? "[페이지 오류 안내]  홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
      );
    }
  }, [certResponse]);

  async function onAuthSubmit(data: any) {
    let body = {
      authCode: data.authCode,
    };
    setAuthBody(body);
  }
  useEffect(() => {
    if (checkResponse?.result === RESULT.OK) {
      alert("인증이 완료되었습니다.");
      setsendAuthCode(false);
      setValue("phone", phone.replace(/ /gi, "").replace(/-/gi, ""));
      setIsOpen(false);
    }
    if (checkResponse?.result === RESULT.FAIL) {
      alert(
        checkResponse.msg ?? "[페이지 오류 안내] 홈페이지 이용에 불편을 드려 죄송합니다. 새로고침 (F5키)을 누르시거나, 잠시 후에 이용해 주십시오."
      );
    }
  }, [checkResponse]);

  let phoneError: any = phoneForm.formState.errors.phone ? phoneForm.formState.errors.phone.message : undefined;
  let authCodeError: any = authCodeForm.formState.errors.authCode ? authCodeForm.formState.errors.authCode.message : undefined;

  return (
    <ModalBox width="450px">
      <Font fontSize="17px" fontWeight="500" className="p-[16px]">
        휴대전화 인증
      </Font>
      <ModalBorderContent>
        <ModalContent padding="0px" alignItems="start" justifyContent="start" textAlign="start">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              phoneForm.handleSubmit(onPhoneSubmit)(e);
              e.stopPropagation(); //외부 form의 submit버튼이 실행되는 것을 막아줌.
            }}
            className="w-full"
            // onKeyDown={(e) => checkKeyDown(e)}
          >
            <Wrap>
              <Input
                {...phoneForm.register("phone")}
                name="phone"
                type="text"
                placeholder="숫자만 입력해주세요."
                isError={phoneForm.formState.errors.phone ? true : false}
                onChange={(e: any) => {
                  makeHyphenInput(e, "phone");
                  phoneForm.setValue("phone", e.target.value);
                }}
              />
              <Blank width="10px" changeHeight="10px"></Blank>
              <EvButton fontSize="1rem" color="#FFF" bgColor={modalGreyBtnColor} type="submit">
                인증번호 전송
              </EvButton>
            </Wrap>
            {phoneForm.formState.errors.phone ? <Font color={noticeRedColor}>{phoneError}</Font> : <Blank height="10px"></Blank>}
          </Form>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              authCodeForm.handleSubmit(onAuthSubmit)(e);
              e.stopPropagation(); //외부 form의 submit버튼이 실행되는 것을 막아줌.
            }}
            // onKeyDown={(e) => checkKeyDown(e)}
            className="w-full"
          >
            <Wrap>
              <Input
                {...authCodeForm.register("authCode")}
                name="authCode"
                type="text"
                disabled={isCert ? false : true}
                placeholder="인증번호를 입력해주세요."
                isError={authCodeForm.formState.errors.authCode ? true : false}
              />
              <Blank width="10px" changeHeight="10px"></Blank>
              <EvButton
                fontSize="1rem"
                color="#FFF"
                bgColor={isCert ? modalGreyBtnColor : modalGreyBtnOpacityColor}
                type={isCert ? "submit" : "button"}
              >
                인증번호 확인
              </EvButton>
            </Wrap>
            {authCodeForm.formState.errors.authCode ? <Font color={noticeRedColor}>{authCodeError}</Font> : <Blank height="10px"></Blank>}
          </Form>
          {sendAuthCode && (
            <Div className="text-[#DC3545]">
              인증번호 유효시간: <Timer mm={3} ss={0} setTimer={setTimer}></Timer>
            </Div>
          )}
        </ModalContent>
      </ModalBorderContent>
      <ModalContent>
        <EvButton fontSize="1.2rem" color="#FFF" bgColor={primaryColor} padding="8px 28px" onClick={() => setIsOpen(false)}>
          닫기
        </EvButton>
      </ModalContent>
    </ModalBox>
  );
}
export default FindPhoneModal;
