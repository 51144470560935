/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Blank, Div, EvButton, Font, Form, PageLayout } from "../../styles/commonStyle";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cardTitleColor, labelBgColor, modalGreyBtnColor, orangeBtnGradient, primaryColor } from "../../styles/theme";
import { InfoBox } from "./style";
import InputLine from "../../components/input/inputLine";
import { updatePaycardValidation } from "../../validation";
import { RESULT, ResponseModel } from "../../model/response";
import { checkKeyDown, getUserIdFromToken, makeHyphenInput } from "../../util";
import { changePaycardSelector, showUserSelector } from "../../recoil/variableAtom";
import usePost from "../../hooks/usePost";
import { useEffect, useState } from "react";
import { useRecoilRefresher_UNSTABLE, useRecoilState } from "recoil";
import EvModal from "../../components/modal";
import Loading from "../../components/loading/loading";
import { errorState, loadingState, modalState, modalTypeState, variableState } from "../../recoil/atoms";

function ChangePaycardPage() {
  const navigate = useNavigate();
  const [body, setBody] = useState<any>();
  const [errMsg, setErrMsg] = useRecoilState(errorState);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const [modalType, setModalType] = useRecoilState(modalTypeState);
  let accessToken = window.sessionStorage.getItem("accessToken");
  const refreshUser = useRecoilRefresher_UNSTABLE(showUserSelector({ token: accessToken! }));
  const chagePaycardForm = useForm({
    resolver: yupResolver(updatePaycardValidation),
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const response: ResponseModel<any> = usePost({
    recoilSelector: changePaycardSelector,
    data: body,
    recoilState: variableState,
  });

  const onSubmit = async (data: any) => {
    if (!window.confirm("결제카드를 변경하시겠습니까?")) {
      return;
    }
    let userId = getUserIdFromToken();
    let body = {
      id: userId.toString(),
      paycardNo: data.paycardNo.replace(/ /gi, "").replace(/-/gi, ""),
      paycardExp: data.paycardExp.replace(/ /gi, "").replace(/-/gi, ""),
      paycardBirth: data.paycardBirth.replace(/ /gi, "").replace(/-/gi, ""),
      paycardPw: data.paycardPw.replace(/ /gi, ""),
    };
    setBody(body);
  };
  useEffect(() => {
    if (response?.result === RESULT.OK) {
      alert("정상적으로 완료되었습니다.");
      refreshUser();
      navigate("/userInfo");
    }
    if (response?.result === RESULT.FAIL) {
      setModalType("inputError");
      setErrMsg(response.msg!);
      setModalOpen(true);
    }
  }, [response]);
  return (
    <Form onSubmit={chagePaycardForm.handleSubmit(onSubmit)} className="w-full" onKeyDown={(e) => checkKeyDown(e)}>
      <PageLayout>
        <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px">
          결제카드 변경
        </Font>
        <Blank height="2rem" />
        <InfoBox>
          <InputLine
            label="카드번호"
            name="paycardNo"
            type="text"
            necessary={true}
            register={chagePaycardForm.register}
            errors={chagePaycardForm.formState.errors.paycardNo ? chagePaycardForm.formState.errors.paycardNo.message : undefined}
            labelColor={labelBgColor}
            // setValue={chagePaycardForm.setValue}
            isBottomlabel={true}
            bottomLabel="예) 1234-1234-1234-1234"
            onChange={(e: any) => {
              makeHyphenInput(e, "paycardNo");
              chagePaycardForm.setValue("paycardNo", e.target.value);
            }}
          />
          <InputLine
            label="유효 기간"
            name="paycardExp"
            type="text"
            necessary={true}
            register={chagePaycardForm.register}
            errors={chagePaycardForm.formState.errors.paycardExp ? chagePaycardForm.formState.errors.paycardExp.message : undefined}
            labelColor={labelBgColor}
            // setValue={chagePaycardForm.setValue}
            isBottomlabel={true}
            bottomLabel="월-년 순서로 입력, 예) 08-24"
            onChange={(e: any) => {
              makeHyphenInput(e, "paycardExp");
              chagePaycardForm.setValue("paycardExp", e.target.value);
            }}
          />
          <InputLine
            label="생년월일/사업자번호"
            name="paycardBirth"
            type="text"
            necessary={true}
            register={chagePaycardForm.register}
            errors={chagePaycardForm.formState.errors.paycardBirth ? chagePaycardForm.formState.errors.paycardBirth.message : undefined}
            labelColor={labelBgColor}
            setValue={chagePaycardForm.setValue}
            isBottomlabel={true}
            bottomLabel="예) 850823(개인), 1234578900(사업자)"
          />
          <InputLine
            label="비밀번호 앞 2자리"
            name="paycardPw"
            type="password"
            necessary={true}
            register={chagePaycardForm.register}
            errors={chagePaycardForm.formState.errors.paycardPw ? chagePaycardForm.formState.errors.paycardPw.message : undefined}
            labelColor={labelBgColor}
            setValue={chagePaycardForm.setValue}
          />
        </InfoBox>
        <Div className="flex w-[100%] justify-center py-[3rem]">
          <EvButton color="#FFF" bgColor={primaryColor} width="7rem" type="submit">
            수정
          </EvButton>
          <Blank width="1rem"></Blank>
          <Link to="/userinfo">
            <EvButton bgColor={modalGreyBtnColor} color="#fff" width="7rem" type="button">
              취소
            </EvButton>
          </Link>
        </Div>
      </PageLayout>
      <EvModal type={modalType} />
      <Loading isLoading={isLoading} />
    </Form>
  );
}

export default ChangePaycardPage;
