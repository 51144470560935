import { Blank, Div, Font } from "../../styles/commonStyle";
import { AlignSelf, InformationContent, InformationTitle, InformationLink } from "./style";


function MembershipInformation({ membershipInfoList, info }: { membershipInfoList?: any, info?: Boolean }) {
  return (
    <AlignSelf>
      <Div>
        {info ?
          <>
            <InformationTitle className="px-[12px] text-left">등급 기준 안내</InformationTitle>
            <Blank height="12px" />
            <InformationContent className="px-[12px] text-left">
              1. evPlug 멤버십으로 100원 이상 충전 결제 시 결제 충전량이 누적됩니다. (타사 카드로 결제 시 충전량이 누적되지 않습니다.)<br />
              2. 멤버십 등급은 {membershipInfoList ? membershipInfoList.countingPd : '3'}개월 동안 누적된 결제 충전량을 기준으로 산정합니다.<br />
              &nbsp;&nbsp;&nbsp;&nbsp;- 예시) 등급 갱신 주기가 3개월이라면 2024년 7월 등급은 2024.04.01 00시 ~ 2024.06.30 24시 기간 동안 결제된 누적 {membershipInfoList?.calculateTy == "PRICE" ? '충전 금액' : '충전량'} 기준<br />
              3. 멤버십 등급은 매월 {membershipInfoList ? membershipInfoList.refreshDay : 1}일마다 갱신됩니다.<br />
              4. {membershipInfoList?.calculateTy == "PRICE" ? '충전 금액' : '충전량'}은 충전 결제 완료 시점으로부터 실시간으로 반영됩니다.<br />
              5. 멤버십 등급 기준 및 혜택은 당사의 사정을 반영해 통보 없이 변경 또는 제외될 수 있습니다.
            </InformationContent>
          </> : <>
            <InformationTitle className="px-[12px] text-left">등급 기준 안내</InformationTitle>
            <Blank height="12px" />
            <InformationContent className="px-[12px] text-left">
              1. evPlug 멤버십으로 100원 이상 충전 결제 시 결제 충전량이 누적됩니다. (타사 카드로 결제 시 충전량이 누적되지 않습니다.)<br />
              2. 멤버십 등급은 {membershipInfoList ? membershipInfoList.countingPd : '3'}개월 동안 누적된 결제 충전량을 기준으로 산정합니다.<br />
              &nbsp;&nbsp;&nbsp;&nbsp;- 예시) 등급 갱신 주기가 3개월이라면 2024년 7월 등급은 2024.04.01 00시 ~ 2024.06.30 24시 기간 동안 결제된 누적 {membershipInfoList?.calculateTy == "PRICE" ? '충전 금액' : '충전량'} 기준<br />
              3. 멤버십 등급은 매월 {membershipInfoList ? membershipInfoList.refreshDay : 1}일마다 갱신됩니다.<br />
              4. {membershipInfoList?.calculateTy == "PRICE" ? '충전 금액' : '충전량'}은 충전 결제 완료 시점으로부터 실시간으로 반영됩니다.<br />
              5. 멤버십 등급 기준 및 혜택은 당사의 사정을 반영해 통보 없이 변경 또는 제외될 수 있습니다.<br />
            </InformationContent></>
        }
        <Blank height="32px" />
        {info ?
          <>
            <InformationTitle className="px-[12px] text-left">등급 리워드 안내</InformationTitle>
            <Blank height="12px" />
            <InformationContent className="px-[12px] text-left">
              1. 등급 리워드 포인트는 evPlug 멤버십으로 충전 결제 시, 충전한 금액을 기준으로 포인트 지급됩니다.<br />
              2. 등급 리워드 포인트는 멤버십 등급에 따라 상이하게 지급됩니다. <InformationLink to="https://evplug.co.kr/membership/info">(멤버십 등급별 비율 보러 가기)</InformationLink><br />
              3. 지급된 등급 리워드 포인트는 유효기간이 1년입니다.<br />
              4. 기한까지 사용하지 않은 포인트는 소멸되며, 재 지급은 불가합니다.<br />
              5. 포인트 사용 후, 단순 변심으로 결제 취소/환불할 경우 포인트 재 지급은 불가합니다.
            </InformationContent>
          </>
          : <>
            <InformationTitle className="px-[12px] text-left">포인트 안내</InformationTitle>
            <Blank height="12px" />
            <InformationContent className="px-[12px] text-left">
              1. 포인트 내역 내 반영되는 포인트 상태는 아래와 같습니다.<br />
              &nbsp;&nbsp;&nbsp;&nbsp;- ‘등급 리워드‘ (evPlug 멤버십으로 충전 결제 시 등급별 비율에 따라 지급되는 포인트)<br />
              &nbsp;&nbsp;&nbsp;&nbsp;- ‘유료충전’(evWhere APP 내 결제한 포인트)<br />
              &nbsp;&nbsp;&nbsp;&nbsp;- ‘적립‘(유료충전 시 일정 비율에 따라 지급되는 추가 포인트)<br />
              2. 등급 리워드 포인트는 evPlug 멤버십으로 충전 결제 시, 충전한 금액을 기준으로 지급됩니다.<br />
              3. 등급 리워드 포인트는 멤버십 등급에 따라 상이하게 지급됩니다. <InformationLink to="https://evplug.co.kr/membership/info">(멤버십 등급별 비율 보러 가기)</InformationLink><br />
              4. 지급된 등급 리워드 포인트는 유효기간이 1년입니다.<br />
              5. 기한까지 사용하지 않은 포인트는 소멸되며, 재 지급은 불가합니다.<br />
              6. 소멸 예정 포인트는 포인트 별 만료 기한에 따라 매일 변동됩니다.<br />
              7. 포인트 사용 후, 단순 변심으로 결제 취소/환불할 경우 포인트 재 지급은 불가합니다.
            </InformationContent>
          </>
        }
      </Div>
    </AlignSelf>
  );

}


function InformationItem({ title, content }: { title?: string; content: string; }) {
  return (
    <Div>
      {
        title ? <>
          <InformationTitle className="px-[12px] text-left">{title}</InformationTitle>
          <Blank height="12px" />
        </> : null
      }
      <InformationContent className="px-[12px] text-left">{content}</InformationContent>
    </Div>
  );

}

export default MembershipInformation;
