/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Link } from "react-router-dom";
import { PageLayout, Blank, EvButton, Font, Img, EvBorderButton } from "../../../styles/commonStyle";
import { blueBtnGradient, cardTitleColor, orangeBtnGradient, primaryColor, spanBlueColor } from "../../../styles/theme";
import { CardBox, CardContent, InlineLayout, MembershipBenefitsContainer } from "./style";
import { Wrap } from "../../register/style";
import membership01 from "../../../assets/images/membership01.png";
import membership02 from "../../../assets/images/membership02.png";
import membership03 from "../../../assets/images/membership03.png";
import { useRecoilState } from "recoil";
import { loginState } from "../../../recoil/atoms";
function MembershipBenefits() {
  const [islogin, setlogin] = useRecoilState(loginState);
  return (
    <MembershipBenefitsContainer>
      <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px">
        {"evPlug의 멤버십 혜택"}
      </Font>
      <Blank height="2rem" />
      <InlineLayout>
        <Wrap>
          <CardBox>
            <Img src={membership01} width="150px" height="150px" />
            <CardContent>할인된 요금제</CardContent>
          </CardBox>
          <CardBox>
            <Img src={membership02} width="150px" height="150px" />
            <CardContent>포인트 구매 시 추가적립</CardContent>
          </CardBox>
          <CardBox>
            <Img src={membership03} width="150px" height="150px" />
            <CardContent>등급별 리워드 적립</CardContent>
          </CardBox>
        </Wrap>
      </InlineLayout>
      <Blank height="2rem" />
      {!islogin ?
        <>
          <Link to="/register">
            <EvButton color="#FFF" bgColor={primaryColor} width="300px" fontSize="16px">
              회원가입하고 혜택 받기!
            </EvButton>
          </Link>
          <Blank height="12px" />
          <Link to="/login">
            <EvBorderButton width={"300px"} color={primaryColor} borderColor={primaryColor} fontSize="16px">
              로그인
            </EvBorderButton>
          </Link>
        </>
        : null
      }
    </MembershipBenefitsContainer>
  );
}

export default MembershipBenefits;
