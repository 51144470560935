/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilState } from "recoil";
import { RESULT, ResponseModel } from "../../model/response";
import { Blank, Div, EvButton, Font, Form, Input, Wrap } from "../../styles/commonStyle";
import { modalGreyBtnColor, modalGreyBtnOpacityColor, noticeRedColor, orangeBtnGradient, primaryColor } from "../../styles/theme";
import { ModalBorderContent, ModalBox, ModalContent } from "./style";
import { useEffect, useState } from "react";
import { certifyAuthCodeValidation, findPasswordValidation } from "../../validation";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Timer from "../timer/timer";
import { checkKeyDown, makeHyphenInput } from "../../util";
import usePost from "../../hooks/usePost";
import { certifyPhoneSelector, checkAuthSelector, findPwSelector } from "../../recoil/variableAtom";
import useFetch from "../../hooks/useFetch";
import { certifyPhoneState, checkAuthState, modalState } from "../../recoil/atoms";

function FindPasswordModal() {
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const [timer, setTimer] = useState(false);
  const [sendAuthCode, setsendAuthCode] = useState(false);
  const [isCertify, setIsCertify] = useState(false);
  const [certifyPhone, setCertifyPhone] = useState("");
  const [isComplete, setComplete] = useState(false);
  const [getBody, setBody] = useState<any>();
  const [getAuthBody, setAuthBody] = useState<any>();
  const [pwParams, setPwParams] = useState<any>();
  const [isCert, setCert] = useState<boolean>(false);
  const certResponse: ResponseModel<any> = usePost({
    recoilSelector: certifyPhoneSelector,
    data: getBody,
    recoilState: certifyPhoneState,
  });
  const checkResponse: ResponseModel<any> = usePost({
    recoilSelector: checkAuthSelector,
    data: getAuthBody,
    recoilState: checkAuthState,
  });
  const response: ResponseModel<any> | undefined = useFetch<ResponseModel<any>>({
    recoilSelector: findPwSelector,
    query: pwParams,
  });
  let phone: string;

  const phoneForm = useForm({
    resolver: yupResolver(findPasswordValidation),
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });
  const authCodeForm = useForm({
    resolver: yupResolver(certifyAuthCodeValidation),
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    if (timer) {
      alert("인증 제한 시간이 만료되었습니다. 인증 버튼을 다시 클릭해주세요.");
      setTimer(false);
      setsendAuthCode(false);
    }
  }, [timer]);

  async function onPhoneSubmit(data: FieldValues) {
    phone = data.phone.replace(/ /gi, "").replace(/-/gi, "");
    let body = {
      ty: "PW",
      phone: phone,
    };
    setBody(body);
    setCertifyPhone(phone);
  }

  useEffect(() => {
    if (certResponse?.result === RESULT.OK) {
      setsendAuthCode(true);
      setCert(true);
    }
    if (certResponse?.result === RESULT.FAIL) {
      alert(certResponse.msg);
    }
  }, [certResponse]);

  async function onAuthSubmit(data: FieldValues) {
    let body = {
      authCode: data.authCode,
    };
    setAuthBody(body);
  }

  useEffect(() => {
    if (checkResponse?.result === RESULT.OK) {
      alert("인증이 완료되었습니다.");
      setsendAuthCode(false);
      setIsCertify(true);
    }
    if (checkResponse?.result === RESULT.FAIL) {
      alert(checkResponse.msg);
    }
  }, [checkResponse]);

  async function onPasswordReqSubmit() {
    if (!isCertify) {
      alert("인증받은 전화번호가 없습니다.");
    } else {
      let params = {
        phone: certifyPhone,
      };
      setPwParams(params);
    }
  }

  useEffect(() => {
    if (response?.result === RESULT.OK) {
      setComplete(true);
    }
    if (response?.result === RESULT.FAIL) {
      alert(response.msg);
    }
  }, [response]);

  let phoneError: any = phoneForm.formState.errors.phone ? phoneForm.formState.errors.phone.message : undefined;
  let authCodeError: any = authCodeForm.formState.errors.authCode ? authCodeForm.formState.errors.authCode.message : undefined;

  if (response?.result === RESULT.OK) {
    return (
      <ModalBox width="100%">
        <Font fontSize="17px" fontWeight="500" className="p-[16px]">
          비밀번호 찾기
        </Font>
        <ModalBorderContent>
          <ModalContent padding="0px">
            인증받은 휴대폰번호로 임시 비밀번호를 보냈습니다. <br />
            반드시 비밀번호를 변경한 후 사용해 주세요.
          </ModalContent>
        </ModalBorderContent>
        <ModalContent>
          <EvButton fontSize="1.2rem" color="#FFF" bgColor={modalGreyBtnColor} padding="8px 28px" onClick={() => setIsOpen(false)}>
            확인
          </EvButton>
        </ModalContent>
      </ModalBox>
    );
  }
  return (
    <ModalBox width="450px">
      <Font fontSize="17px" fontWeight="500" className="p-[16px]">
        비밀번호 찾기
      </Font>
      <ModalBorderContent>
        <ModalContent padding="0px" alignItems="start" justifyContent="start" textAlign="start">
          <Form onSubmit={phoneForm.handleSubmit(onPhoneSubmit)} className="w-full" onKeyDown={checkKeyDown}>
            <Wrap>
              <Input
                {...phoneForm.register("phone")}
                name="phone"
                type="text"
                placeholder="숫자만 입력해주세요."
                isError={phoneForm.formState.errors.phone ? true : false}
                onChange={(e: any) => {
                  // makeHyphenInput(e, "phone");
                  phoneForm.setValue("phone", makeHyphenInput(e, "phone"));
                }}
              />
              <Blank width="10px"></Blank>
              <EvButton
                fontSize="1rem"
                color="#FFF"
                bgColor={!isCert ? modalGreyBtnColor : modalGreyBtnOpacityColor}
                type={!isCert ? "submit" : "button"}
              >
                인증번호 전송
              </EvButton>
            </Wrap>
            {phoneForm.formState.errors.phone ? <Font color={noticeRedColor}>{phoneError}</Font> : <Blank height="10px"></Blank>}
          </Form>
          <Form onSubmit={authCodeForm.handleSubmit(onAuthSubmit)} className="w-full" onKeyDown={checkKeyDown}>
            <Wrap>
              <Input
                {...authCodeForm.register("authCode")}
                name="authCode"
                type="text"
                disabled={isCert ? false : true}
                placeholder="인증번호를 입력해주세요."
                isError={authCodeForm.formState.errors.authCode ? true : false}
              />
              <Blank width="10px"></Blank>
              <EvButton
                fontSize="1rem"
                color="#FFF"
                bgColor={isCert ? modalGreyBtnColor : modalGreyBtnOpacityColor}
                type={isCert ? "submit" : "button"}
              >
                인증번호 확인
              </EvButton>
            </Wrap>
            {authCodeForm.formState.errors.authCode ? <Font color={noticeRedColor}>{authCodeError}</Font> : <Blank height="10px"></Blank>}
          </Form>
          {sendAuthCode && (
            <Div className="text-[#DC3545]">
              인증번호 유효시간: <Timer mm={3} ss={0} setTimer={setTimer}></Timer>
            </Div>
          )}
        </ModalContent>
      </ModalBorderContent>
      <ModalContent>
        <EvButton fontSize="1.2rem" color="#FFF" bgColor={primaryColor} padding="8px 28px" onClick={onPasswordReqSubmit}>
          임시 비밀번호 받기
        </EvButton>
      </ModalContent>
    </ModalBox>
  );
}
export default FindPasswordModal;
