/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Blank, Div, Font, PageLayout } from "../../styles/commonStyle";
import { cardTitleColor } from "../../styles/theme";
import { Notice } from "../../model/notice";
import { RESULT, ResponseModel } from "../../model/response";
import { useEffect, useState } from "react";
import { ResponseList } from "../../model/responseList";
import Pagination from "../../components/pagination";
import { useRecoilState } from "recoil";
import EvModal from "../../components/modal";
import Search from "./components/search";
import ListTile from "../../components/listTile";
import { noticeSelector } from "../../recoil/variableAtom";
import { useLocation, useNavigate } from "react-router-dom";
import useFetchList from "../../hooks/useFetchList";
import React from "react";
import Loading from "../../components/loading/loading";
import { loadingState, modalState, pageState, searchState } from "../../recoil/atoms";

function NoticeListPage() {
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const [currentPage, setPage] = useRecoilState(pageState);
  const [getSearch, setSearch] = useRecoilState(searchState);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [noticeList, setNoticeList] = useState<ResponseList<Notice> | undefined>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const cPage = params.get("currentPage") ?? "1";
  const search = params.get("search");
  const naviagate = useNavigate();
  const query: any =
    getSearch === ""
      ? {
          currentPage: currentPage,
          perPage: 10,
          ty: "NOTICE,GUIDE",
        }
      : {
          currentPage: currentPage,
          perPage: 10,
          ty: "NOTICE,GUIDE",
          search: getSearch,
        };

  const response: ResponseModel<ResponseList<Notice>> = useFetchList({
    recoilState: noticeSelector,
    query: query,
    currentPage: currentPage,
    search: getSearch,
  });

  const execSearch = (e: any) => {
    setPage(1);
    setSearch(e.currentTarget.value);
    naviagate(`/notice?currentPage=${currentPage}&search=${e.currentTarget.value}`);
  };

  useEffect(() => {
    setPage(Number(cPage));
    if (search) {
      setSearch(search);
    }
  }, []);

  useEffect(() => {
    if (response?.result === RESULT.FAIL) {
      setModalOpen(true);
    }
    if (response?.result === RESULT.OK) {
      setNoticeList(response.data);
    }
  }, [response]);
  return (
    <PageLayout>
      <Font fontSize="1.75rem" fontWeight="500" color={cardTitleColor} changeFontSize="20px">
        공지사항
      </Font>
      <Blank height="12px" />
      <Search execSearch={(e) => execSearch(e)} />
      <Blank height="12px" />
      <ListTile result={response?.result!} list={noticeList} />
      <Div className="flex mt-5 mb-5">
        <Pagination totalPage={noticeList?.totalPage!} currentPage={noticeList?.currentPage!} type="notice" />
      </Div>
      <Loading isLoading={isLoading} />
      <EvModal msg={response?.msg} />
    </PageLayout>
  );
}
export default React.memo(NoticeListPage);
