import styled from "styled-components";
import { boxBottomBorderColor, primaryColor } from "../../styles/theme";
import BgBenefitsList from "../../assets/images/bg-benefitsList.png";

const LevelBenefitsListStyled = styled.ul`
    @media only screen and (max-width: 640px){
        flex-flow: row wrap;
        li{
            width: 100%;
        }
    }
`

const Test = styled.div`
    /* background-image: url(${BgBenefitsList}); */
    background-position: center;
    background-repeat: no-repeat;
    background-color: #FFF0E3;
    
`

interface LevelLogoProps {
    color?: string;
}
const LevelLogo = styled.div<LevelLogoProps>`
    /* background-color: #f9f9f9; */
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 180px;
    margin:4px;
    color: ${(props) => props.color};
    font-size: 1.5rem;
    font-weight: 700;
    img{
        width: 180px;
        margin: 0 auto;
    }
    &.none{
        background-color: transparent;
    }
    @media only screen and (max-width: 640px){
        &.none{
            background-color: none;
            display: none;
        }
    }
`
const LevelPercent = styled.div`
    padding: 0 16px 16px 16px;
`
const LevelStandard = styled.div`
    border-top: 1px solid ${primaryColor};
    padding: 16px 16px 0 16px;
    @media only screen and (max-width: 640px){
        padding: 0 16px 24px 16px;
        border-top: none;
        border-bottom: 1px solid ${primaryColor};
    }
`

export { LevelBenefitsListStyled, LevelLogo, LevelPercent, LevelStandard, Test }