/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

const Timer = ({ mm, ss, setTimer }: { mm: number; ss: number; setTimer: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [minutes, setMinutes] = useState(mm);
  const [seconds, setSeconds] = useState(ss);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(countdown);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    if (minutes === 0 && seconds === 0) {
      setTimer(true);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [minutes, seconds]);

  return (
    <span>
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </span>
  );
};

export default Timer;
