import styled from "styled-components";
import { listBorderColor, noticeFontColor, searchBorderColor } from "../../styles/theme";

interface ListProps {
  ty?: string;
}

const ListHead = styled.div<ListProps>`
  background: ${(props) => (props.ty === "main" ? "#FFF" : "#f7f7f7")};
  border-color: hsla(0, 0%, 7%, 0.8);
  border-width: 2px 0 0 1px;
  border-bottom: 1px solid ${listBorderColor};
  border-top: 2px solid #000;
  font-size: ${(props) => (props.ty === "main" ? "18px" : "16px")}; //15px;
  font-weight: 500;
  padding: 13px 10px;
  width: 100%;
  border-right: 0px;
  border-left: 0px;
  display: flex;
`;

const ListContent = styled.div<ListProps>`
  font-weight: ${(props) => (props.ty === "main" ? 500 : 400)};
  color: ${noticeFontColor};
  padding: 10px;
  font-size: ${(props) => (props.ty === "main" ? "calc(0.8rem + 0.4vw)" : "calc(0.7rem + 0.4vw)")};
  border-bottom: 1px solid ${searchBorderColor};
  width: 100%;
  display: flex;
`;

const DetailContent = styled.div<ListProps>`
  color: ${noticeFontColor};
  padding: 10px;
  border-bottom: 1px solid ${searchBorderColor};
  width: 100%;
  display: flex;
`;

interface labelProps {
  bgColor: string;
}
const NoticeLabel = styled.div<labelProps>`
  color: #fff;
  background: ${(props) => props.bgColor};
  font-weight: 600;
  font-size: 12px;
  border-radius: 1.5rem;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  padding: 4.2px 7.2px;
  margin-right: 20px;
  min-width: 37px;
  max-height: 27px;
`;

const TitleLine = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;

export { ListHead, ListContent, NoticeLabel, TitleLine, DetailContent };
