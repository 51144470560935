import styled from "styled-components";
import { listBorderColor, mobileChangeWidth, primaryColor, searchBorderColor, searchColor } from "../../styles/theme";

const SearchInput = styled.input`
  border: 1px solid ${searchBorderColor};
  width: 248px;
  padding: 6px 12px;
  :focus {
    outline: none;
    border: 1px solid ${primaryColor};
    z-index: 1;
  }
  @media only screen and (max-width: ${mobileChangeWidth}) {
    width: 100%;
  }
`;

const SearchRightBox = styled.button`
  border: 1px solid ${searchBorderColor};
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -1px;

  :hover {
    border-color: ${primaryColor};
    .searchGlass {
      color: ${primaryColor};
    }
  }
`;

interface ListProps {
  ty?: string;
}

const ListHead = styled.div<ListProps>`
  background: ${(props) => (props.ty === "main" ? "#FFF" : "#f7f7f7")};
  border-color: hsla(0, 0%, 7%, 0.8);
  border-width: 2px 0 0 1px;
  border-bottom: 1px solid ${listBorderColor};
  border-top: 2px solid #000;
  font-size: ${(props) => (props.ty === "main" ? "18px" : "16px")}; //15px;
  font-weight: 500;
  padding: 13px 10px;
  width: 100%;
  border-right: 0px;
  border-left: 0px;
  display: flex;

  @media only screen and (max-width: ${mobileChangeWidth}) {
    flex-direction: column;
  }
`;

const HeadTitle = styled.div`
  width: 75%;
  font-weight: 500;
  text-align: left;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    width: 100%;
  }
`;

const HeadDate = styled.div`
  //w-[25%] px-[12px] text-right text-[#808184] font-medium
  width: 25%;
  color: ${searchColor};
  font-weight: 500;
  text-align: end;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    width: 100%;
    text-align: left;
  }
`;

export { SearchRightBox, SearchInput, ListHead, HeadDate, HeadTitle };
