import styled from "styled-components";
import { dividerColor, greyColor, inlineWidth, mobileChangeWidth, navMenuFontColor } from "../../styles/theme";
import { EvBorderButton } from "../../styles/commonStyle";

const HeaderBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #fff;
`;
const HeaderDiv = styled.div`
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: center;
`;

const ContentDiv = styled.div`
  width: ${inlineWidth};
  display: flex;
  margin: 0px auto;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    display: none;
  }
`;

const FontBox = styled.div`
  display: flex;
`;

const ButtonBox = styled.div`
  display: flex;
`;

const MobileContentDiv = styled.div`
  display: none;
  @media only screen and (max-width: ${mobileChangeWidth}) {
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
  }
`;

const NavModalDiv = styled.div`
  //display: none; /* Hidden by default */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const NavModalContent = styled.div`
  background-color: #fefefe;
  width: 80%;
  height: 100%;
`;

const NavModalHeader = styled.div`
  width: 100%;
  padding: 20px;
  border: 0px solid #fff;
  border-bottom: 1px solid ${dividerColor};
`;
const NavModalMenuBox = styled.div`
  width: 100%;
  padding: 20px;
`;
const NavMenu = styled.div`
  width: 100%;
  padding: 10px;
  color: ${navMenuFontColor};
  font-weight: 500;
  border-bottom: 1px solid ${dividerColor};
`;

const MenuDropdown = styled.div`
  width: 150px;
  background-color: #fff;
  top: 38px;
  left: -1px;
  position: absolute;
  border: 1px solid ${greyColor};
  border-radius: 7px;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
`;
interface IconProps {
  bgImage?: string;
  changeBgImage?: string;
}
const Triangle = styled.div<IconProps>`
  background-image: url(${(props) => props.bgImage});
  width: 15px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  ${EvBorderButton}:hover & {
    background-image: url(${(props) => props.changeBgImage});
  }
`;
const TriangleNoHover = styled.div<IconProps>`
  background-image: url(${(props) => props.bgImage});
  width: 15px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
`;


const MenuOption = styled.div`
  text-align: start;
  padding-left: 10px;
  &:hover {
    background-color: ${greyColor};
  }
`;
export {
  MenuOption,
  Triangle,
  TriangleNoHover,
  MenuDropdown,
  HeaderDiv,
  ContentDiv,
  FontBox,
  ButtonBox,
  MobileContentDiv,
  NavModalDiv,
  NavModalContent,
  NavModalHeader,
  NavModalMenuBox,
  NavMenu,
  HeaderBox,
};
