import { Link } from "react-router-dom";
import { RESULT } from "../../model/response";
import { Div, Font } from "../../styles/commonStyle";
import { UtctoLocaleFormmatter } from "../../util";
import { ListContent, ListHead, NoticeLabel, TitleLine } from "./style";
import { noticeBlueColor, noticeRedColor } from "../../styles/theme";
import { ResponseList } from "../../model/responseList";
import { Notice } from "../../model/notice";

function ListTile({ result, list, ty }: { result: RESULT; list: ResponseList<Notice> | undefined; ty?: string }) {
  return (
    <Div className={ty !== "main" ? "min-h-[550px] w-full" : "w-full"}>
      <ListHead ty={ty}>
        <Div className="w-[70%] px-[12px] text-center">제목</Div>
        <Div className="w-[30%] px-[12px] text-center">작성일</Div>
      </ListHead>
      {list?.data.length === 0 && result === RESULT.OK ? (
        <Font className="mt-5">작성된 글이 존재하지 않습니다.</Font>
      ) : (
        list?.data.map((value) => getListLine(value, ty))
      )}
    </Div>
  );
}

function getListLine(line: Notice, ty?: string) {
  const regDt: string = UtctoLocaleFormmatter(line.regDt);
  return (
    <ListContent key={"noticeList" + line.id} ty={ty}>
      <Link to={"/notice/" + line.id} className="flex w-full">
        <Div className="w-[70%] px-[12px] text-center flex">
          {line.ty === "NOTICE" ? (
            <NoticeLabel bgColor={noticeRedColor}>공지</NoticeLabel>
          ) : (
            <NoticeLabel bgColor={noticeBlueColor}>이용가이드</NoticeLabel>
          )}
          <TitleLine>{line.sj}</TitleLine>
        </Div>
        <Div className="w-[30%] px-[12px] text-center">{regDt}</Div>
      </Link>
    </ListContent>
  );
}

export default ListTile;
