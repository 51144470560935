import React from 'react';
import styled, { css } from 'styled-components';
import warningIcon from "../../../assets/images/icon-warning.svg";
import infoIcon from "../../../assets/images/icon-info-blue.svg";

export enum Status {
  INFO = 'info',
  SAFE = 'safe',
  WARNING = 'warning',
  DANGER = 'danger'
}

interface InfoTextProps {
  status: Status;
  text: string;
  label?: boolean;
}

export const InfoText: React.FC<InfoTextProps> = ({ status, text, label = true }) => {
  const getStatusIcon = (status: Status) => {
    switch (status) {
      case Status.INFO:
        return infoIcon;
      case Status.SAFE:
        return warningIcon;
      case Status.WARNING:
        return warningIcon;
      case Status.DANGER:
        return warningIcon;
      default:
        return '';
    }
  };
  return (
    <Flex>
      {!label ? null : <EmptyLabel />}
      <StyledInfoText status={status}>
        <Icon src={getStatusIcon(status)} alt={`${status} icon`} />
        {text}
      </StyledInfoText>
    </Flex>
  );
};

const Flex = styled.div`
  display: flex;
`;

const EmptyLabel = styled.div`
  width: 25%;
  @media only screen and (max-width: 640px){
    display: none;
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

const StyledInfoText = styled.div<{ status: Status }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 1.5;

  ${({ status }) => status === Status.INFO && css`
    color: #2768FF;
  `}

  ${({ status }) => status === Status.SAFE && css`
    color: green;
  `}

  ${({ status }) => status === Status.WARNING && css`
    color: #98690A;
  `}

  ${({ status }) => status === Status.DANGER && css`
    color: red;
  `}
`;
