import styled from "styled-components";
import { greyBgColor, greyColor2, inputBorderColor, loginChangeWidth, navMenuFontColor, stipulationBgColor } from "../../styles/theme";

interface LayoutProps {
  image?: string;
}
const Layout = styled.div<LayoutProps>`
  background-image: url(${(props) => props.image});
  background-position-y: -70px;
  width: 100%;
  min-height: 82vh;
  height: auto;
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  @media only screen and (max-width: ${loginChangeWidth}) {
    background-image: none;
  }
`;

interface ContentBoxProps {
  paddingTop?: string;
  paddingLeft?: string;
  paddingBottom?: string;
  paddingRight?: string;
}
const ContentBox = styled.div<ContentBoxProps>`
  width: 40%;
  /* height: 500px; */
  min-width: 600px;
  padding-top: ${(props) => props.paddingTop ?? "2rem"}; //2rem;
  padding-left: ${(props) => props.paddingLeft ?? "5rem"}; //5rem;
  padding-bottom: ${(props) => props.paddingBottom ?? "3rem"}; //3rem;
  padding-right: ${(props) => props.paddingRight ?? "5rem"}; //5rem;
  /* background-color: #fff; */
  /* opacity: 0.9; */
  background-color: rgba(255, 255, 255, 0.93);
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  @media only screen and (max-width: ${loginChangeWidth}) {
    width: 100%;
    min-width: auto;
    padding-top: calc(1rem + 1vw);
    padding-left: calc(0.5rem + 4.5vw);
    padding-bottom: calc(2rem + 1vw);
    padding-right: calc(0.5rem + 4.5vw);
  }
`;

interface ColorCircleProps {
  color?: string;
}
const ColorCircle = styled.div<ColorCircleProps>`
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
`;

const ChargingResultBox = styled.div`
  background-color: ${greyBgColor};
  border-radius: 7px;
  padding: 0.8rem 1rem;
  width: 100%;
  border: 1px solid ${inputBorderColor};
  display: flex;
  justify-content: space-between;
`;
const AgreeAllBox = styled.div`
  font-size: 1rem;
  color: ${navMenuFontColor};
  font-weight: 500;
  display: flex;
  width: 100%;
`;

const StipulationBox = styled.div`
  border-radius: 7px;
  width: 100%;
  border: 1px solid ${inputBorderColor};
  display: flex;
  /* justify-content: space-between;
  align-items: center; */
  flex-direction: column;
`;

const StipulationHeaderBox = styled.div`
  width: 100%;
  display: flex;
  padding: 0.4rem;
  justify-content: space-between;
  align-items: center;
`;

interface StipulationContentBoxProps {
  height: string;
}
const StipulationContentBox = styled.div<StipulationContentBoxProps>`
  width: 100%;
  height: ${(props) => props.height};
  background-color: ${stipulationBgColor};
  transition: 0.5s;
  padding: ${(props) => (props.height !== "0px" ? "0.4rem" : null)};
  font-size: 0.8rem;
  border-top: ${(props) => (props.height !== "0px" ? "1px solid #EEE9E9" : null)};
`;

interface PageMoveDivProps {
  color?: string;
}
const PageMoveBtn = styled.button<PageMoveDivProps>`
  font-size: 30px;
  color: ${(props) => props.color ?? greyColor2};
  display: flex;
  align-items: center;
`;

interface ArrowProps {
  arrowImg?: string;
}
const Arrow = styled.div<ArrowProps>`
  width: 30px;
  height: 50px;
  background-image: url(${(props) => props.arrowImg});
  background-size: auto;
  background-repeat: no-repeat;
`;

const BorderBox = styled.div`
  border: 1px solid ${inputBorderColor};
  border-radius: 7px;
  width: 100%;
  padding: 1rem;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImgButton = styled.button`
  width: calc(5rem + 1vw);
  border: 1px solid ${inputBorderColor};
  border-radius: 7px;
  margin: 0.2rem;
  padding: 0.2rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  ImgButton,
  BorderBox,
  Arrow,
  Layout,
  ContentBox,
  ColorCircle,
  ChargingResultBox,
  AgreeAllBox,
  StipulationBox,
  StipulationHeaderBox,
  StipulationContentBox,
  PageMoveBtn,
};
