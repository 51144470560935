import styled from "styled-components"
import { boolean } from "yup";

interface StyledTopButtonProps {
    isVisible?: boolean,
}

const StyledTopButton = styled.button<StyledTopButtonProps>`
    display: ${(props) => props.isVisible ? 'block' : 'none'};
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 50%;
    position: fixed;
    right: 60px;
    bottom: 60px;
`

export { StyledTopButton };