/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link, useNavigate } from "react-router-dom";
import { Blank, EvButton } from "../../styles/commonStyle";
import { primaryColor } from "../../styles/theme";
import { ModalBox, ModalContent } from "./style";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { logoutHandler } from "../../util";
import { errorState, loginState } from "../../recoil/atoms";

function ErrorModal({ msg, closeModal }: { msg?: string; closeModal: () => void }) {
  const [errMsg, setErrMsg] = useRecoilState(errorState);
  const [islogin, setlogin] = useRecoilState(loginState);
  const navigate = useNavigate();

  useEffect(() => {
    logoutHandler(setlogin, navigate);
  }, []);

  return (
    <ModalBox height="170px">
      <ModalContent height="100%">
        문제가 발생했습니다
        <br />
        메인페이지로 이동합니다
        <br />
        {errMsg}
        <Blank height="10px" />
        <Link to="/">
          <EvButton fontSize="1rem" color="#FFF" bgColor={primaryColor} onClick={closeModal}>
            확인
          </EvButton>
        </Link>
      </ModalContent>
    </ModalBox>
  );
}

export default ErrorModal;
