/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { ArrowBtn, LeftArrow, PageButton, RightArrow } from "./style";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router";
import { Div } from "../../styles/commonStyle";
import { pageState, searchState } from "../../recoil/atoms";

function Pagination({ totalPage, currentPage, type }: { totalPage: string; currentPage: string; type: string }) {
  let pageBox = [];
  const [check, setCheck] = useState<Number>();
  const [page, setPage] = useRecoilState(pageState);
  const [getSearch, setSearch] = useRecoilState(searchState);
  const naviagate = useNavigate();
  const perPage = 10;

  const clickHandler = (e: any) => {
    setCheck(parseInt(e.target.value));
    setPage(parseInt(e.target.value));
    if (type === "notice") {
      naviagate(
        getSearch ? `/notice?currentPage=${parseInt(e.target.value)}&search=${getSearch}` : `/notice?currentPage=${parseInt(e.target.value)}`
      );
    } else if (type === 'charging') {
      naviagate(
        getSearch ? `/charging?currentPage=${parseInt(e.target.value)}&search=${getSearch}` : `/charging?currentPage=${parseInt(e.target.value)}`
      );
    } else {
      naviagate(
        getSearch ? `/charging/pointlist?currentPage=${parseInt(e.target.value)}&search=${getSearch}` : `/charging/pointlist?currentPage=${parseInt(e.target.value)}`
      );
    }
  };

  const nextPageHandler = () => {
    const pageValue = viewPage + 1;
    setCheck(pageValue);
    setPage(pageValue);
    if (type === "notice") {
      naviagate(getSearch ? `/notice?currentPage=${pageValue}&search=${getSearch}` : `/notice?currentPage=${pageValue}`);
    } else if (type === 'charging') {
      naviagate(getSearch ? `/charging?currentPage=${pageValue}&search=${getSearch}` : `/charging?currentPage=${pageValue}`);
    } else {
      naviagate(getSearch ? `/charging/pointlist?currentPage=${pageValue}&search=${getSearch}` : `/charging/pointlist?currentPage=${pageValue}`);
    }
  };
  const prevPageHandler = () => {
    // const pageValue = parseInt(currentPage) - perPage > 0 ? parseInt(currentPage) - perPage : 1;
    let viewPage = Math.floor(parseInt(currentPage) / perPage) === 0 ? 1 * perPage : Math.ceil(parseInt(currentPage) / perPage) * perPage;
    const pageValue = parseInt(currentPage) - perPage > 0 ? viewPage - perPage - perPage + 1 : 1;
    setCheck(pageValue);
    setPage(pageValue);
    if (type === "notice") {
      naviagate(getSearch ? `/notice?currentPage=${pageValue}&search=${getSearch}` : `/notice?currentPage=${pageValue}`);
    } else if (type === 'charging') {
      naviagate(getSearch ? `/charging?currentPage=${pageValue}&search=${getSearch}` : `/charging?currentPage=${pageValue}`);
    } else {
      naviagate(getSearch ? `/charging/pointlist?currentPage=${pageValue}&search=${getSearch}` : `/charging/pointlist?currentPage=${pageValue}`);
    }
  };

  useEffect(() => {
    if (currentPage) {
      setCheck(Number(currentPage));
    }
  }, [currentPage]);

  let viewPage = Math.floor(parseInt(currentPage) / perPage) === 0 ? 1 * perPage : Math.ceil(parseInt(currentPage) / perPage) * perPage;
  let startPage = viewPage - perPage + 1;
  if (parseInt(totalPage) < viewPage) {
    viewPage = parseInt(totalPage);
  }

  for (let i = startPage; i <= viewPage; i++) {
    if (viewPage === 1 || viewPage === startPage) {
      pageBox.push(
        <PageButton
          key={"pagebox" + i}
          bottomLeftRadius="0.5rem"
          bottomRightRadius="0.5rem"
          topLeftRadius="0.5rem"
          topRightRadius="0.5rem"
          value={i}
          onClick={(e) => clickHandler(e)}
          bgColorChange={check === i ? true : false}
        >
          {i}
        </PageButton>
      );
    } else if (i === startPage) {
      pageBox.push(
        <PageButton
          key={"pagebox" + i}
          bottomLeftRadius="0.5rem"
          topLeftRadius="0.5rem"
          value={i}
          onClick={(e) => clickHandler(e)}
          bgColorChange={check === i ? true : false}
        >
          {i}
        </PageButton>
      );
    } else if (i === viewPage) {
      pageBox.push(
        <PageButton
          key={"pagebox" + i}
          topRightRadius="0.5rem"
          bottomRightRadius="0.5rem"
          value={i}
          onClick={(e) => clickHandler(e)}
          bgColorChange={check === i ? true : false}
        >
          {i}
        </PageButton>
      );
    } else {
      pageBox.push(
        <PageButton key={"pagebox" + i} value={i} onClick={(e) => clickHandler(e)} bgColorChange={check === i ? true : false}>
          {i}
        </PageButton>
      );
    }
  }
  if (viewPage < parseInt(totalPage)) {
    pageBox.push(
      <ArrowBtn key={"lastArrow"} onClick={nextPageHandler}>
        <RightArrow />
      </ArrowBtn>
    );
  }
  if (currentPage !== undefined) {
    if (parseInt(currentPage) !== 1 && startPage !== 1) {
      pageBox.unshift(
        <ArrowBtn key={"firstArrow"} onClick={prevPageHandler}>
          <LeftArrow />
        </ArrowBtn>
      );
    }
  }
  return <Div className="flex justify-center items-center">{pageBox}</Div>;
}

export default Pagination;
