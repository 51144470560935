/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import MembershipInformation from "../../components/information";
import Information from "../../components/information";
import MembershipBenefits from "./components/membershipBenefits";
import { Blank, Div, Font, PageLayout } from "../../styles/commonStyle";
import { cardTitleColor, fontColor, primaryColor } from "../../styles/theme";
import LevelBenefitsList from "./LevelBenefitsList";
import { Test } from "./style";
import TopButton from "../../components/topButton/topButton";
import { RESULT, ResponseModel } from "../../model/response";
import useFetch from "../../hooks/useFetch";
import { getJustMembershipGradeSelector, getUserMembershipGradeSelector } from "../../recoil/variableAtom";
import { useEffect, useState } from "react";

function MembershipInfoPage() {

    const [membershipInfoList, setMembershipInfoList] = useState<any>();

    const getJustMembershipGrade: ResponseModel<any> = useFetch({
        recoilSelector: getJustMembershipGradeSelector,
    });
    useEffect(() => {
        if (getJustMembershipGrade?.result === RESULT.OK) {
            setMembershipInfoList(getJustMembershipGrade.data);
        }
    }, [getJustMembershipGrade])

    return (
        <>
            <Div className="w-full">
                <PageLayout>
                    <MembershipBenefits />
                </PageLayout>
                {
                    membershipInfoList && <>
                        <Blank height="4rem" />
                        <Test>
                            <PageLayout>
                                <Font fontSize="1.75rem" fontWeight="500" color="#2B2B2B" changeFontSize="20px" className="w-full flex justify-center">
                                    멤버십 등급 별 혜택 안내
                                </Font>
                                <Blank height="2rem" />
                                <LevelBenefitsList membershipInfoList={membershipInfoList} />
                                <Blank height="40px" />
                            </PageLayout>
                        </Test>
                        <Blank height="2rem" />
                        <PageLayout>
                            <MembershipInformation membershipInfoList={membershipInfoList} info={true} />
                        </PageLayout>
                        <Blank height="8rem" />
                    </>
                }

            </Div>
            <TopButton />
        </>
    );
}
export default MembershipInfoPage;
