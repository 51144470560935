/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { RecoilState, useRecoilState, useRecoilStateLoadable, useResetRecoilState } from "recoil";
import { useLayoutEffect } from "react";
import { loadingState } from "../recoil/atoms";

function usePost({ recoilSelector, data, recoilState }: { recoilSelector: RecoilState<any>; data?: any; recoilState: RecoilState<any> }) {
  const [postData, setData] = useRecoilStateLoadable(recoilSelector);
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const reset = useResetRecoilState(recoilSelector);
  const resetState = useResetRecoilState(recoilState);

  useLayoutEffect(() => {
    setLoading(true);
    if (data !== null || data !== undefined) {
      setData(data);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (postData.state !== "loading") {
      setLoading(false);
      resetState();
      reset();
    }
  }, [postData.state]);

  if (postData.state !== "loading") {
    return postData.contents;
  }
}

export default usePost;
