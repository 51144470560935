/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CardBox, CardContent, CardTitle, InlineLayout, LeftBox, RightBox, Section, TextBox, TransparencyBox, TransparencyContent } from "./style";
import section1 from "../../assets/images/img-visual.jpg";
import topLabel from "../../assets/images/img-top-label.png";
import { A, Blank, Div, EvButton, Font, Img, PageLayout, Span, Wrap } from "../../styles/commonStyle";
import line from "../../assets/images/line.png";
import iconJoin from "../../assets/images/icon-join.png";
import iconCharger from "../../assets/images/icon-charger.png";
import { blueBtnGradient, cardTitleColor, fontColor, greenBtnGradient, lightBlueColor, orangeBtnGradient, primaryColor, sectionBgGradient, spanBlueColor } from "../../styles/theme";
import section5 from "../../assets/images/img-bg-full-main.jpg";
import iconCS from "../../assets/images/icon-cs.png";
import iconEvwhere from "../../assets/images/ico-new-evwhere.png";
import ecoLogo from "../../assets/images/ecoLogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import btnAppstore from "../../assets/images/btn-appstore.png";
import btnPlaystore from "../../assets/images/btn-playstore.png";
import ttiBanner from "../../assets/images/tti-banner.png";
import { useEffect, useState } from "react";
import { RESULT, ResponseModel } from "../../model/response";
import { ResponseList } from "../../model/responseList";
import { Notice } from "../../model/notice";
import EvModal from "../../components/modal";
import EvPopup from "../../components/modal/popup";
import ListTile from "../../components/listTile";
import ArrowGo from "../../assets/images/arrow-go.png";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { noticeMainSelector, popupSelector } from "../../recoil/variableAtom";
import useFetchList from "../../hooks/useFetchList";
import Loading from "../../components/loading/loading";
import { loadingState, modalState, isPopupOpenState } from "../../recoil/atoms";
import { getCookie } from "../../util";

function LandingPage() {
  const productPdf = require("../../assets/pdf/products.pdf");
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [isPopupOpen, setPopupOpen] = useRecoilState(isPopupOpenState);
  const [noticeList, setNoticeList] = useState<ResponseList<Notice> | undefined>();
  const [popups, setPopups] = useState<Notice | undefined>();
  const [isLoading, setLoading] = useRecoilState(loadingState);
  const query: any = {
    currentPage: 1,
    perPage: 5,
    ty: "NOTICE,GUIDE",
  };

  const response: ResponseModel<ResponseList<Notice>> = useFetchList({
    recoilState: noticeMainSelector,
    query: query,
  });

  const popupRes: ResponseModel<Notice[]> = useFetchList({
    recoilState: popupSelector,
  });


  useEffect(() => {
    if (response?.result === RESULT.FAIL) {
      setModalOpen(true);
    }
    if (response?.result === RESULT.OK) {
      setNoticeList(response.data);
    }
  }, [response]);

  useEffect(() => {
    if (popupRes?.result === RESULT.FAIL) {
      setModalOpen(false);
    }
    if (popupRes?.result === RESULT.OK) {
      if (popupRes && popupRes.data && popupRes.data.length > 0) {
        setPopups(popupRes.data[0]);
      }
    }
  }, [popupRes]);

  useEffect(() => {
    if (popups === undefined) return;
    var id = getCookie(`${popups.id}`);
    if (id == undefined) {
      setPopupOpen(true);
    }
  }, [popups]);

  return (
    <Div>
      <Section image={section1}>
        <InlineLayout>
          <TextBox>evPlug 서비스란?</TextBox>
          <Wrap>
            <LeftBox image={line}>
              <Img src={topLabel} alt="top-label" />
            </LeftBox>
            <RightBox>
              <p className="mb-4">
                쉽고 저렴한 충전, evPlug <br />
                언제 어디에서건 충전할 수 있습니다.
              </p>
              <p>
                전기차 사용 고객님들과 늘 함께 하겠습니다. <br />
                국내 최다 충전소 정보 서비스 evPlug
              </p>
            </RightBox>
          </Wrap>
        </InlineLayout>
      </Section>
      <Section>
        <InlineLayout>
          <Wrap>
            <CardBox>
              <Img src={iconJoin} width="120px" height="120px" />
              <CardTitle>evPlug 멤버십가입</CardTitle>
              <CardContent>
                지금 바로 evPlug의 멤버가 되어주세요. <br />
                다양한 혜택이 고객님을 기다립니다.
              </CardContent>
              <Link to="/register">
                <EvButton color="#fff" bgColor={primaryColor} className="mt-7">
                  멤버십 가입
                </EvButton>
              </Link>
            </CardBox>
            <CardBox>
              <Img src={iconCharger} width="120px" height="120px" />
              <CardTitle>충전기 설치신청</CardTitle>
              <CardContent>
                evplug의 회원으로 가입하시고
                <br />
                원하는 장소에 충전기 설치를 신청해 보세요.
              </CardContent>
              <Link to="/installRequest">
                <EvButton color="#fff" bgColor={spanBlueColor} className="mt-7">
                  바로가기
                </EvButton>
              </Link>
            </CardBox>
          </Wrap>
        </InlineLayout>
      </Section>
      <Section minHeight="0px" bgColor={sectionBgGradient} padding="100px 0px" changePadding="60px 0px">
        <Div className="flex justify-center">
          <A href={productPdf} target="_blank" rel="noopener noreferrer">
            <EvButton color="#fff" bgColor={primaryColor}>
              충전기 상세정보 보기
            </EvButton>
          </A>
        </Div>
      </Section>
      <Section padding="100px 0px" changePadding="60px 0px">
        <Div className="flex justify-center flex-col items-center">
          <Div className="flex items-center justify-between w-[1200px] max-[1200px]:w-full">
            <Div className="flex w-[88px]"></Div>
            <Font color={cardTitleColor} fontSize="calc(1.3rem + 0.9vw)" fontWeight="600">
              공지사항
            </Font>
            <Link to="/notice?currentPage=1">
              <Div className="flex w-[88px] items-center">
                <Font fontSize="calc(0.8rem + 0.5vw)" color={fontColor} fontWeight="400">
                  더보기
                </Font>
                <Img src={ArrowGo} alt="arrowGo" width="calc(1rem + 0.5vw)" height="calc(1rem + 0.5vw)" />
              </Div>
            </Link>
          </Div>
          {isLoading ? (
            <Loading isLoading={true} cssStyle="w-full min-h-[200px] flex justify-center items-center" />
          ) : (
            <PageLayout paddingTop="1.5rem">
              <ListTile result={response?.result!} list={noticeList} ty="main" />
            </PageLayout>
          )}
        </Div>
      </Section>
      <Section image={section5} minHeight="678px" changePadding="38px 12px">
        <Wrap className="justify-center items-center">
          <TransparencyBox>
            <Img src={iconCS} width="120px" height="120px"></Img>
            <Blank height="30px" changeHeight="12px" />
            <Font color={cardTitleColor} fontSize="36px" fontWeight="600" changeFontSize="20px">
              고객센터
            </Font>
            <TransparencyContent>
              고객 만족을 위해 <br />
              노력하겠습니다. <br />
            </TransparencyContent>
            <A href="tel:0269563055">
              <EvButton color="#fff" bgColor={spanBlueColor} className="flex justify-center items-center">
                <FontAwesomeIcon icon={faPhone} />
                <Blank width="3px" />
                02-6956-3055
              </EvButton>
            </A>
            <Blank height="16px" />
            <A href="tel:0269563000">
              <EvButton color="#fff" bgColor={spanBlueColor} className="flex justify-center items-center">
                <FontAwesomeIcon icon={faPhone} />
                <Blank width="3px" />
                02-6956-3000
              </EvButton>
            </A>
            <Blank height="16px" />
            <A href="https://www.ev.or.kr/mobile/report/mobileReport" target="_blank" rel="noopener noreferrer">
              <EvButton
                color="#fff"
                bgColor={greenBtnGradient}
                fontSize="13px"
                changeFontSize="10px"
                className="flex justify-center items-center"
                padding="11px 11px 11px 5px"
              >
                <Img src={ecoLogo} alt="ecoLogo" height="25px" width="75px" changeWidth="55px" changeHeight="18px" />
                <Blank changeWidth="9px" />
                불편민원신고센터
              </EvButton>
            </A>
          </TransparencyBox>
          <TransparencyBox>
            <Img src={iconEvwhere} className="rounded-[25%]" width="120px" height="120px"></Img>
            <Blank height="30px" changeHeight="12px" />
            <Font color={cardTitleColor} fontSize="36px" fontWeight="600" changeFontSize="20px">
              충전소 찾기
            </Font>
            <TransparencyContent>
              <Span color={primaryColor}>evWhere</Span>를 통해 모바일로 <br />
              충전소를 찾아보세요.
            </TransparencyContent>
            <A href="https://itunes.apple.com/kr/app/evwhere/id1231333404" target="_blank">
              <Img src={btnAppstore} width="20vh" />
            </A>
            <Blank height="16px" />
            <A href="https://play.google.com/store/apps/details?id=com.ecoevc.evwhere.evwhere" target="_blank">
              <Img src={btnPlaystore} width="20vh" />
            </A>
            <Blank height="50.36px" changeHeight="0px" />
          </TransparencyBox>
        </Wrap>
      </Section>
      <Section minHeight="0"><A href="https://www.tardistechnology.com/" target="_blank"><Img src={ttiBanner} alt="타디스테크놀로지 링크" /></A></Section>
      <EvModal msg={response?.msg!} />
      {
        popups != undefined && <EvPopup notice={popups} />
      }

    </Div>
  );
}

export default LandingPage;
