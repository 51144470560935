/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import bgImage from "../../assets/images/img-bg-full.png";
import { Blank, Div, EvBorderButton, EvButton, Font, Form, Input } from "../../styles/commonStyle";
import { noticeRedColor, orangeBtnGradient, primaryColor } from "../../styles/theme";
import { FontLink, LoginBox, LoginLayout } from "./style";
import EvModal from "../../components/modal";
import { useRecoilState } from "recoil";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidation } from "../../validation";
import { RESULT, ResponseModel } from "../../model/response";
import usePost from "../../hooks/usePost";
import crypto from "crypto-es";
import Loading from "../../components/loading/loading";
import { errorState, loadingState, loginState, modalState, modalTypeState, variableState } from "../../recoil/atoms";
import { loginPostSelector } from "../../recoil/variableAtom";

function LoginPage() {
  const navigate = useNavigate();
  const [modalType, setModalType] = useRecoilState(modalTypeState);
  const [isModalOpen, setModalOpen] = useRecoilState(modalState);
  const [islogin, setlogin] = useRecoilState(loginState);
  const [isLoading, setLoading] = useRecoilState(loadingState);
  let sessionStorage = window.sessionStorage;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidation),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });
  const [body, setBody] = useState<any>();
  const [errMsg, setErrMsg] = useRecoilState(errorState);
  const response: ResponseModel<any> = usePost({
    recoilSelector: loginPostSelector,
    data: body,
    recoilState: variableState,
  });
  useEffect(() => {
    if (response?.result === RESULT.OK) {
      setlogin(true);
      sessionStorage.setItem("accessToken", response.data.accessToken);
      sessionStorage.setItem("refreshToken", response.data.refreshToken);
      sessionStorage.setItem("id", body.lgnId);
      // let password = crypto.AES.encrypt(crypto.enc.Utf8.parse(body.lgnPassword), process.env.REACT_APP_JWT_ACCESS_SECRET_KEY).toString();
      let password = crypto.AES.encrypt(body.lgnPassword, process.env.REACT_APP_JWT_ACCESS_SECRET_KEY).toString();
      sessionStorage.setItem("check", password);
      navigate("/");
    }
    if (response?.result === RESULT.FAIL) {
      setModalType("inputError");
      setErrMsg(response.msg!);
      setModalOpen(true);
    }
  }, [response]);

  function modalHandler(type: string) {
    setModalType(type);
    setModalOpen(true);
  }
  async function OnSubmit(data: any) {
    setBody({
      lgnId: data.lgnId.toString(),
      lgnPassword: data.lgnPassword,
    });
  }
  let idError = errors.lgnId ? errors.lgnId.message : undefined;
  let pwError = errors.lgnPassword ? errors.lgnPassword.message : undefined;
  return (
    <LoginLayout bgImg={bgImage}>
      <LoginBox>
        <Font fontSize="1.75rem" fontWeight="500">
          로그인
        </Font>
        <Blank height="24px" />
        <Form onSubmit={handleSubmit(OnSubmit)} className="w-full">
          <Div className="flex w-full">
            <Div className="flex flex-col w-full">
              <Input
                placeholder="아이디"
                className="mb-[-1px]"
                fontSize="calc(0.9rem + 0.3vw)"
                padding="10px"
                tabIndex={1}
                type="text"
                {...register("lgnId")}
                name="lgnId"
                isError={idError ? true : false}
              />
              <Input
                placeholder="비밀번호"
                fontSize="calc(0.9rem + 0.3vw)"
                padding="10px"
                tabIndex={2}
                type="password"
                {...register("lgnPassword")}
                name="lgnPassword"
                autoComplete="off"
                isError={pwError ? true : false}
              />
            </Div>
            <EvButton
              bgColor={primaryColor}
              noRadius={true}
              color="#FFF"
              className="w-[calc(6rem + 1vw)]"
              fontSize="calc(1rem + 0.5vw)"
              changeFontSize="calc(1rem + 0.5vw)"
              fontweight="400"
            >
              로그인
            </EvButton>
          </Div>
        </Form>
        <Font color={noticeRedColor} className="w-[100%]" fontSize="calc(0.7rem + 0.3vw)">
          {idError || pwError ? "필수입력입니다." : null}
        </Font>
        <Blank height="16px" />
        <Div className="flex justify-between items-center w-full">
          <Div>
            <Link to="/register">
              <EvBorderButton borderColor={primaryColor} color={primaryColor} fontSize="calc(0.7rem + 0.2vw)">
                멤버십가입
              </EvBorderButton>
            </Link>
          </Div>
          <Div className="flex items-center">
            <FontLink onClick={() => modalHandler("id")}>아이디찾기</FontLink>
            <Blank width="4px" /> | <Blank width="4px" />
            <FontLink onClick={() => modalHandler("password")}>비밀번호찾기</FontLink>
          </Div>
        </Div>
      </LoginBox>
      <EvModal type={modalType} />
      <Loading isLoading={isLoading} />
    </LoginLayout>
  );
}

export default LoginPage;
