/* eslint-disable @typescript-eslint/no-unused-vars */
import { errorState } from "../../recoil/atoms";
import { Blank, EvButton } from "../../styles/commonStyle";
import { primaryColor } from "../../styles/theme";
import { ModalBox, ModalContent } from "./style";
import { useRecoilState } from "recoil";

function InputErrorModal({ msg, closeModal }: { msg?: string; closeModal: () => void }) {
  const [errMsg, setErrMsg] = useRecoilState(errorState);
  return (
    <ModalBox height="170px">
      <ModalContent height="100%">
        {errMsg}
        <Blank height="10px" />
        <EvButton fontSize="1rem" color="#FFF" bgColor={primaryColor} onClick={closeModal}>
          확인
        </EvButton>
      </ModalContent>
    </ModalBox>
  );
}

export default InputErrorModal;
