/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRecoilState } from "recoil";
import { Blank, Div, EvButton, Font, PageLayout } from "../../styles/commonStyle";
import { primaryColor, redColor } from "../../styles/theme";
import { ChargingListContent, ChargingListHead, ScrollDiv, HistoryTabList, HistoryTabButton, StyledTotal } from "./style";
import { userPointListSelector } from "../../recoil/variableAtom";
import { RESULT, ResponseModel } from "../../model/response";
import { useEffect, useLayoutEffect, useState } from "react";
import EvModal from "../../components/modal";
import { UtctoLocaleDateTimeFormmatter, addCommas, formatDate, formatISODateFormmatter, getUserIdFromToken } from "../../util";
import Pagination from "../../components/pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useFetchList from "../../hooks/useFetchList";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/loading/loading";
import { modalState, pageState, userPointState } from "../../recoil/atoms";

function PointHistoryPage() {
    const [currentPage, setPage] = useRecoilState(pageState);
    const [isModalOpen, setModalOpen] = useRecoilState(modalState);
    const [userPointList, setUserPointList] = useState<any>();
    const [isLoading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState<Date | null | undefined>();
    const [endDate, setEndDate] = useState<Date | null | undefined>();
    const [startDateString, setStartDateString] = useState<string | null | undefined>();
    const [endDateString, setEndDateString] = useState<string | null | undefined>();
    const [getSearch, setSearch] = useState<any>();
    const [isExec, setExec] = useState(false);
    const [totalStartDate, setTotalStartDate] = useState<any>();
    const [totalEndDate, setTotalEndDate] = useState<any>();
    const location = useLocation();
    const naviagate = useNavigate();
    const params = new URLSearchParams(location.search);
    const cPage = params.get("currentPage") ?? "1";
    let userId = getUserIdFromToken();
    const query: any = {
        page: currentPage,
        per_page: 10,
    };
    // 데이트 선택시에만 query 추가
    if (startDateString !== undefined) {
        query.start_dt = startDateString;
    }
    if (endDateString !== undefined) {
        query.end_dt = endDateString;
    }
    const response: ResponseModel<any> = useFetchList({
        recoilState: userPointListSelector,
        query: query,
        currentPage: currentPage,
        search: getSearch,
    });

    const execSearch = () => {
        setPage(1);
        setExec(true);
        setTotalStartDate(startDate)
        setTotalEndDate(endDate)
    };
    const execEnterSearch = (e: any) => {
        if (e.key === "Enter") {
            execSearch();
        }
    };

    useEffect(() => {
        setSearch(startDateString + "-" + endDateString);
        if (startDateString && endDateString) {
            naviagate(`/charging/pointlist?currentPage=${currentPage}&start_dt=${startDateString}&end_dt=${endDateString}`);
        } else if (startDateString && !endDateString) {
            naviagate(`/charging/pointlist?currentPage=${currentPage}&start_dt=${startDateString}`);
        } else if (!startDateString && endDateString) {
            naviagate(`/charging/pointlist?currentPage=${currentPage}&end_dt=${endDateString}`);
        }
        else {
            naviagate(`/charging/pointlist?currentPage=${currentPage}`);
        }
        setExec(false);
    }, [isExec]);

    useLayoutEffect(() => {
        setPage(Number(cPage));
        if (params.get("start_dt") && params.get("end_dt")) {
            setStartDateString(params.get("start_dt")!);
            setEndDateString(params.get("end_dt")!);
            setSearch(startDateString + "-" + endDateString);
        }
    }, []);

    useEffect(() => {
        if (response?.result === RESULT.FAIL) {
            setLoading(false);
            setModalOpen(true);
        }
        if (response?.result === RESULT.OK) {
            setLoading(false);
            setUserPointList(response.data);
        }
    }, [response]);

    const startDateHandler = (date: Date | null) => {
        if (endDate! < date!) {
            return;
        }
        setStartDate(date);
        setStartDateString(formatISODateFormmatter(date!, 'start'));
    };

    const endDateHandler = (date: Date | null) => {
        setEndDate(date);
        setEndDateString(formatISODateFormmatter(date!, 'end'));
    };
    return (
        <>
            <Div className="w-full flex justify-end items-center">
                <DatePicker
                    selected={startDate}
                    onChange={(date) => startDateHandler(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className="DatePicker"
                    placeholderText="검색 시작일"
                />
                &nbsp;&nbsp;~&nbsp;&nbsp;
                <DatePicker
                    selected={endDate}
                    onChange={(date) => endDateHandler(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    className="DatePicker"
                    placeholderText="검색 종료일"
                    onKeyDown={(e) => execEnterSearch(e)}
                />
                <EvButton color="#fff" bgColor={primaryColor} className="ml-[10px]" padding="0.5rem 1.5rem" onClick={(e) => execSearch()}>
                    조회
                </EvButton>
            </Div>

            {userPointList !== undefined && userPointList.items.length > 0 && (totalStartDate || totalEndDate) ? (<GetTotalPoint totalStartDate={totalStartDate} totalEndDate={totalEndDate} userPointList={userPointList} />) : undefined}

            <Blank height="12px" />
            <ScrollDiv className="overflow-x-scroll w-full">
                <ChargingListHead>
                    <Font className="w-[20%] text-center flex justify-center" fontSize="0.9rem">
                        no
                    </Font>
                    <Font className="w-[40%] text-center flex justify-center" fontSize="0.9rem">
                        날짜
                    </Font>
                    <Font className="w-[20%] text-center flex justify-center" fontSize="0.9rem">
                        구분
                    </Font>
                    <Font className="w-[20%] text-center flex justify-center" fontSize="0.9rem">
                        포인트(P)
                    </Font>
                </ChargingListHead>

                {userPointList?.items.length === 0 && response?.result === RESULT.OK ? (
                    <Font className="mt-5 flex justify-center">포인트 이력이 존재하지 않습니다.</Font>
                ) : (
                    userPointList?.items.map((value: any, idx: number) =>
                        <GetPointListLine value={value} idx={idx} key={"userPointList" + idx} />
                    )
                )}
            </ScrollDiv>

            {isLoading ? (
                <Loading isLoading={true} />
            ) : (
                <Div className="flex mt-5 mb-5">
                    <Pagination totalPage={userPointList?.last_page!} currentPage={userPointList?.current_page!} type="pointList" />
                </Div>
            )}
            <EvModal msg={response?.msg} />
        </>
    );
}

function GetTotalPoint({ totalStartDate, totalEndDate, userPointList }: { totalStartDate: any, totalEndDate: any, userPointList: any }) {
    return (
        <>
            <Blank height="12px" />
            <StyledTotal>
                <Font className="w-[15%] text-center flex justify-center font-medium bg-gray-112" fontSize="0.9rem">
                    기간별 합계
                </Font>
                <Font className="w-[15%] text-center flex justify-center font-medium" fontSize="0.9rem">
                    기간
                </Font>
                <Font className="w-[40%] text-center flex justify-center font-medium" fontSize="0.9rem">
                    {formatDate(totalStartDate)} ~ {formatDate(totalEndDate)}
                </Font>
                <Font className="w-[15%] text-center flex justify-center font-medium" fontSize="0.9rem">
                    포인트(P)
                </Font>
                <Font className="w-[15%] text-center flex justify-center font-medium" fontSize="0.9rem">
                    {userPointList?.items[0].totalPoint}
                </Font>
            </StyledTotal>
        </>
    )
}

function GetPointListLine({ value, idx }: { value: any, idx: number }) {
    let pointSttus: string = value.point_type;
    // PURCHASE_PAY,PURCHASE_COUPON, REWARD, USE, EXPIRE, PURCHASECANCEL, REWARDCANCLE, ADMINPLUS, ADMINMINUS
    switch (pointSttus) {
        case "PURCHASE_PAY":
            pointSttus = "유료 충전";
            break;
        case "PURCHASE_COUPON":
            pointSttus = "쿠폰 충전";
            break;
        case "REWARD":
            pointSttus = "적립";
            break;
        case "USE":
            pointSttus = "사용";
            break;
        case "EXPIRE":
            pointSttus = "소멸";
            break;
        case "PURCHASECANCEL":
            pointSttus = "충전 취소";
            break;
        case "REWARDCANCLE":
            pointSttus = "적립 취소";
            break;
        case "ADMINPLUS":
            pointSttus = "관리자 적립";
            break;
        case "ADMINMINUS":
            pointSttus = "관리자 소멸";
            break;
        case "MILEAGE":
            pointSttus = "등급 리워드";
            break;
        default:
            pointSttus = "알수없음";
            break;
    }
    return (
        <ChargingListContent>
            <Font className="w-[20%] text-center flex justify-center" fontSize="0.9rem">
                {idx + 1}
            </Font>
            <Font className="w-[40%] text-center flex justify-center" fontSize="0.9rem">
                {value.created_at
                    ? `${UtctoLocaleDateTimeFormmatter(value.created_at.toString())}`
                    : "-"}
            </Font>
            {
                pointSttus === "적립 취소" || pointSttus === "충전 취소" || pointSttus === "사용" || pointSttus === "소멸" || pointSttus === "관리자 소멸" ? <>
                    <Font className={'w-[20%] text-center flex justify-center'} color={redColor} fontSize="0.9rem">
                        {pointSttus}
                    </Font>
                    <Font className={'w-[20%] text-center flex justify-center'} color={value.point != 0 ? redColor : undefined} fontSize="0.9rem">
                        {value.point > 0 ? `+${addCommas(value.point)}` : addCommas(value.point)}
                    </Font>
                </> : <>
                    <Font className={'w-[20%] text-center flex justify-center'} fontSize="0.9rem">
                        {pointSttus}
                    </Font>
                    <Font className={'w-[20%] text-center flex justify-center'} fontSize="0.9rem">
                        {value.point > 0 ? `+${addCommas(value.point)}` : addCommas(value.point)}
                    </Font></>
            }

        </ChargingListContent>
    );
}

export default PointHistoryPage;

